import React, { FC } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import Security from '../Icons/Security';
import Server from '../Icons/Server';
import BetsModalTable from './BetsModalTable';
import Unlock from '../Icons/Unlock';
import useTranslate from '../../config/language';
import { KEYWORDS } from '../../config/language/keywords';

const Container = styled.div`
    background: ${(props) => props.theme.colors.charcoalGreyTwo};
    color: #cccccc;
    font-family: ${(props) => props.theme.fonts.mediatorNarrowCaps};
    border-radius: 4px;
    box-shadow: none;
    width: 545px;
    padding: 16px;
    box-sizing: border-box;
    max-width: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
`;

const Title = styled.div`
    color: ${(props) => props.theme.colors.turtleGreen};
    font-size: 17px;
    margin-left: 10px;
`;

const Body = styled.div`
    display: block;
    text-align: left;
`;

const SectionTitle = styled.div`
    font-size: 16px;
    margin-bottom: 8px;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: ${(props) => props.theme.colors.dark};
`;

const IconWrapper = styled.div`
    height: 15px;
    width: 13px;
    display: flex;
`;

const Section = styled.div`
    margin-bottom: 16px;
`;

const SectionBody = styled.div`
    display: flex;
    align-items: center;
`;

const SectionIcon = styled.div`
    height: 22px;
    width: 22px;
    margin-right: 16px;
`;

const TableWrapper = styled.div`
    flex: 1;
`;

const SectionValue = styled.div`
    background: ${(props) => props.theme.colors.charcoalGreyTree};
    height: 31px;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    line-height: 31px;
    padding: 0 8px;
`;

const BetsModal: FC<{
    gameId?: number;
    hashCode?: string;
    hashKey?: string;
    bustedAt?: number;
    closeModal(): void;
}> = ({ closeModal, gameId, hashCode, hashKey, bustedAt }) => {
    const translate = useTranslate();

    return (
        <Modal closeModal={closeModal}>
            <Container>
                <Header>
                    <IconContainer>
                        <IconWrapper>
                            <Security />
                        </IconWrapper>
                    </IconContainer>
                    <Title>
                        {translate(KEYWORDS.Round)} {gameId}
                    </Title>
                </Header>
                <Body>
                    <Section>
                        <SectionTitle>
                            {translate(KEYWORDS.ResultsHash)}
                        </SectionTitle>
                        <SectionBody>
                            <SectionIcon>
                                <Server />
                            </SectionIcon>
                            <SectionValue>{hashCode}</SectionValue>
                        </SectionBody>
                    </Section>
                    <Section>
                        <SectionTitle>
                            {translate(KEYWORDS.CheckResult)}
                        </SectionTitle>
                        <SectionBody>
                            <SectionIcon>
                                <Unlock />
                            </SectionIcon>
                            <TableWrapper>
                                <BetsModalTable
                                    hashKey={hashKey}
                                    bustedAt={bustedAt}
                                />
                            </TableWrapper>
                        </SectionBody>
                    </Section>
                </Body>
            </Container>
        </Modal>
    );
};

export default BetsModal;
