import React, { FC } from 'react';
import { IconProps } from './types';

export const Europebet: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 128.684 18.551"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                id="Path_2"
                fill="#e74e0f"
                d="M0 14.6a7.2 7.2 0 0 1 7.281-7.1 7.206 7.206 0 0 1 7.281 7.133 7.2 7.2 0 0 1-7.281 7.1A7.206 7.206 0 0 1 0 14.6"
                transform="translate(0 -5.386)"
            />
            <path
                id="Path_3"
                d="M226.939 20.137a3.158 3.158 0 0 1-3.159-2.9v-.507a3.179 3.179 0 1 1 3.159 3.411m.572-8.937a5.964 5.964 0 0 0-3.731 1.325v-.987h-3.28V26.03h3.28v-4.54a5.964 5.964 0 0 0 3.731 1.325 5.808 5.808 0 1 0 0-11.616"
                fill="#fff"
                transform="translate(-154.157 -7.479)"
            />
            <path
                id="Path_4"
                d="M410.851 3.5h-2.467V0H405.1v3.5h-1.5v2.505h1.5v8.4h3.279v-8.4h2.467z"
                fill="#fff"
                transform="translate(-282.168)"
            />
            <path
                id="Path_5"
                d="M323.3 8.965a3.166 3.166 0 0 1-6.318.254v-.507a3.155 3.155 0 0 1 3.159-2.876 3.114 3.114 0 0 1 3.159 3.129m3.249 0a5.858 5.858 0 0 0-9.568-4.483V0H313.7v14.407h3.28v-.987a5.944 5.944 0 0 0 3.7 1.325 5.822 5.822 0 0 0 5.867-5.78"
                fill="#fff"
                transform="translate(-219.316)"
            />
            <path
                id="Path_6"
                d="M270.769 17.024a3.341 3.341 0 0 1 3.31-3.193 2.666 2.666 0 0 1 2.437 1.271l-5.716 2.276a3.166 3.166 0 0 0-.03-.355m.963 2.72l8.274-3.311a5.879 5.879 0 0 0-6.017-5.232 6.176 6.176 0 0 0-6.288 6.09 6.26 6.26 0 0 0 6.469 6.09 7.459 7.459 0 0 0 5.416-2.01l-1.778-1.77c-3.58 2.454-5.957.443-6.078.148"
                fill="#fff"
                transform="translate(-187.156 -8.042)"
            />
            <path
                id="Path_7"
                d="M108.885 12.4v6.483a2.618 2.618 0 0 1-5.235 0V12.4h-3.25v6.069a5.868 5.868 0 0 0 11.734 0V12.4z"
                fill="#fff"
                transform="translate(-70.192 -8.904)"
            />
            <path
                id="Path_8"
                d="M178.769 20.571a3.312 3.312 0 1 1 3.219-3.311 3.285 3.285 0 0 1-3.219 3.311m0-9.371a6.279 6.279 0 0 0-6.469 6.09 6.481 6.481 0 0 0 12.938 0 6.279 6.279 0 0 0-6.469-6.09"
                fill="#fff"
                transform="translate(-120.46 -8.042)"
            />
            <path
                id="Path_9"
                d="M57.7 17.024a3.341 3.341 0 0 1 3.31-3.193 2.666 2.666 0 0 1 2.436 1.269l-5.717 2.276c-.03.03-.03-.148-.03-.355m.933 2.72l8.274-3.311a5.879 5.879 0 0 0-6.018-5.23 6.176 6.176 0 0 0-6.288 6.09 6.26 6.26 0 0 0 6.469 6.09 7.459 7.459 0 0 0 5.416-2.01l-1.776-1.77c-3.58 2.454-5.927.443-6.078.148"
                fill="#fff"
                transform="translate(-38.172 -8.042)"
            />
            <path
                id="Path_10"
                d="M363.7 17.024a3.341 3.341 0 0 1 3.31-3.193 2.666 2.666 0 0 1 2.437 1.271l-5.717 2.276c-.03.03-.03-.148-.03-.355m.933 2.72l8.274-3.311a5.879 5.879 0 0 0-6.017-5.232 6.176 6.176 0 0 0-6.288 6.09 6.26 6.26 0 0 0 6.469 6.09 7.459 7.459 0 0 0 5.416-2.01l-1.778-1.77c-3.55 2.454-5.927.443-6.078.148"
                fill="#fff"
                transform="translate(-252.105 -8.042)"
            />
            <path
                id="Path_11"
                d="M152.981 11.352c-2.557-.444-7.281.385-7.281 5.3v6.424h3.249v-5.6c0-3.937 4.032-3.256 4.032-3.256z"
                fill="#fff"
                transform="translate(-101.863 -8.076)"
            />
        </svg>
    );
};

export default Europebet;
