export enum MY_BETS_ACTIONS {
    INITIALIZE_MY_BETS = 'INITIALIZE_MY_BETS',
}

export interface InitializeMyBetsParams {
    ID: number;
    BuyIn: number;
    CashOut: number | string;
    Timestamp: string;
    GameID: number;
    Hash?: string;
}

export interface FormattedMyBetsParams {
    date: string;
    games: InitializeMyBetsParams[];
}

export const initializeMyBetsAction = (myBets: InitializeMyBetsParams[]) => {
    return {
        type: MY_BETS_ACTIONS.INITIALIZE_MY_BETS as MY_BETS_ACTIONS.INITIALIZE_MY_BETS,
        myBets,
    };
};
