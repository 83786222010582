import React, { FC } from 'react';
import styled from 'styled-components';
import History from '../../../../components/Icons/History';
import ToggleInput from '../../../../components/ToggleInput/ToggleInput';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';
import { useGameHistory } from '../../../../config/store/state/app.state';
import CustomResultBox from '../../../../components/ResultBox/CustomResultBox';

const StyledInfoPanel = styled.div`
    display: flex;
    height: 40px;
    align-items: center;
`;

const IconContainer = styled.div`
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
`;

const Text = styled.div`
    color: ${props => props.theme.colors.whiteGrayish};
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    font-weight: bold;
    font-size: 14px;
    margin-right: 16px;
`;

const ItemContainer = styled.div`
    margin-right: 16px;
`;

const BetsInfoPanel: FC<{
    showLastBets: boolean;
    setShowLastBets(setShow: boolean): void;
    totalBets: number;
}> = ({ showLastBets, setShowLastBets, totalBets }): any => {
    const translate = useTranslate();
    const lastGameStats = useGameHistory()[0];

    return (
        <StyledInfoPanel>
            <IconContainer>
                <History />
            </IconContainer>
            <Text>{translate(KEYWORDS.LastHand)}</Text>
            <ItemContainer>
                <ToggleInput
                    active={showLastBets}
                    onChange={() => setShowLastBets(!showLastBets)}
                />
            </ItemContainer>
            {lastGameStats && showLastBets && (
                <ItemContainer>
                    <CustomResultBox game={lastGameStats} />
                </ItemContainer>
            )}
            <Text>
                {translate(KEYWORDS.AllBets)} {totalBets}
            </Text>
        </StyledInfoPanel>
    );
};

export default BetsInfoPanel;
