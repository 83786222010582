import React, { FC } from 'react';
import styled from 'styled-components';
import MobileProfileSection from '../../features/profile/MobileProfileSection';

const StyledHeader = styled.div`
    height: 35px;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
`;

const BoomSection = styled.div`
    height: 100%;
    width: 90px;
    text-align: center;
    margin: auto;
    color: ${props => props.theme.colors.vermillion};
    font-size: 21px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    border-bottom: 2px solid;
    transform: translateX(-60px);
`;

const MobileHeader: FC = () => {
    return (
        <StyledHeader>
            <MobileProfileSection />
            <BoomSection>Boom</BoomSection>
        </StyledHeader>
    );
};

export default MobileHeader;
