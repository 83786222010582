export interface ITenant {
    Type: ETenantType;
}

export enum ETenantType {
    EuropeBet = 1,
    Demo = 2,
    Local = 3,
    Belarus = 4,
}
