import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { useCallback, useMemo } from 'react';
import { SENTRY_DSN } from '../../constants/constants';
import { ITenant } from '../../constants/interfaces/Tenant';
import { getIsTestTenant, isLocal } from '../../utils/check-env';

export const useSentryServices = () => {
    const init = useCallback((tenant: ITenant) => {
        if (isLocal() || !SENTRY_DSN) return;

        Sentry.init({
            dsn: SENTRY_DSN,
            integrations: [new Integrations.BrowserTracing()],
            attachStacktrace: true,
            debug: getIsTestTenant(tenant),
            tracesSampleRate: 1.0,
        });
    }, []);

    return useMemo(
        () => ({
            init,
        }),
        [init]
    );
};
