import styled from 'styled-components';
import { TableCell } from '../../../../components/Table/TableCell';

export const NeutralFirstTableCell = styled(TableCell)`
    width: 30%;
    padding-left: 8px;
    letter-spacing: 0;
    text-align: left;
`;

export const NeutralSecondTableCell = styled(TableCell)`
    width: 20%;
`;

export const NeutralThirdTableCell = styled(TableCell)`
    width: 20%;
    color: ${(props) => props.theme.colors.frogGreen};
`;

export const NeutralFourthTableCell = styled(TableCell)`
    width: 30%;
    padding-right: 10px;
`;

export const NeutralFirstVTableCell = styled.div`
    padding-left: 8px;
    letter-spacing: 0;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
`;

export const NeutralSecondVTableCell = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
`;

export const NeutralThirdVTableCell = styled.div`
    color: ${(props) => props.theme.colors.frogGreen};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const NeutralFourthVTableCell = styled.div`
    padding-right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
