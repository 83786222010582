import React, { FC } from 'react';
import useTranslate from '../../config/language';
import { ErrorModalTemplate } from './ErrorModalTemplate';
import { ErrorList, ErrorListNames } from './ErrorList';

const ErrorModal: FC<{ type: ErrorListNames }> = ({ type }) => {
    const translate = useTranslate();

    const handleOpenHere = () => {
        window.location.reload();
    };

    const actionExist = !!ErrorList[type].action;

    const action = actionExist ? handleOpenHere : undefined;
    const actionText = actionExist
        ? translate(ErrorList[type].action)
        : undefined;

    return (
        <ErrorModalTemplate action={action} actionText={actionText}>
            {translate(ErrorList[type].keyword)}
        </ErrorModalTemplate>
    );
};

export default ErrorModal;
