import React, { FC } from 'react';
import { IconProps } from './types';

export const Server: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 22 22"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <g
                    fill="none"
                    stroke="#75b75d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2px"
                    transform="translate(-1 -1)"
                >
                    <rect
                        id="Rectangle_3014"
                        width="20"
                        height="8"
                        className="cls-1"
                        rx="2"
                        transform="translate(2 2)"
                    />
                    <rect
                        id="Rectangle_3015"
                        width="20"
                        height="8"
                        className="cls-1"
                        rx="2"
                        transform="translate(2 14)"
                    />
                    <path
                        id="Line_104"
                        d="M0 0L0.01 0"
                        className="cls-1"
                        transform="translate(6 6)"
                    />
                    <path
                        id="Line_105"
                        d="M0 0L0.01 0"
                        className="cls-1"
                        transform="translate(6 18)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Server;
