import React, { FC } from 'react';
import Button, {
    ButtonNumericLabel,
} from '../../../../../components/Button/Button';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useTranslate from '../../../../../config/language';
import { KEYWORDS } from '../../../../../config/language/keywords';
import { roundNumber } from '../../../../../helpers/functions/round-number';
import { useShowMessage } from '../../../../errorMessages/store/services';
import { CURRENCY_SYMBOL } from '../../../../../constants/constants';

const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const BetButtonContainer = styled(motion.div)`
    display: flex;
    flex: 48;
    margin-right: 6px;
`;

const StyledButton = styled(Button)`
    background: ${props => props.theme.colors.frogGreen};
    &:disabled {
        background: ${props => props.theme.colors.frogGreen};
        color: unset;
    }
`;

const MIN_FREE_BET_CASH_OUT = 1.5;

const CashOutPanel: FC<{
    amount: number;
    multiplier: number;
    handleCashOut(): void;
    disabled?: boolean;
}> = ({ amount, multiplier, handleCashOut, disabled }) => {
    const translate = useTranslate();
    const showMessage = useShowMessage();

    const handleBlockedAction = () => {
        showMessage(KEYWORDS.MinFreebetCashout, 'error');
    };

    return (
        <StyledContainer>
            <BetButtonContainer
                initial={{ maxWidth: '50%' }}
                animate={{ maxWidth: '100%' }}
                transition={{ duration: 0.2 }}
            >
                <StyledButton
                    text={
                        <>
                            {translate(KEYWORDS.Cashout)} (
                            <ButtonNumericLabel>
                                {roundNumber(amount)}
                            </ButtonNumericLabel>
                            {CURRENCY_SYMBOL})
                        </>
                    }
                    disabled={disabled}
                    blocked={multiplier < MIN_FREE_BET_CASH_OUT}
                    blockedAction={handleBlockedAction}
                    onClick={handleCashOut}
                />
            </BetButtonContainer>
        </StyledContainer>
    );
};

export default CashOutPanel;
