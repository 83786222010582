import React, { FC } from 'react';
import { IconProps } from './types';

export const Survey: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 14.155 17.695"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g transform="translate(-8 -1.995)">
                <path
                    id="layer2"
                    d="M27.07,4.65A1.415,1.415,0,0,0,25.6,3.176h-.619a1.474,1.474,0,0,0-2.89,0h-.619A1.386,1.386,0,0,0,19.993,4.65v.885H27.07Z"
                    transform="translate(-8.454 0)"
                    fill="#e64d0e"
                />
                <path
                    id="layer2-2"
                    data-name="layer2"
                    d="M21.565,8H19.648a2.8,2.8,0,0,1,.147.885v.885h.59V22.155H9.769V9.769h.59V8.885A2.89,2.89,0,0,1,10.507,8H8.59A.59.59,0,0,0,8,8.59V23.334a.59.59,0,0,0,.59.59H21.565a.59.59,0,0,0,.59-.59V8.59A.59.59,0,0,0,21.565,8Z"
                    transform="translate(0 -4.234)"
                    fill="#e64d0e"
                />
                <path
                    id="layer1"
                    d="M18,18V28.026h8.257V18Zm6.488,8.257H19.769a.59.59,0,0,1,0-1.18h4.718a.59.59,0,0,1,0,1.18Zm0-2.949H19.769a.59.59,0,0,1,0-1.18h4.718a.59.59,0,0,1,0,1.18Zm0-2.949H19.769a.59.59,0,0,1,0-1.18h4.718a.59.59,0,0,1,0,1.18Z"
                    transform="translate(-7.051 -11.285)"
                    fill="#e64d0e"
                />
            </g>
        </svg>
    );
};

export default Survey;
