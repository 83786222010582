import React, { FC, useState } from 'react';
import styled from 'styled-components';
import SelectButton from '../../../../../components/SelectButton/SelectButton';
import { CURRENCY_SYMBOL } from '../../../../../constants/constants';
import { useMixpanelServices } from '../../../../../services/mixpanel/MixpanelServices';
import { EMixpanelEvent } from '../../../../../services/mixpanel/types';

const SelectButtonsActionRow = styled.div<{ disabled?: boolean }>`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    gap: 24px;
    margin-bottom: 12px;
    height: 40px;
    @media (max-height: ${750}px), (max-width: ${1100}px) {
        height: 30px;
    }
    ${props =>
        props.disabled &&
        `
            pointer-events: none;
        }
    `};

    @media (max-height: ${550}px) {
        margin-bottom: 6px;
    }
`;

const StyledSelectButton = styled(SelectButton)`
    @media (max-height: ${550}px), (max-width: ${1100}px) {
        font-size: 12px;
    }
`;

const SelectButtonActions: FC<{
    amount: number;
    onChange: (value: number) => void;
    disabled: boolean;
}> = ({ amount, onChange, disabled }) => {
    const [increaseBy, setIncreaseBy] = useState(1);
    const { track } = useMixpanelServices();

    const handleAmountChange = (increment: number) => {
        setIncreaseBy(increment);
        track(EMixpanelEvent.selectPredefinedBet, {
            BetAmount: `${increment}`,
        });
        if (increaseBy === increment) {
            onChange(amount + increment);
        } else {
            onChange(increment);
        }
    };

    return (
        <SelectButtonsActionRow disabled={disabled}>
            <StyledSelectButton
                text={`1.00 ${CURRENCY_SYMBOL}`}
                selected={amount === 1.0}
                onClick={() => handleAmountChange(1.0)}
                disabled={disabled}
            />
            <StyledSelectButton
                text={`2.00 ${CURRENCY_SYMBOL}`}
                selected={amount === 2.0}
                onClick={() => handleAmountChange(2.0)}
                disabled={disabled}
            />
            <StyledSelectButton
                text={`5.00 ${CURRENCY_SYMBOL}`}
                selected={amount === 5.0}
                onClick={() => handleAmountChange(5.0)}
                disabled={disabled}
            />
            <StyledSelectButton
                text={`10.00 ${CURRENCY_SYMBOL}`}
                selected={amount === 10.0}
                onClick={() => handleAmountChange(10.0)}
                disabled={disabled}
            />
            <StyledSelectButton
                text={`25.00 ${CURRENCY_SYMBOL}`}
                selected={amount === 25.0}
                onClick={() => handleAmountChange(25.0)}
                disabled={disabled}
            />
        </SelectButtonsActionRow>
    );
};

export default SelectButtonActions;
