import { CURRENCY_SYMBOL } from '../../../constants/constants';

export const english = {
    AccountBanned: 'Your accountb has been blocked!',
    AllBets: 'All Bets:',
    AlreadyRegistered: 'You have already placed a bet',
    Animation: 'Animation',
    AutoBet: 'AUTO BET',
    AutoBetting: 'Auto Bet',
    AutoCashout: 'AUTO CASHOUT',
    AutoCashouting: 'Auto Cashout',
    Balance: 'BALANCE',
    BalanceShort: 'BAL',
    Banned: 'BANNED',
    BaseBet: 'BASE BET',
    Bet: 'BET',
    BetMaximum: 'Bet Must Be Maximum {}GEL',
    BetMustBe: 'Bet Must Be Minimum {}GEL',
    Bets: 'Bets',
    Boom: 'BOOM!',
    BustedJackpot: 'Busted Jackpot',
    BuyIn: 'BET',
    Cashout: 'Cashout',
    CashoutFromGame: 'Cashout',
    CashoutMaxMustBe: 'Cashout Must Be Maximum ',
    CashoutMustBe: 'Cashout must be Minimum 1.01',
    ChangeAvatar: 'Change avatar',
    Chat: 'CHAT',
    ChatBlocked: 'Your chat has been blocked',
    CheckFair: '1. What is checking the odd?',
    CheckHash: '3. How to check credibility code?',
    CheckResult: 'Check Result',
    Circulation: '#',
    Clear: 'CLEAR',
    Crashed: 'ODD',
    CurrentBets: 'BETS',
    Day: '24 hours',
    DearUsers: 'Sorry for inconvenience',
    en: 'EN',
    Error: 'Error',
    ExampleForFair:
        'Game number - 9088 Odds - 1.06 Generation time - 12/03/2019_16:09 Unique game number - Fv+5TVHK2621A== As a result, the key to the game will be: 9088_1.06_Boom_12/03/2019_16:09_Fvv+5TVHK2621A== And the credibility code generated by the SHA256 algorithm: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d',
    Examples: 'Example:',
    FairGame: 'Fair Game',
    Fill: 'Fill',
    FreeBet: 'FreeBet',
    GameIsStopped: 'Game Is Stopped',
    GameKey: '2. What does a game key and credibility code mean?',
    ServerConnectionTemp: 'Connecting to server.',
    GameRules: 'Game Rules',
    GameStart: 'Starting Game',
    GameStarted: 'Game took start - Good Luck',
    GameStatusIsNotRegistering: 'Accepting bets finished',
    GameWillResume: 'Time Till Resume',
    GotoHome: 'HOME',
    Hash: 'Hash code',
    History: 'History ',
    HowToCheckFair:
        'This method allows us to make sure that the odds of the game (the number on which the game is over) is predetermined before players register their bets. There are 5 predetermined games in advance at any given time. The following is a Hash Code of 5 past and 5 upcoming rounds.',
    HowToCheckGameKey:
        'The odds of the game (the number on which the game will be over) are predetermined, 5 games in advance. A game key is generated on the game server, which consists of the following parameters: [Game Number] _ [Odds] _ [Boom] _ [Generation Time] _ [Unique Game Number]',
    HowToCheckHash:
        'After the game is over, a game key will be available that you can encrypt on any online SHA256 calculator and then compare it to the credibility code given for the next 5 games.',
    IncreaseBy: 'INCREASE BY',
    Jackpot: 'JACKPOT',
    JackpotRuleBust:
        'The jackpot will drop randomly. The jackpot will be equally divided among the playiers in the round who:s, who:',
    JackpotRules: 'JACKPOT RULES',
    JackpotRulesBalance:
        'Winnings will automatically be credited to the player’s balance.',
    JackpotRulesBetCondition: `Made a minimum 1${CURRENCY_SYMBOL} bet`,
    JackpotRulesCashoutCondition: 'Didn’t cashout on the odd less than 1.2',
    ka: 'KA',
    Key: 'Key',
    LanguageChangeAcception: 'Are you sure you want to change language? ',
    LanguageChangeWarning: 'Pressing the button will turn off the current game',
    LastGames: 'Last games',
    LastHand: 'Last Hand',
    LastJackpot: 'LAST JACKPOT',
    LastTopSpins: 'TOP MULTIPLIERS IN 24 HOURS',
    LastTopWins: 'TOP WINS IN 24 HOURS',
    LeaveMessage: 'Type your message',
    Login: 'LOGIN',
    LoginFailed: 'Login failed',
    MainPage: 'Main Page',
    ManualBet: 'MANUAL BET',
    MinFreebetCashout: 'Min. odd 1.5',
    Month: "Month's",
    MultiplierCashout: 'Mult. Cashout',
    Music: 'Music',
    My: 'My Bets',
    No: 'No',
    NotEnoughMoney: 'Not Enough Money',
    OnLoss: 'ON LOSS',
    OnWin: 'ON WIN',
    OpenHere: 'Open Here',
    PlaceYourBet: 'PLACE YOUR BETS',
    Player: 'Player',
    Rating: 'TOP',
    Register: 'Place a bet',
    Registration: 'PLACE YOUR BETS',
    Reload: 'Reload',
    Result: 'Result',
    ResultsHash: "Result's Hash Code",
    ReturnToBaseBet: 'RETURN TO BASE BET',
    ReturnToCash: 'Return to cash',
    Round: 'Round',
    ru: 'RU',
    Rules1: 'Players can place bets before the round starts.',
    Rules2: 'Odds start to increase when the game starts and lasts until it’s burnt out.',
    Rules3: 'The burnout point is generated randomly.',
    Rules4: 'A player should complete a cashout of the winning before the burnout.',
    Rules5: 'Bets that are not cashed out before the burnout will be considered as lost.',
    Rules6: 'A player’s bet will be multiplied by the odds at which the bet was cashed out when paying out before the burnout.',
    Rules7: `Odds start to increase from 1.0. In the case of burnout at 1.0, all bets are considered lost. For example, a player places a bet of 5${CURRENCY_SYMBOL} and cashouts the cash at 2.0, he/she will receive 2*5=10${CURRENCY_SYMBOL}. If odds burnout at less than 2.0, for example at 1.75, in this case, a player loses the whole bet of 5${CURRENCY_SYMBOL}. In case of leaving the game, the bet will be automatically cashed out according to the multiplication of the bet and the height of odds at that moment`,
    Rules8: 'In case of leaving the game (disconnection), the bet will be automatically cashed out according to the multiplication of the bet and the height coefficient at that moment. Leaving the game will be considered as termination of the game session for any reason.',
    Rules9: 'A player can place two bets at the same time and cash them out separately.',
    Rules10:
        'The player can place a bet on the desired conditions by placing an automatic bet. In the existing window, it is possible to specify the bet amount, the cash out ratio, the maximum cash out amount. Also pre-determine the number of bets in case of win or lose.',
    Rules11: 'Winnings are automatically reflected on a player’s balance.',
    Rules12:
        'The player can see the game history by clicking on the "My Bets" button.',
    Rules13:
        'In case of a proven error in the game, the game side reserves the right to withhold or correct the player’s estimated winnings.',
    Rules14: 'Game limits:',
    Rules14_1: `minimum bet - 0.1${CURRENCY_SYMBOL}`,
    Rules14_2: `maximum bet - 200${CURRENCY_SYMBOL}`,
    Rules15: `A player’s maximum win (bet*odds) - 30,000${CURRENCY_SYMBOL}, when bet multiplied by odds reaches 30,000${CURRENCY_SYMBOL}, the system will automatically cash out the active bet`,
    Rules16: `When bets placed by all players multiplied by the odds will reach 100 000${CURRENCY_SYMBOL} at one round, the system will automatically payout all active bets.`,
    Rules17: 'The maximum multiplayer of the game is 5500.',
    Rules18: 'Game RTP is 95.05%',
    Rules19:
        'The player can find the latest odds of the game in the top section of the page.',
    Rules20:
        'The player can see the rating of players by clicking on the "Rating" button.',
    Save: 'Save',
    ServerConnection: 'Connecting to server',
    InternetConnectionLost:
        'There was a problem with your internet connection, please refresh the page.',
    ServerError: 'Server  Error',
    SessionHasExpired: 'Session Has Expired',
    SitesUpdate: 'SCHEDULED MAINTENANCE!',
    Sound: 'Sound',
    Statistics: 'Statistics',
    StopIf: 'STOP IF BET >',
    Submit: 'Submit',
    SuccessfulRegistration: 'Bet placed',
    TechnicalWork: 'the game you requested will be back soon.',
    TicketCashoutMustBe: 'Cashout must be at least 1.5',
    Time: 'Time',
    TopSpins: 'Odds',
    TopWins: 'Winnings',
    tr: 'TR',
    UnknowError: 'Server Error',
    Unregister: 'Cancel bet',
    UnregisterFreeBet: 'Cancel FreeBet',
    Unregistered: 'Bet cancelled',
    User: 'User',
    UserName: 'User Name',
    UserShort: 'US',
    WaitingForNextRound: 'You Are Waiting For Next Round',
    Win: 'WIN',
    WinAmount: 'Profit',
    WinMultiplier: 'Won Mult.',
    Winners: 'Winners',
    WrongAmount: 'Wrong Amount',
    WrongConfiguration: 'Wrong Configuration',
    Year: "Year's",
    Yes: 'Yes',
    YouAreNotRegistered: 'You Are Not Registered On This Game',
    EnterText: 'Enter Text',
    PlayerNotFound: 'Player Not Found',
    Jan: 'Jan.',
    Feb: 'Feb.',
    Mar: 'Mar.',
    Apr: 'Apr.',
    May: 'May',
    June: 'June',
    July: 'July',
    Aug: 'Aug.',
    Sept: 'Sept.',
    Oct: 'Oct.',
    Nov: 'Nov.',
    Dec: 'Dec.',
    BlockedUser: 'Your account is blocked',
    LoadingProblem: 'Failed to load the game, please reload the page.',
};
