export const DEFAULT_GAME_HEIGHT = 400;
export const DEFAULT_GAME_WIDTH = 300;

// TODO: ??
export const calculateGameBoardDimensions = (width: number, height: number) => {
    if (height < 500) {
        return { height: 160, width: 150 };
    } else if (width < 550) {
        return { height: 160, width: 150 };
    } else if (height < 570) {
        return { height: 160, width: 180 };
    } else if (height < 630) {
        return { height: 180, width: 180 };
    } else if (height < 670) {
        return { height: 200, width: 180 };
    } else if (height < 680) {
        return { height: 230, width: 220 };
    } else if (height < 735) {
        return { height: 250, width: 250 };
    } else if (height < 850) {
        return { height: 340, width: 250 };
    } else if (width < 1000) {
        return { height: 300, width: 220 };
    } else if (width < 1300) {
        return { height: 350, width: 260 };
    } else {
        return {
            height: DEFAULT_GAME_HEIGHT,
            width: DEFAULT_GAME_WIDTH,
        };
    }
};

export const calculateGameDuration = (duration: number) => {
    return duration * 1000 - 600;
};
