import React, { FC } from 'react';
import styled from 'styled-components';
import Error from '../Icons/Error';
import Success from '../Icons/Success';
import Info from '../Icons/Info';
import { motion } from 'framer-motion';
import { variables } from '../../constants/variables';
import useTranslate from '../../config/language';

export const variantIcon = {
    success: Success,
    error: Error,
    info: Info,
};

const errorColor = {
    success: variables.colors.frogGreen,
    error: variables.colors.redPink,
    info: variables.colors.lighterOrange,
};

const SnackbarWrapper = styled(motion.div)<{
    open?: string;
}>`
    width: fit-content;
    min-width: 280px;
    max-width: 550px;
    height: 56px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 17px;
    font-family: ${(props) => props.theme.fonts.mediatorNarrowCaps};
    backdrop-filter: blur(30px);
    font-weight: 500;
    display: flex;
    align-items: center;
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
    transition: visibility 1s linear;
    z-index: 1000;
    padding: 8px 8px 8px 16px;
    margin-bottom: 16px;
`;

const IconWrapper = styled.div<{ background: string }>`
    width: 32px;
    height: 32px;
    border-radius: 32px;
    flex-shrink: 0;
    display: flex;
    background: ${(props) => props.background};
    align-items: center;
    justify-content: center;
`;

const StyledIcon = styled.div`
    height: 16px;
    width: 16px;
    color: ${(props) => props.theme.colors.white};
    flex: 1;
`;

const StyledMessageContainer = styled.div`
    font-size: 15px;
    margin-left: 16px;
    color: ${(props) => props.theme.colors.white};
`;

export const GenerateIcon: FC<{
    variant: keyof typeof variantIcon;
}> = ({ variant }) => {
    const Icon = variantIcon[variant];

    return (
        <IconWrapper background={errorColor[variant]}>
            <StyledIcon>
                <Icon />
            </StyledIcon>
        </IconWrapper>
    );
};

export const InfoSnackbar: FC<{
    className?: string;
    message: string;
    keyword?: string;
    handleClose: () => void;
    open: any;
    variant: keyof typeof variantIcon;
}> = ({ message, keyword, variant, open, handleClose }) => {
    const translate = useTranslate();

    return (
        <>
            <SnackbarWrapper initial={{ y: 50 }} animate={{ y: 0 }} open={open}>
                {<GenerateIcon variant={variant} />}
                <StyledMessageContainer>
                    {translate(keyword)} {message}
                </StyledMessageContainer>
            </SnackbarWrapper>
        </>
    );
};
