import { keyframes } from 'styled-components';

export const BUBBLE_ANIMATION_0 = keyframes`
    0% {
        width: 30px;
        height: 30px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 39.497764659734955px;
        height: 39.497764659734955px;
        margin-left: -4.748882329867477px;
        margin-top: -4.748882329867477px;
    }

    100% {
        width: 30px;
        height: 30px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_1 = keyframes`
    0% {
        width: 50px;
        height: 50px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 57.73902810530796px;
        height: 57.73902810530796px;
        margin-left: -3.8695140526539795px;
        margin-top: -3.8695140526539795px;
    }

    100% {
        width: 50px;
        height: 50px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_2 = keyframes`
    0% {
        width: 30px;
        height: 30px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 37.68691681707352px;
        height: 37.68691681707352px;
        margin-left: -3.8434584085367582px;
        margin-top: -3.8434584085367582px;
    }

    100% {
        width: 30px;
        height: 30px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_3 = keyframes`
    0% {
        width: 30px;
        height: 30px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 35.03516345979218px;
        height: 35.03516345979218px;
        margin-left: -2.51758172989609px;
        margin-top: -2.51758172989609px;
    }

    100% {
        width: 30px;
        height: 30px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_4 = keyframes`
        0% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }
    
    50% {
        width: 29.667374649147696px;
        height: 29.667374649147696px;
        margin-left: -4.833687324573848px;
        margin-top: -4.833687324573848px;
    }

    100% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }
`;
export const BUBBLE_ANIMATION_5 = keyframes`
    0% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 27.120784010245018px;
        height: 27.120784010245018px;
        margin-left: -3.560392005122509px;
        margin-top: -3.560392005122509px;
    }
    
    100% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }
`;
export const BUBBLE_ANIMATION_6 = keyframes`
    0% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 26.428038799849922px;
        height: 26.428038799849922px;
        margin-left: -3.214019399924961px;
        margin-top: -3.214019399924961px;
    }

    100% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_7 = keyframes`
    0% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 29.198395472562986px;
        height: 29.198395472562986px;
        margin-left: -4.599197736281493px;
        margin-top: -4.599197736281493px;
    }

    100% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_8 = keyframes`
    0% {
        width: 40px;
        height: 40px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 47.39214182292373px;
        height: 47.39214182292373px;
        margin-left: -3.6960709114618666px;
        margin-top: -3.6960709114618666px;
    }

    100% {
        width: 40px;
        height: 40px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_9 = keyframes`
    0% {
        width: 30px;
        height: 30px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 36.62591113863251px;
        height: 36.62591113863251px;
        margin-left: -3.312955569316255px;
        margin-top: -3.312955569316255px;
    }

    100% {
        width: 30px;
        height: 30px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_10 = keyframes`
    0% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 27.253923513535568px;
        height: 27.253923513535568px;
        margin-left: -3.626961756767784px;
        margin-top: -3.626961756767784px;
    }

    100% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_11 = keyframes`
    0% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 27.432601651031604px;
        height: 27.432601651031604px;
        margin-left: -3.716300825515802px;
        margin-top: -3.716300825515802px;
    }

    100% {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 0;
    }
`;

export const BUBBLE_ANIMATION_12 = keyframes`
    0% {
        width: 15px;
        height: 15px;
        margin-left: 0;
        margin-top: 0;
    }

    50% {
        width: 22.067364867305894px;
        height: 22.067364867305894px;
        margin-left: -3.533682433652947px;
        margin-top: -3.533682433652947px;
    }

    100% {
        width: 15px;
        height: 15px;
        margin-left: 0;
        margin-top: 0;
    }
`;
