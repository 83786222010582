import { useSelector } from 'react-redux';
import {SelectAudioPlayerState} from "../../../config/store/selectors";

export interface AudioPlayerState {
    playBetSound: boolean;
    playButtonSound: boolean;
    playWinSound: boolean;
}

export function useAudioPlayerState(): AudioPlayerState {
    return useSelector(SelectAudioPlayerState);
}
