import styled from 'styled-components';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import Rocket from '../../../components/Icons/Rocket';
import Fire from '../../../components/Icons/Fire';

const ShipContainer = styled(motion.div)`
    position: relative;
`;

const Spaceship = styled.div<{ width: number }>`
    width: ${props => props.width}px;
    position: relative;
    z-index: 1;
`;

const FireIcon = styled(motion.div)<{
    width: number;
    left: number;
    top: number;
}>`
    width: ${props => props.width}px;
    position: absolute;
    left: ${props => props.left}px;
    top: ${props => props.top}px;
`;

const fireTransition = {
    y: {
        duration: 0.4,
        yoyo: Infinity,
        delay: 0.5,
    },
    opacity: {
        duration: 0.4,
        yoyo: Infinity,
        ease: 'easeOut',
        delay: 0.5,
    },
};

const shipTransition = {
    y: {
        duration: 0.4,
        yoyo: Infinity,
        delay: 0.5,
    },
};

const DEFAULT_SHIP_WIDTH = 185;
const DEFAULT_FIRE_WIDTH = 40;
const DEFAULT_FIRE_LEFT = 75;
const DEFAULT_FIRE_TOP = 235;
const SHIP_TO_FIRE_WIDTH_RATIO = DEFAULT_SHIP_WIDTH / DEFAULT_FIRE_WIDTH;
const SHIP_TO_FIRE_LEFT_RATIO = DEFAULT_SHIP_WIDTH / DEFAULT_FIRE_LEFT;
const SHIP_TO_FIRE_TOP_RATIO = DEFAULT_SHIP_WIDTH / DEFAULT_FIRE_TOP;

export const Ship: FC<{
    width?: number;
    animateFire?: boolean;
    animateShip?: boolean;
}> = ({ width, animateFire, animateShip }) => {
    const shipWidth = width || 185;
    return (
        <ShipContainer
            animate={animateShip ? { y: -10 } : undefined}
            transition={shipTransition}
        >
            <Spaceship width={shipWidth}>
                <Rocket />
            </Spaceship>
            <FireIcon
                width={shipWidth / SHIP_TO_FIRE_WIDTH_RATIO}
                left={shipWidth / SHIP_TO_FIRE_LEFT_RATIO}
                top={shipWidth / SHIP_TO_FIRE_TOP_RATIO}
                animate={animateFire ? { y: -10, opacity: 0.8 } : undefined}
                transition={fireTransition}
            >
                <Fire />
            </FireIcon>
        </ShipContainer>
    );
};
