import React, { FC } from 'react';
import styled from 'styled-components';

const AvatarContainer = styled.div<{ width: number; height: number }>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    border: 1px solid yellow;
    border-radius: 100%;
    overflow: hidden;
`;

const AvatarImage = styled.img`
    width: 100%;
    height: 100%;
`;

const UserAvatar: FC<{ avatarId?: string | number; size?: number }> = ({
    avatarId = 0,
    size,
}) => {
    return (
        <AvatarContainer width={size ? size : 35} height={size ? size : 35}>
            <AvatarImage src={`/images/avatars/${avatarId}.svg`} />
        </AvatarContainer>
    );
};

export default UserAvatar;
