import React, { FC } from 'react';
import { IconProps } from './types';

export const DivArrow: FC<IconProps> = ({ className }) => {
    return (
        <svg className={className} width="50px" height="50px">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d=" M 0 20 C 0 10 10 10 15 0 L 15 40 C 10 30 0 30 0 20"
            />
        </svg>
    );
};

export default DivArrow;
