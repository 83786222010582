import React, { FC } from 'react';
import { TableHead } from '../Table/TableHead';
import { TableRow } from '../Table/TableRow';
import { TableHeaderCell } from '../Table/TableHeaderCell';
import { TableBody } from '../Table/TableBody';
import { TableCell } from '../Table/TableCell';
import Table from '../Table/Table';
import styled from 'styled-components';
import useTranslate from '../../config/language';
import { KEYWORDS } from '../../config/language/keywords';

const StyledTableFirstHeaderCell = styled(TableHeaderCell)`
    padding: 0 8px;
    width: 80% !important;
`;

const StyledTableSecondHeaderCell = styled(TableHeaderCell)`
    padding: 0 8px;
`;

const StyledFirstTableCell = styled(TableCell)`
    height: 31px;
    width: 80%;
    text-align: left;
    padding: 0 8px;
`;

const StyledSecondTableCell = styled(TableCell)`
    height: 31px;
    padding: 0 8px;
    color: ${props => props.theme.colors.frogGreen};
`;

const BetsModalTable: FC<{ hashKey?: string; bustedAt?: number }> = ({
    hashKey,
    bustedAt,
}) => {
    const translate = useTranslate();

    return (
        <Table>
            <TableHead>
                <tr>
                    <StyledTableFirstHeaderCell>
                        {translate(KEYWORDS.Key)}
                    </StyledTableFirstHeaderCell>
                    <StyledTableSecondHeaderCell>
                        {translate(KEYWORDS.Result)}
                    </StyledTableSecondHeaderCell>
                </tr>
            </TableHead>
            <TableBody>
                <TableRow>
                    <StyledFirstTableCell>{hashKey}</StyledFirstTableCell>
                    <StyledSecondTableCell>{bustedAt}x</StyledSecondTableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
export default BetsModalTable;
