import React, { FC } from 'react';
import styled from 'styled-components';
import Counter from '../Counter/Counter';

const StyledSelectButton = styled.button<{
    selected?: boolean;
    disabled?: boolean;
}>`
    position: relative;
    font-size: 14px;
    width: 100%;
    font-weight: bold;
    border-radius: 50px;
    display: inline-flex;
    padding: 6px 0;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    cursor: pointer;
    white-space: nowrap;
    border: none;
    outline: none;
    background: ${props =>
        props.selected
            ? props.theme.colors.vermillionTwo
            : props.theme.colors.transparent};
    &:active {
        background: ${props => props.theme.colors.vermillionTwo};
    }
    &:disabled {
        background: ${props => props.theme.colors.transparent};
        opacity: 0.5;
        pointer-events: none;
    }
`;

const SelectButton: FC<{
    text: string;
    selected?: boolean;
    count?: number;
    onClick?(): void;
    disabled?: boolean;
    className?: string;
}> = ({ text, selected, count, onClick, disabled, className }) => {
    return (
        <StyledSelectButton
            selected={selected}
            onClick={onClick}
            disabled={disabled}
            className={className}
        >
            {text}
            {count && <Counter count={count} />}
        </StyledSelectButton>
    );
};

export default SelectButton;
