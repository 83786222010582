import { delay, put, takeEvery } from 'redux-saga/effects';
import {
    ACTIONS,
    CashedOutPlayerParams,
    PlayerCashedOutParams,
    PlayerRegisteredParams,
    PlayerRegisterErrorStatuses,
    PlayerUnregisteredParams,
} from '../actions/app.actions';
import { ADD_MESSAGE } from '../../../features/errorMessages/store/actions';
import { KEYWORDS } from '../../language/keywords';
import {
    PLAY_BET_SOUND,
    PLAY_WIN_SOUND,
    STOP_BET_SOUND,
    STOP_WIN_SOUND,
} from '../../../features/audioPlayer/store/actions';
import {
    LIKE_CHAT_MESSAGE,
    SEND_CHAT_MESSAGE,
} from '../../../features/chat/store/actions';
import { roundNumber } from '../../../helpers/functions/round-number';
import { CURRENCY_SYMBOL } from '../../../constants/constants';

const transformSocketMessage = (payload: any): string => {
    return JSON.stringify({
        ...payload,
        SendDate: Date.now(),
    });
};

export function* handleMessageLike(params: any) {
    yield takeEvery(LIKE_CHAT_MESSAGE, (action: any) => {
        params.socket.send(
            transformSocketMessage({
                Message: 'SendLike',
                MID: action.MID,
            })
        );
    });
}

export function* handleMessageSend(params: any) {
    yield takeEvery(SEND_CHAT_MESSAGE, (action: any) => {
        params.socket.send(
            transformSocketMessage({
                Message: 'SendMessage',
                ...action.payload,
            })
        );
    });
}

export function* handlePlayerRegister(params: any) {
    yield takeEvery(ACTIONS.REGISTER, (action: any) => {
        params.socket.send(
            transformSocketMessage({ Message: 'Register', ...action.payload })
        );
    });
}

export function* handlePing(params: any) {
    yield takeEvery(ACTIONS.PING, () => {
        params.socket.send(
            transformSocketMessage({
                Message: 'H',
            })
        );
    });
}

function* displayRegisterSuccessMessage() {
    yield put({
        type: ADD_MESSAGE,
        error: {
            keyword: KEYWORDS.SuccessfulRegistration,
            status: 'success',
        },
    });
    yield put({
        type: PLAY_BET_SOUND,
    });
    yield delay(500);
    yield put({
        type: STOP_BET_SOUND,
    });
}

const registerErrors: any = {
    [PlayerRegisterErrorStatuses.NotEnoughFunds]: KEYWORDS.NotEnoughMoney,
    [PlayerRegisterErrorStatuses.Error]: KEYWORDS.Error,
    [PlayerRegisterErrorStatuses.BetNotFound]: KEYWORDS.WaitingForNextRound,
    [PlayerRegisterErrorStatuses.GamblerNotActive]: KEYWORDS.Error,
    [PlayerRegisterErrorStatuses.InvalidBetAmount]: KEYWORDS.WrongAmount,
    [PlayerRegisterErrorStatuses.InvalidGameStatus]:
        KEYWORDS.WaitingForNextRound,
    [PlayerRegisterErrorStatuses.PlayerNotFound]: KEYWORDS.PlayerNotFound,
};

function* handlePlayerRegisterError(errorKeyword: string) {
    yield put({
        type: ADD_MESSAGE,
        error: {
            keyword: errorKeyword,
            status: 'error',
        },
    });
}

export function* handlePlayerRegisterSuccess() {
    yield takeEvery(
        ACTIONS.PLAYER_REGISTERED,
        (action: {
            type: ACTIONS.PLAYER_REGISTERED;
            payload: PlayerRegisteredParams;
        }) => {
            if (
                action.payload.YouRegisteredOnGame &&
                action.payload.EC === PlayerRegisterErrorStatuses.Success
            ) {
                return displayRegisterSuccessMessage();
            } else if (
                action.payload.YouRegisteredOnGame &&
                action.payload.EC !== PlayerRegisterErrorStatuses.Success
            ) {
                return handlePlayerRegisterError(
                    registerErrors[action.payload.EC]
                );
            }
        }
    );
}

function* displayUnregisterSuccessMessage() {
    yield put({
        type: ADD_MESSAGE,
        error: {
            keyword: KEYWORDS.Unregistered,
            status: 'success',
        },
    });
}

export function* handlePlayerUnregisterSuccess() {
    yield takeEvery(
        ACTIONS.PLAYER_UNREGISTERED,
        (action: {
            type: ACTIONS.PLAYER_UNREGISTERED;
            payload: PlayerUnregisteredParams;
        }) => {
            if (action.payload.YouUnRegisteredOnGame) {
                return displayUnregisterSuccessMessage();
            }
        }
    );
}

function* displayCashOutSuccessMessage(payload?: CashedOutPlayerParams) {
    let profit = 0;
    if (payload) {
        profit = payload.BuyIn * payload.Multiplier;
    }

    yield put({
        type: ADD_MESSAGE,
        error: {
            keyword: KEYWORDS.Win,
            message: `${roundNumber(profit)} ${CURRENCY_SYMBOL}`,
            status: 'success',
        },
    });
    yield put({
        type: PLAY_WIN_SOUND,
    });
    yield delay(800);
    yield put({
        type: STOP_WIN_SOUND,
    });
}

export function* handlePlayerCashOutSuccess() {
    yield takeEvery(
        ACTIONS.PLAYER_CASHED_OUT,
        (action: {
            type: ACTIONS.PLAYER_CASHED_OUT;
            payload: PlayerCashedOutParams;
        }) => {
            if (action.payload.YouCashedOut) {
                return displayCashOutSuccessMessage(
                    action.payload.YouCashedOut.CashedOutPlayer
                );
            }
        }
    );
}

export function* handlePlayerUnregister(params: any) {
    yield takeEvery(ACTIONS.UNREGISTER, (action: any) => {
        params.socket.send(transformSocketMessage(action.payload));
    });
}

export function* handlePlayerCashOut(params: any) {
    yield takeEvery(ACTIONS.CASH_OUT, (action: any) => {
        params.socket.send(transformSocketMessage(action.payload));
    });
}

export function* handleSaveSettings(params: any) {
    yield takeEvery(ACTIONS.SAVE_GAME_SETTINGS, (action: any) => {
        params.socket.send(transformSocketMessage(action.payload));
    });
}
