import React from 'react';
import Game from './Game';
import { useGameState } from './config/store/state/app.state';
import GameLoader from './components/GameLoader';

function App() {
    const gameLoading = useGameState().loading;

    return gameLoading ? <GameLoader /> : <Game />;
}

export default App;
