import React, { FC } from 'react';
import Button from '../../../../../components/Button/Button';
import PlusButton from '../../../../../components/PlusButton/PlusButton';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useTranslate from '../../../../../config/language';
import { KEYWORDS } from '../../../../../config/language/keywords';
import { roundNumber } from '../../../../../helpers/functions/round-number';
import { CURRENCY_SYMBOL } from '../../../../../constants/constants';

const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const BetButtonContainer = styled(motion.div)`
    display: flex;
    flex: 48;
    margin-right: 6px;
`;

const StyledButton = styled(Button)`
    font-size: 21px;
    @media (max-height: ${550}px), (max-width: ${1100}px) {
        font-size: 16px;
    }
`;

const AddIcon = styled.div`
    color: ${(props) => props.theme.colors.white};
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RegisterPanel: FC<{
    amount: number;
    setAmount(amount: number | null): void;
    handleRegister(): void;
    toggleBoard(): void;
    icon: any;
    minValue?: number;
    maxValue?: number;
}> = ({ amount, handleRegister, toggleBoard, icon }) => {
    const translate = useTranslate();

    return (
        <StyledContainer>
            <BetButtonContainer>
                <StyledButton
                    text={`${translate(KEYWORDS.FreeBet)} (${roundNumber(
                        amount
                    )}${CURRENCY_SYMBOL})`}
                    onClick={handleRegister}
                />
            </BetButtonContainer>
            <PlusButton onClick={toggleBoard}>
                <AddIcon>{icon}</AddIcon>
            </PlusButton>
        </StyledContainer>
    );
};

export default RegisterPanel;
