import React, { FC } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Blacker = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: ${props => props.theme.colors.transparentModal};
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Close = styled.div`
    position: absolute;
    font-size: 30px;
    font-weight: 100;
    right: 16px;
    cursor: pointer;
    user-select: none;
    color: ${props => props.theme.colors.gray};
    z-index: 1;
    &:hover {
        color: ${props => props.theme.colors.dark};
    }
    &:active {
        color: ${props => props.theme.colors.transparent};
    }
`;

const Container = styled(motion.div)`
    max-width: 90%;
    position: relative;
    max-height: 95%;
    overflow: auto;
`;

const Modal: FC<{ closeModal?: () => void; className?: string }> = ({
    closeModal,
    className,
    children,
}) => {
    return (
        <AnimatePresence>
            <Blacker onClick={closeModal}>
                <Container
                    className={className}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.2 }}
                >
                    {closeModal && <Close onClick={closeModal}>×</Close>}
                    {children}
                </Container>
            </Blacker>
        </AnimatePresence>
    );
};

export default Modal;
