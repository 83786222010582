import React, { FC } from 'react';
import styled from 'styled-components';
import { Winnings } from '../../../../constants/interfaces/TopWinnings';
import TableAvatar from '../../components/TableAvatar';
import { formatUsername } from '../../../../helpers/functions/format-username';
import {
    formatTime,
    useFormatDate,
} from '../../../../helpers/functions/format-date';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';
import { roundNumber } from '../../../../helpers/functions/round-number';
import { CURRENCY_SYMBOL } from '../../../../constants/constants';
import { useProfile } from '../../../profile/configs/store/state';

const StyledTopBoardActions = styled.div`
    z-index: 1;
    padding: 4px 16px;
    margin-bottom: 2px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    background: ${props => props.theme.colors.transparent5};
`;

const FirstRow = styled.div`
    flex: 1;
    height: 35px;
    display: flex;
    justify-content: space-between;
`;

const Avatar = styled.div`
    display: flex;
    align-items: center;
`;

const Name = styled.div`
    font-size: 15px;
`;

const Date = styled.div`
    display: flex;
    color: ${props => props.theme.colors.transparent4};
    opacity: 0.5;
    font-size: 14px;
`;

const SecondRow = styled.div`
    display: flex;
`;

const Block = styled.div`
    height: 100%;
    flex: 35;
`;

const Block2 = styled.div`
    height: 100%;
    flex: 37;
`;

const Block3 = styled.div`
    height: 100%;
    flex: 24;
`;

const BlockLabel = styled.div`
    height: 20px;
    color: ${props => props.theme.colors.transparent4};
    opacity: 0.5;
    font-size: 14px;
    display: flex;
    align-items: center;
`;

const BlockValue = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 16px;
`;

const BetValue = styled(BlockValue)`
    color: ${props => props.theme.colors.vermillionTwo};
`;

const MultiplierValue = styled(BlockValue)`
    color: ${props => props.theme.colors.frogGreen};
`;

const ProfitValue = styled(BlockValue)`
    font-weight: bold;
`;

const WinningTableItem: FC<{
    game: Winnings;
}> = ({ game }) => {
    const translate = useTranslate();
    const formatDate = useFormatDate();
    const { Player } = useProfile();

    return (
        <StyledTopBoardActions>
            <FirstRow>
                <Avatar>
                    <TableAvatar avatarId={game.Player.A} />
                    <Name>
                        {game.Player.I === Player.I
                            ? game.Player.N
                            : formatUsername(game.Player.N)}
                    </Name>
                </Avatar>
                <Date>
                    {`${formatDate(game.Timestamp)} ${formatTime(
                        game.Timestamp
                    )}`}
                </Date>
            </FirstRow>
            <SecondRow>
                <Block>
                    <BlockLabel>{translate(KEYWORDS.Bet)}</BlockLabel>
                    <BetValue>
                        {game.BuyIn}
                        {CURRENCY_SYMBOL}
                    </BetValue>
                </Block>
                <Block2>
                    <BlockLabel>{translate(KEYWORDS.WinMultiplier)}</BlockLabel>
                    <MultiplierValue>{game.Multiplier}x</MultiplierValue>
                </Block2>
                <Block3>
                    <BlockLabel>{translate(KEYWORDS.WinAmount)}</BlockLabel>
                    <ProfitValue>
                        {roundNumber(game.BuyIn * game.Multiplier)}
                        {CURRENCY_SYMBOL}
                    </ProfitValue>
                </Block3>
            </SecondRow>
        </StyledTopBoardActions>
    );
};

export default WinningTableItem;
