import React, { FC, memo, useRef, useState } from 'react';
import styled from 'styled-components';
import ArrowUp from '../Icons/ArrowUp';
import ArrowDown from '../Icons/ArrowDown';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CURRENCY_SYMBOL } from '../../constants/constants';

const StyledNumberInput = styled.div<{ fontSize: number; disabled?: boolean }>`
    height: 100%;
    width: 100%;
    min-height: 30px;
    background: ${props => props.theme.colors.transparent};
    position: relative;
    & input {
        display: block;
        border: none;
        padding: 0 25px 0 16px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        background: transparent;
        outline: none;
        font-size: ${props => props.fontSize}px;
        font-family: ${props => props.theme.fonts.mediatorNarrow};
        &:disabled {
            opacity: 0.5;
            font-size: ${props => props.fontSize}px;
            color: ${props => props.theme.colors.whiteGrayish} !important;
            text-shadow: none;
        }
    }

    @media (max-height: ${550}px), (max-width: ${600}px) {
        & input {
            font-size: 16px;
            &:disabled {
                font-size: 16px;
            }
        }
    }
    @media (max-width: ${1100}px) {
        & input {
            font-size: 16px;
            padding: 0 20px 0 4px;
            &:disabled {
                font-size: 16px;
            }
        }
    }
`;

const Input = styled(MaskedInput)`
    height: 100%;
    width: 100%;
`;

const Arrows = styled.div<{ disabled?: boolean }>`
    position: absolute;
    width: 30px;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${props =>
        props.disabled &&
        `
        opacity: 0.5;
        pointer-events: none;
    `}
`;

const ArrowContainer = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.transparent2};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 2px 0;
    &:active {
        opacity: 0.9;
    }
`;

const ArrowIcon = styled.div`
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const defaultMaskOptions = {
    prefix: '',
    suffix: ` ${CURRENCY_SYMBOL}`,
    includeThousandsSeparator: false,
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 10,
    allowNegative: false,
    allowLeadingZeroes: false,
};

const emptyMaskOptions = {
    prefix: '',
    suffix: '',
};

function formatDecimalsNumber(value: number | null) {
    if (!value) return 0;
    return Number(Math.round(parseFloat(value + 'e' + 2)) + 'e-' + 2);
}

const NumberInput: FC<{
    suffix?: string;
    prefix?: string;
    value: number;
    onChange: (value: number) => void;
    step?: number;
    disabled?: boolean;
    minValue?: number;
    maxValue?: number;
    maxLength?: number;
    fontSize?: number;
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}> = ({
    value,
    onChange,
    suffix,
    prefix,
    step = 0.1,
    disabled,
    minValue = 0.1,
    maxValue,
    maxLength,
    fontSize = 24,
    className,
}) => {
    const inputRef = useRef<any>(null);

    const maskOptions = {
        suffix: suffix ? suffix : defaultMaskOptions.suffix,
        prefix: prefix ? prefix : defaultMaskOptions.prefix,
    };

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    });

    const focusedMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
        ...emptyMaskOptions,
    });

    const handleInputChange = (val: string) => {
        let suffix = maskOptions?.suffix || defaultMaskOptions.suffix;
        let prefix = maskOptions?.prefix || defaultMaskOptions.prefix;
        let updatedValue = val.replace(suffix, '');
        updatedValue = updatedValue.replace(prefix, '');
        onChange(Number(updatedValue));
    };

    const handleIncrement = () => {
        onChange(formatDecimalsNumber(Number(value + step)));
    };

    const handleDecrement = () => {
        onChange(formatDecimalsNumber(Number(value - step)));
    };
    const [hideMask, setHideMask] = useState(false);

    return (
        <StyledNumberInput
            disabled={disabled}
            fontSize={fontSize}
            className={className}
        >
            <Input
                ref={inputRef}
                onBlur={() => {
                    setHideMask(false);
                }}
                onFocus={event => {
                    setHideMask(true);
                    const val = event.target.value;
                    event.target.value = '';
                    event.target.value = val;
                }}
                mask={hideMask ? focusedMask : currencyMask}
                value={value}
                step={step}
                min={minValue}
                max={maxValue}
                maxLength={maxLength}
                onChange={event => {
                    handleInputChange(event.target.value);
                }}
                disabled={disabled}
                inputMode="numeric"
            />
            <Arrows disabled={disabled}>
                <ArrowContainer
                    onClick={!disabled ? handleIncrement : undefined}
                >
                    <ArrowIcon>
                        <ArrowUp />
                    </ArrowIcon>
                </ArrowContainer>
                <ArrowContainer
                    onClick={!disabled ? handleDecrement : undefined}
                >
                    <ArrowIcon>
                        <ArrowDown />
                    </ArrowIcon>
                </ArrowContainer>
            </Arrows>
        </StyledNumberInput>
    );
};

export default memo(NumberInput);
