import { useSelector } from 'react-redux';
import { variantIcon } from '../../../components/InfoSnackbar/InfoSnackbar';
import {SelectMessagesState} from "../../../config/store/selectors";

export interface ErrorMessagesState {
    status: keyof typeof variantIcon | null;
    message: string;
    keyword?: string;
}

export function useErrorMessagesState(): ErrorMessagesState[] {
    return useSelector(SelectMessagesState);
}
