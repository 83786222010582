import { CURRENCY_SYMBOL } from '../../../constants/constants';

export const georgian = {
    AccountBanned: 'თქვენი ანგარიში დაბლოკილია!',
    AllBets: 'ყველა ფსონი:',
    AlreadyRegistered: 'თქვენ უკვე დადებული გაქვთ ფსონი',
    Animation: 'ანიმაცია',
    AutoBet: 'ავტომატური',
    AutoBetting: 'ავტო ფსონი',
    AutoCashout: 'ავტო ქეშაუთი',
    AutoCashouting: 'ავტო განაღდება',
    Balance: 'ბალანსი',
    BalanceShort: 'ბალ',
    Banned: 'თქვენ დაბლოკილი ხართ',
    BaseBet: 'საწყისი ფსონი',
    Bet: 'ფსონი',
    BetMaximum: `ბეთი უნდა იყოს მაქსიმუმ {}${CURRENCY_SYMBOL}`,
    BetMustBe: 'ბეთი უნდა იყოს მინიმუმ ',
    Bets: 'ფსონები',
    Boom: 'BOOM!',
    BustedJackpot: 'გათამაშებული ჯეკპოტი',
    BuyIn: 'ფსონი',
    Cashout: 'ქეშაუთი',
    CashoutFromGame: 'ქეშაუთი',
    CashoutMaxMustBe: 'ქეშაუთი უნდა იყოს მაქსიმუმ',
    CashoutMustBe: 'ქეშაუთი უნდა იყოს მინუმუმ 1.01',
    ChangeAvatar: 'ავატარის შეცვლა',
    Chat: 'ჩატი',
    ChatBlocked: 'თქვენი ჩატი დაბლოკილია',
    CheckFair: '1. რა არის კოეფიციენტის გადამოწმება?',
    CheckHash: '3. როგორ გადავამოწმოთ სანდოობის კოდი?',
    CheckResult: 'შედეგის შემოწმება',
    Circulation: '#',
    Clear: 'გასუფთავება',
    Crashed: 'კოეფ.',
    CurrentBets: 'ფსონები',
    Day: '24 საათის',
    DearUsers: 'ბოდიშს გიხდით შეფერხებისთვის',
    Error: 'შეცდომა',
    ExampleForFair:
        'თამაშის ნომერი -  9088 თამაშის კოეფიციენტი - 1.06 გენერირების დრო - 12/03/2019_16:09 თამაშის უნიკალური ნომერი - Fvv+5TVHK2621A== შედეგად, თამაშის გასაღები იქნება: 9088_1.06_Boom_12/03/2019_16:09_Fvv+5TVHK2621A== ხოლო მისგან SHA256 ალგორითმით გენერირებული სანდოობის კოდი: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d',
    Examples: 'მაგალითად:',
    FairGame: 'სამართლიანი თამაში',
    Fill: 'შევსება',
    FreeBet: 'ფრიბეთი',
    GameIsStopped: 'თამაში შეჩერებულია',
    GameKey: '2. რას ნიშნავს თამაშის გასაღები და სანდოობის კოდი?',
    ServerConnectionTemp: 'სერვერთან კავშირის აღდგენა.',
    GameRules: 'თამაშის წესები',
    GameStart: 'თამაში იწყება',
    GameStarted: 'თამაში დაიწყო, წარმატებები',
    GameStatusIsNotRegistering: 'ფსონების მიღება დასრულებულია',
    GameWillResume: 'თამაშის განახლებამდე დარჩა',
    GotoHome: 'HOME',
    Hash: 'Hash კოდი',
    History: 'ისტორია',
    HowToCheckFair:
        "ეს მეთოდი საშუალებას გვაძლევს დავრწმუნდეთ, რომ თამაშის კოეფიციენტი (რიცხვი, რომელზეც 'ჩაიწვება' თამაში) განსაზღვრულია წინასწარ, სანამ მოთამაშეები დარეგისტრირდებიან. ნებისმიერ მომენტში წინასწარ განსაზღვრულია 5 თამაში. ქვემოთ მოყვანილია 5 გასული და 5 მომავალი თამაშის სანდოობის Hash კოდი.",
    HowToCheckGameKey:
        "თამაშების კოეფიციენტი (რიცხვი, რომელზეც 'ჩაიწვება' თამაში) განისაზღვრება წინასწარ, 5 თამაშით ადრე. თამაშის სერვერზე გენერირდება თამაშის გასაღები, რომელიც შედგება შემდეგი პარამეტრებისგან: [თამაშის ნომერი]_[თამაშის კოეფიციენტი]_[Boom]_[გენერირების დრო]_[თამაშის უნიკალური ნომერი]",
    HowToCheckHash:
        'თამაშის დამთავრების შემდეგ ქვემოთ გამოჩნდება თამაშის გასაღები რომელიც შეგიძლიათ დაშიფროთ ნებისმიერ ონლაინ SHA256 კალკულატორზე და შემდეგ შეადაროთ სანდოობის კოდს, რომელიც წინასწარ არის მოცემული მომდევნო 5 თამაშზე.',
    IncreaseBy: 'გაზარდე',
    Jackpot: 'ჯეკპოტი',
    JackpotRuleBust:
        'ჯეკპოტი ჩამოვარდება შემთხვევითობის პრინციპით და თანაბრად გაიყოფა რაუნდში მონაწილე იმ მოთამაშეებზე, რომლებმაც',
    JackpotRules: 'ჯეკპოტის წესები',
    JackpotRulesBalance: 'მოგება ავტომატურად აისახება მოთამაშის ბალანსზე.',
    JackpotRulesBetCondition: `დადეს მინიმუმ 1${CURRENCY_SYMBOL} ფსონი`,
    JackpotRulesCashoutCondition: 'არ დააქეშაუთეს 1.2-ზე ნაკლებ კოეფიციენტზე',
    Key: 'გასაღები',
    LanguageChangeAcception: 'ნამდვილად გსურთ ენის შეცვლა?',
    LanguageChangeWarning: 'ღილაკზე დაჭერით გაგეთიშებათ მიმდინარე თამაში',
    LastGames: 'ბოლო თამაშები',
    LastHand: 'წინა ხელი',
    LastJackpot: 'ბოლო ჯეკპოტი',
    LastTopSpins: 'ბოლო 24 საათის ტოპ კოეფიციენტები',
    LastTopWins: 'ბოლო 24 საათის ტოპ მოგებები',
    LeaveMessage: 'დაწერეთ კომენტარი',
    Login: 'შესვლა',
    LoginFailed: 'შესვლისას დაფიქსირდა შეცდომა',
    MainPage: 'მთავარი გვერდი',
    ManualBet: 'ძირითადი',
    MinFreebetCashout: 'მინ. კოეფიციენტი 1.5',
    Month: 'თვის',
    MultiplierCashout: 'კოეფ. მოგება',
    Music: 'მუსიკა',
    My: 'ჩემი',
    No: 'არა',
    NotEnoughMoney: 'არასაკმარისი თანხა',
    OnLoss: 'წაგებისას',
    OnWin: 'მოგებისას',
    OpenHere: 'გახსენი აქ',
    PlaceYourBet: 'მიმდინარეობს ფსონების მიღება',
    Player: 'მოთამაშე',
    Rating: 'რეიტინგი',
    Register: 'ფსონის დადება',
    Registration: 'მიმდინარეობს ფსონის მიღება',
    Reload: 'განახლება',
    Result: 'შედეგი',
    ResultsHash: 'შედეგის ჰეშ კოდი',
    ReturnToBaseBet: 'დაბრუნდი საწყის ფსონზე',
    ReturnToCash: 'Cash-ზე დაბრუნება',
    Round: 'რაუნდი',
    Rules1: 'თამაშში მოთამაშეებს აქვთ საშუალება განათავსონ ფსონი რაუნდის დაწყებამდე.',
    Rules2: 'თამაშის დაწყებისას კოეფიცინეტი იწყებს ზრდას, მანამ სანამ არ ჩაიწვება.',
    Rules3: 'ჩაწვის წერტილი გენერირდება შემთხვევითობის პრინციპით.',
    Rules4: 'მოთამაშემ უნდა მოასწროს საკუთარი მოგების განაღდება ჩაწვამდე.',
    Rules5: 'ჩაწვის მომენტში გაუნაღდებელი ფსონები ითვლება წაგებულად.',
    Rules6: 'ჩაწვამდე განაღდებისას, მოთამაშის განთავსებული ფსონი მრავლდება იმ კოეფიციენტზე რა მომენტშიც მოხდა ფსონის განაღდება.',
    Rules7: `კოეფიციიენტის ზრდა იწყება 1.0-დან. 1.0-ზე ჩაწვის შემთხვევაში ყველა ფსონი ითვლება წაგებულად. მაგ. მოთამაშემ განათავსა ფსონი 5${CURRENCY_SYMBOL} და 2.0 კოეფიციენტზე თანხა გაანაღდა, მას დაერიცხება 2*5=10${CURRENCY_SYMBOL}. თუ კოეფიციენტი ჩაიწვება 2.0-ზე ადრე, მაგალითად 1.75-ზე, ამ შემთხვევაში მოთამაშე წააგებს მთლიან ბეთს 5${CURRENCY_SYMBOL}-ს.`,
    Rules8: 'თამაშის დატოვების (კავშირის წყვეტის) შემთხვევაში, მოხდება ფსონის ავტომატური დაქეშაუთება, ფსონის და იმ მომენტში არსებული სიმაღლის კოეფიციენტის ნამრავლის მიხედვით. თამაშის დატოვებად ჩაითვლება ნებისმიერი მიზეზით თამაშის სესიის შეწყვეტა.',
    Rules9: 'მოთამაშეს შეუძლია ერთდროულად ორი ფსონის დადება და მათი ცალ-ცალკე დაქეშაუთება.',
    Rules10:
        'მოთამაშეს შეუძლია დადოს ფსონი სასურველი პირობებით, ავტომატური ფსონის დადების საშუალებით. არსებულ ფანჯარაში შესაძლებელია ფსონის ოდენობის, განაღდების კოეფიციენტის, განაღდების მაქსიმალური ოდენობის განსაზღვრა. ასევე შემდეგი ფსონის პირობების წინასწარი განსაზღვრა მოგების ან წაგების შემთხვევაში.',
    Rules11: 'მოგება ავტომატურად აისახება მოთამაშის ბალანსზე.',
    Rules12:
        'მოთამაშეს შეუძლია ნახოს თავისი თამაშის ისტორია ღილაკზე „ჩემი“ დაკლიკებით.',
    Rules13:
        'თამაშში დადასტურებული ხარვეზის აღმოჩენის შემთხვევაში, თამაშის ორგანიზატორი უფლებას იტოვებს, მომხმარებელს დაუკავოს ან დაუკორექტიროს სავარაუდო მოგება.',
    Rules14: 'თამაშის ლიმიტები:',
    Rules14_1: `მინიმალური ფსონი - 0.1${CURRENCY_SYMBOL}`,
    Rules14_2: `მაქსიმალური ფსონი - 200${CURRENCY_SYMBOL}`,
    Rules15: `მოთამაშის მაქსიმალური მოგება (ფსონი*კოეფიციენტი) – 30,000${CURRENCY_SYMBOL}, როდესაც ფსონი*კოეფიციენტი მიაღწევს 30,000${CURRENCY_SYMBOL}-ს, სისტემა ავტომატურად გაანაღდებს აქტიურ ფსონს.`,
    Rules16: `ყველა მოთმაშის ჯამური მაქსიმალური მოგება რაუნდზე - 100,000${CURRENCY_SYMBOL}. როდესაც ერთ რაუნდზე, ყველა მოთამაშის განთავსებული ფსონი*კოეფიციენტი მიაღწევს 100,000${CURRENCY_SYMBOL}-ს, სისტემა ატომატურად გაანაღდებს ყველა აქტიურ ფსონს.`,
    Rules18: `თამაშის RTP არის 95.05%`,
    Rules19: `მოთამაშეს შეუძლია ნახოს თამაშის ბოლო კოეფიციენტები თამაშის ფანჯრის ზედა სექციაში.`,
    Rules20: `მოთამაშეს შეუძლია ნახოს რეიტინგის ინფორმაცია, ღილაკზე „რეიტინგი“ დაკლიკებით.`,
    Save: 'შენახვა',
    ServerConnection: 'სერვერთან კავშირის აღდგენა',
    InternetConnectionLost:
        'დაფიქსირდა ინტერნეტთან კავშირის პრობლება, გთხოვთ გადატვირთეთ გვერდი.',
    ServerError: 'სერვერთან დაკავშირებისას მოხდა შეცდომა',
    SessionHasExpired: 'სესიას ვადა გაუვიდა',
    SitesUpdate: 'მიმდინარეობს ტექნიკური სამუშაოები!',
    Sound: 'ხმა',
    Statistics: 'სტატისტიკა',
    StopIf: 'გაჩერდი თუ ფსონი >',
    Submit: 'გაგზავნა',
    SuccessfulRegistration: 'ფსონი მიღებულია',
    TechnicalWork: 'თამაშის უწყვეტი ფუნქციონირება მალე აღდგება.',
    TicketCashoutMustBe: 'ქეშაუთი უნდა იყოს მინუმუმ 1.5',
    Time: 'დრო',
    TopSpins: 'ტოპ კოეფ.',
    TopWins: 'მოგებები',
    UnknowError: 'სერვერის შეცდომა',
    Unregister: 'ფსონის გაუქმება',
    UnregisterFreeBet: 'ფრიბეთის გაუქმება',
    Unregistered: 'ფსონი გაუქმებულია',
    User: 'მომხმარებელი',
    UserName: 'სახელი',
    UserShort: 'მომ',
    WaitingForNextRound: 'ველოდებით შემდეგ რაუნდს',
    Win: 'მოგება',
    WinAmount: 'მოგება',
    WinMultiplier: 'მოგ. კოეფ.',
    Winners: 'გამარჯვებულები',
    WrongAmount: 'არასწორი თანხა',
    WrongConfiguration: 'არასწორი კონფიგურაცია',
    Year: 'წლის',
    Yes: 'კი',
    YouAreNotRegistered: 'თქვენ არ ხართ დარეგისტრირებული',
    EnterText: 'შეიყვანეთ ტექსტი',
    PlayerNotFound: 'მოთამაშე არ მოიძებნა',
    Jan: 'იან.',
    Feb: 'თებ.',
    Mar: 'მარ.',
    Apr: 'აპრ.',
    May: 'მაის.',
    June: 'ივნ.',
    July: 'ივლ.',
    Aug: 'აგვ.',
    Sept: 'სექ.',
    Oct: 'ოქტ.',
    Nov: 'ნოე.',
    Dec: 'დეკ.',
    BlockedUser: 'თქვენი მომხმარებელი ბლოკილრებულია',
    LoadingProblem: 'თამაში ვერ ჩაიტვირთა, გთხოვთ გადავირთოთ გვერდი.',
};
