import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { useGameStatusState } from '../../../../../../config/store/state/app.state';
import { calculateGameDuration } from '../../../../../game/tools';

const CIRCLE_DASH = 521.504;

const CountDownCircle = styled.circle`
    stroke-dasharray: ${CIRCLE_DASH}, ${CIRCLE_DASH};
    transition: 0.35s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
`;

const ProgressCircle: FC<{ value: number }> = ({ value }) => {
    const gameStatus = useGameStatusState();

    const calculateDashOffset = (value: number) => {
        return -(
            CIRCLE_DASH -
            value / (calculateGameDuration(gameStatus.duration) / CIRCLE_DASH)
        );
    };

    return (
        <svg className="progress-ring" width={170} height={170}>
            <CountDownCircle
                className="progress-ring__circle"
                stroke="#F28323"
                strokeWidth="10"
                fill="transparent"
                r="80"
                cx="85"
                cy="85"
                style={{ strokeDashoffset: calculateDashOffset(value) }}
            />
            <circle
                className="progress-ring__circle"
                strokeWidth="10"
                fill="transparent"
                stroke="#ffffff14"
                r="80"
                cx="85"
                cy="85"
            />
        </svg>
    );
};

export default memo(ProgressCircle);
