import React, { FC, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import Multiplier from '../../../components/Multiplier/Multiplier';
import { motion } from 'framer-motion';
import { getMultiplierRGBColor } from '../../betColors';
import { roundNumber } from '../../../helpers/functions/round-number';
import { GAME_STATE } from '../../../constants/interfaces/Game';
import { useErrorsState } from '../../../config/store/state/app.state';

const MultiplierContainer = styled(motion.div)`
    position: absolute;
    left: 100%;
    top: 50px;

    @media (max-width: ${750}px) {
        left: 85%;
    }

    @media (max-width: ${550}px) {
        left: 85%;
        top: 20px;
    }

    @media (max-width: ${450}px) {
        left: 50%;
        top: 30px;
    }
`;

const StyledMultiplier = styled(Multiplier)`
    min-width: 200px;
    height: 112px;
    transition: all 0.2s;
    font-size: 45px;
    font-family: 'Roboto', monospace;

    @media (max-width: ${1500}px) {
        min-width: 175px;
    }

    @media (max-width: ${1000}px) {
        min-width: 125px;
        height: 88px;
        font-size: 28px;
    }

    @media (max-width: ${630}px) {
        min-width: 100px;
        height: 88px;
        font-size: 24px;
    }

    @media (max-width: ${580}px) {
        min-width: 80px;
        height: 82px;
        font-size: 24px;
    }

    @media (max-height: ${700}px) {
        height: 90px;
        font-size: 24px;
    }

    @media (max-height: ${500}px) {
        height: 88px;
        font-size: 24px;
    }
`;

export const MULTIPLIER_STEP = 100;
export const MULTIPLIER_DELAY = 125;

export const calculateMultiplierGrow = (currentMultiplier: number) => {
    return Number(
        (currentMultiplier + currentMultiplier / MULTIPLIER_STEP / 2).toFixed(2)
    );
};

export const getFinalMultiplier = (
    gameCrushed: boolean,
    gameError: boolean,
    localMultiplier: number,
    multiplier: number
) => {
    return gameCrushed ||
        gameError ||
        localMultiplier > calculateMultiplierGrow(multiplier + multiplier * 0.5)
        ? multiplier
        : localMultiplier;
};

const GameMultiplier: FC<{
    multiplier: number;
    gameState?: GAME_STATE;
}> = ({ multiplier = 1, gameState }) => {
    const animate = gameState === GAME_STATE.LAUNCHING;
    const gameCrushed = gameState === GAME_STATE.CRUSHED;
    const gameError = useErrorsState().isError;

    // BEGIN TEMP LOCAL CALCULATIONS
    const [localMultiplier, setLocalMultiplier] = useState(1);

    useEffect(() => {
        setLocalMultiplier(multiplier || 1);
    }, [multiplier]);

    useEffect(() => {
        if (gameState === GAME_STATE.RUNNING) {
            const interval = setInterval(() => {
                setLocalMultiplier(prevValue =>
                    calculateMultiplierGrow(prevValue)
                );
            }, MULTIPLIER_DELAY);

            return () => {
                clearInterval(interval);
            };
        }
    }, [multiplier, gameState, setLocalMultiplier]);

    // This is only because we have to count locally and can't display multiplier directly
    // from BE due to BE server lags, overall counting multiplier in FE is bad idea so this
    // should be rewritten.
    let finalMultiplayer = getFinalMultiplier(
        gameCrushed,
        gameError,
        localMultiplier,
        multiplier
    );
    // END TEMP LOCAL CALCULATIONS

    return (
        <MultiplierContainer
            initial={animate ? { x: 200 } : undefined}
            animate={animate ? { x: 0 } : undefined}
            transition={{ duration: 0.3 }}
        >
            <StyledMultiplier
                text={`${roundNumber(finalMultiplayer)}x`}
                color={getMultiplierRGBColor(localMultiplier)}
            />
            {/* Uncomment this section to display multiplier from BE directly */}
            {/*<StyledMultiplier*/}
            {/*    text={`${roundNumber(multiplier || 0)}x`}*/}
            {/*    color={getMultiplierRGBColor(multiplier || 0)}*/}
            {/*/>*/}
        </MultiplierContainer>
    );
};

export default memo(GameMultiplier);
