export const avatarsList = [
    { path: '/images/avatars/0.svg', id: 0 },
    { path: '/images/avatars/1.svg', id: 1 },
    { path: '/images/avatars/2.svg', id: 2 },
    { path: '/images/avatars/3.svg', id: 3 },
    { path: '/images/avatars/4.svg', id: 4 },
    { path: '/images/avatars/5.svg', id: 5 },
    { path: '/images/avatars/6.svg', id: 6 },
    { path: '/images/avatars/7.svg', id: 7 },
    { path: '/images/avatars/8.svg', id: 8 },
    { path: '/images/avatars/9.svg', id: 9 },
    { path: '/images/avatars/10.svg', id: 10 },
    { path: '/images/avatars/11.svg', id: 11 },
    { path: '/images/avatars/12.svg', id: 12 },
    { path: '/images/avatars/13.svg', id: 13 },
    { path: '/images/avatars/14.svg', id: 14 },
    { path: '/images/avatars/15.svg', id: 15 },
    { path: '/images/avatars/16.svg', id: 16 },
    { path: '/images/avatars/17.svg', id: 17 },
    { path: '/images/avatars/18.svg', id: 18 },
    { path: '/images/avatars/19.svg', id: 19 },
    { path: '/images/avatars/20.svg', id: 20 },
    { path: '/images/avatars/21.svg', id: 21 },
    { path: '/images/avatars/22.svg', id: 22 },
    { path: '/images/avatars/23.svg', id: 23 },
    { path: '/images/avatars/24.svg', id: 24 },
    { path: '/images/avatars/25.svg', id: 25 },
    { path: '/images/avatars/26.svg', id: 26 },
    { path: '/images/avatars/27.svg', id: 27 },
    { path: '/images/avatars/28.svg', id: 28 },
    { path: '/images/avatars/29.svg', id: 29 },
    { path: '/images/avatars/30.svg', id: 30 },
    { path: '/images/avatars/31.svg', id: 31 },
    { path: '/images/avatars/32.svg', id: 32 },
    { path: '/images/avatars/33.svg', id: 33 },
    { path: '/images/avatars/34.svg', id: 34 },
    { path: '/images/avatars/35.svg', id: 35 },
    { path: '/images/avatars/36.svg', id: 36 },
    { path: '/images/avatars/37.svg', id: 37 },
    { path: '/images/avatars/38.svg', id: 38 },
    { path: '/images/avatars/39.svg', id: 39 },
    { path: '/images/avatars/40.svg', id: 40 },
    { path: '/images/avatars/41.svg', id: 41 },
    { path: '/images/avatars/42.svg', id: 42 },
    { path: '/images/avatars/43.svg', id: 43 },
    { path: '/images/avatars/44.svg', id: 44 },
    { path: '/images/avatars/45.svg', id: 45 },
    { path: '/images/avatars/46.svg', id: 46 },
    { path: '/images/avatars/47.svg', id: 47 },
    { path: '/images/avatars/48.svg', id: 48 },
    { path: '/images/avatars/49.svg', id: 49 },
    { path: '/images/avatars/50.svg', id: 50 },
    { path: '/images/avatars/51.svg', id: 51 },
    { path: '/images/avatars/52.svg', id: 52 },
    { path: '/images/avatars/53.svg', id: 53 },
    { path: '/images/avatars/54.svg', id: 54 },
    { path: '/images/avatars/55.svg', id: 55 },
    { path: '/images/avatars/56.svg', id: 56 },
    { path: '/images/avatars/57.svg', id: 57 },
    { path: '/images/avatars/58.svg', id: 58 },
    { path: '/images/avatars/59.svg', id: 59 },
    { path: '/images/avatars/60.svg', id: 60 },
    { path: '/images/avatars/61.svg', id: 61 },
    { path: '/images/avatars/62.svg', id: 62 },
    { path: '/images/avatars/63.svg', id: 63 },
    { path: '/images/avatars/64.svg', id: 64 },
    { path: '/images/avatars/65.svg', id: 65 },
    { path: '/images/avatars/66.svg', id: 66 },
    { path: '/images/avatars/67.svg', id: 67 },
    { path: '/images/avatars/68.svg', id: 68 },
    { path: '/images/avatars/69.svg', id: 69 },
];
