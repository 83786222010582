import React, { FC } from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
    width: 100%;
    border-spacing: 0 3px;
    table-layout: fixed;
`;

export const Table: FC<{ className?: string }> = ({ children, className }) => {
    return <StyledTable className={className}>{children}</StyledTable>;
};

export default Table;
