export interface IMixpanelUser {
    name: string;
    id: string;
}

export enum EMixpanelEvent {
    openChat = 'Open chat',
    closeChat = 'Close chat',
    switchToTheBetts = 'Switch to the betts',
    switchToMyBetts = 'Switch to my betts',
    switchToTopBetts = 'Switch to top betts',
    switchToManualBettingBoard = 'Switch to manual betting board',
    switchToAutoBettingBoard = 'Switch to auto betting board',
    bet = 'Bet',
    selectPredefinedBet = 'Select predefined bet',
    cashout = 'Cashout',
}
