import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import BetsModal from '../BetsModal';
import { GameHistory } from '../../constants/interfaces/GameHistory';
import { getMultiplierRGBColor } from '../../features/betColors';
import { roundNumber } from '../../helpers/functions/round-number';

const StyledResultBox = styled(motion.div)<{ color?: string }>`
    margin: 4px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    line-height: 20px;
    padding: 5px 13px;
    display: inline-block;
    background-color: ${props =>
        props.color ? props.color : props.theme.colors.leafyGreen};
    font-weight: bold;
    user-select: none;

    @media (max-height: ${500}px), (max-width: ${700}px) {
        padding: 2px 8px;
    }
`;

const ResultBox: FC<{ game: GameHistory }> = ({ game }) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            {open && (
                <BetsModal
                    closeModal={() => setOpen(false)}
                    bustedAt={game.BustedAt}
                    gameId={game.ID}
                    hashCode={game.CrashHash}
                    hashKey={game.CrashSource}
                />
            )}
            <StyledResultBox
                initial={{ y: -10 }}
                animate={{ x: 0, y: 0 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                color={getMultiplierRGBColor(game.BustedAt)}
                onClick={() => setOpen(true)}
            >
                {roundNumber(game.BustedAt)}x
            </StyledResultBox>
        </div>
    );
};

export default ResultBox;
