import React, { FC } from 'react';
import styled from 'styled-components';
import SelectButton from '../../../../../components/SelectButton/SelectButton';
import { useBalance } from '../../../../profile/configs/store/state';
import { CURRENCY_SYMBOL } from '../../../../../constants/constants';

const SelectButtonsActionRow = styled.div<{
    disabled?: boolean;
}>`
    display: flex;
    margin-bottom: 6px;
    height: 40px;
    @media (max-height: ${750}px), (max-width: ${1100}px) {
        height: 30px;
    }
    ${(props) =>
        props.disabled &&
        `
            pointer-events: none;
        }
    `};

    @media (max-height: ${550}px) {
        margin-bottom: 6px;
    }
`;

const StyledSelectButton = styled(SelectButton)`
    width: 16%;
    margin-right: 24px;
    @media (max-height: ${550}px), (max-width: ${1100}px) {
        font-size: 12px;
    }
`;

const SelectButtonActions: FC<{
    amount: number;
    onChange: (value: number) => void;
    disabled: boolean;
}> = ({ amount, onChange, disabled }) => {
    const freeSpinBalance = useBalance()?.FreeSpinBalance || [];
    freeSpinBalance.sort(function (a, b) {
        return a.Amount - b.Amount;
    });

    return (
        <SelectButtonsActionRow disabled={disabled}>
            {freeSpinBalance.map((freeSpin, index) => {
                return (
                    <StyledSelectButton
                        key={index}
                        text={`${freeSpin.Amount} ${CURRENCY_SYMBOL}`}
                        selected={freeSpin.Amount === amount}
                        onClick={() => onChange(freeSpin.Amount)}
                        disabled={disabled}
                        count={freeSpin.Count}
                    />
                );
            })}
        </SelectButtonsActionRow>
    );
};

export default SelectButtonActions;
