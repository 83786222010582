import React, { FC } from 'react';
import { IconProps } from './types';

export const History: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 15 13"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g transform="translate(-21 -119)">
                <g transform="translate(21 84.555)">
                    <path
                        d="M275.482,172.228v3.2a.456.456,0,0,0,.134.323l2.286,2.286.646-.646-2.152-2.152v-3.007Z"
                        transform="translate(-267.933 -134.019)"
                        fill="#e74e0f"
                    />
                    <path
                        d="M8.437,34.445A6.547,6.547,0,0,0,1.95,40.016H0l2.344,2.321,2.344-2.321H2.9A5.577,5.577,0,1,1,4,44.372l-.739.571a6.537,6.537,0,1,0,5.175-10.5Z"
                        fill="#e74e0f"
                    />
                </g>
            </g>
        </svg>
    );
};

export default History;
