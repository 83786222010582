import React, { FC } from 'react';
import { IconProps } from './types';

export const Rocket: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 244.118 357.844"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="1"
                    y1="0.628"
                    x2="-0.443"
                    y2="0.628"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#e6e6e6" />
                    <stop offset="1" stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="1.385"
                    y1="1"
                    x2="0"
                    y2="0.5"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fff" stopOpacity="0" />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="0.5"
                    x2="0.5"
                    y2="2.413"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#1a1a1a" />
                    <stop offset="1" stopColor="#666" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="1"
                    y1="0.628"
                    x2="-0.279"
                    y2="0.622"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#f4f3f2" />
                    <stop offset="1" stopColor="#696868" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="-0.385"
                    x2="1"
                    xlinkHref="#linear-gradient-2"
                />
                <linearGradient
                    id="linear-gradient-7"
                    x1="0.056"
                    y1="0.455"
                    x2="0.81"
                    y2="0.456"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#d1d1d1" />
                    <stop offset="1" stopColor="#919191" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-10"
                    x1="0.056"
                    y1="0.455"
                    x2="1"
                    y2="0.453"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fff" />
                    <stop offset="1" stopColor="#afafaf" />
                </linearGradient>
                <filter
                    id="Path_3231"
                    x="58.27"
                    y="0"
                    width="137.351"
                    height="357.844"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dx="5" dy="5" />
                    <feGaussianBlur stdDeviation="12" result="blur" />
                    <feFlood floodOpacity="0.518" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <linearGradient
                    id="linear-gradient-11"
                    x1="0.433"
                    y1="0.403"
                    x2="1"
                    y2="0.403"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fff" stopOpacity="0" />
                    <stop offset="1" stopColor="#545454" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-18"
                    x1="0.056"
                    y1="0.455"
                    x2="0.81"
                    y2="0.456"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fff" />
                    <stop offset="1" stopColor="#919191" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-20"
                    x1="-0.385"
                    y1="0"
                    x2="1"
                    xlinkHref="#linear-gradient-2"
                />
            </defs>
            <g
                id="Component_7_22"
                data-name="Component 7 – 22"
                transform="translate(0 30.996)"
            >
                <path
                    id="Path_3233"
                    data-name="Path 3233"
                    d="M254.922,277.321V470.133a7.671,7.671,0,0,1-7.68,7.663H172.263a7.66,7.66,0,0,1-7.625-8.606c2.489-19.106,13.309-24.6,20.735-29.947C221.181,413.49,254.922,385.268,254.922,277.321Z"
                    transform="translate(-164.579 -201.524)"
                    fill="url(#linear-gradient)"
                />
                <rect
                    id="Rectangle_3090"
                    data-name="Rectangle 3090"
                    width="67.196"
                    height="0.958"
                    transform="translate(44.633 223.301)"
                    opacity="0.1"
                    fill="url(#linear-gradient-2)"
                />
                <g
                    id="Group_4616"
                    data-name="Group 4616"
                    transform="translate(0.22 141.011)"
                    opacity="0.524"
                >
                    <path
                        id="Path_3234"
                        data-name="Path 3234"
                        d="M184.583,447.326c28-20.14,54.746-41.8,65.069-101.92-10.943,56.179-36.93,77.159-64.109,96.706-7.426,5.341-18.239,10.846-20.735,29.942C168.428,457.1,177.882,452.145,184.583,447.326Z"
                        transform="translate(-164.808 -345.406)"
                        fill="url(#linear-gradient-3)"
                    />
                </g>
                <path
                    id="Path_3235"
                    data-name="Path 3235"
                    d="M326.77,277.321V470.133a7.671,7.671,0,0,0,7.68,7.663h74.979a7.66,7.66,0,0,0,7.624-8.606c-2.489-19.106-13.309-24.6-20.735-29.947C360.511,413.49,326.77,385.268,326.77,277.321Z"
                    transform="translate(-172.995 -201.524)"
                    fill="url(#linear-gradient-4)"
                />
                <rect
                    id="Rectangle_3088"
                    data-name="Rectangle 3088"
                    width="67.196"
                    height="0.958"
                    transform="translate(133.428 223.301)"
                    opacity="0.195"
                    fill="url(#linear-gradient-5)"
                />
                <g
                    id="Group_4617"
                    data-name="Group 4617"
                    transform="translate(159.183 141.011)"
                >
                    <path
                        id="Path_3239"
                        data-name="Path 3239"
                        d="M397.473,447.326c-28.006-20.14-54.747-41.8-65.069-101.92,10.943,56.179,36.93,77.159,64.109,96.706,7.425,5.341,18.239,10.846,20.729,29.942C413.628,457.1,404.172,452.145,397.473,447.326Z"
                        transform="translate(-332.404 -345.406)"
                        fill="url(#linear-gradient-3)"
                    />
                </g>
                <rect
                    id="Rectangle_3055"
                    data-name="Rectangle 3055"
                    width="17.562"
                    height="29.114"
                    rx="3.275"
                    transform="translate(97.217 276.973)"
                    fill="url(#linear-gradient-7)"
                />
                <rect
                    id="Rectangle_3056"
                    data-name="Rectangle 3056"
                    width="17.562"
                    height="29.114"
                    rx="3.275"
                    transform="translate(131.8 276.973)"
                    fill="url(#linear-gradient-7)"
                />
                <rect
                    id="Rectangle_3057"
                    data-name="Rectangle 3057"
                    width="17.562"
                    height="29.114"
                    rx="3.275"
                    transform="translate(114.508 278.889)"
                    fill="url(#linear-gradient-7)"
                />
                <g
                    id="Group_4615"
                    data-name="Group 4615"
                    transform="translate(89.27 0.004)"
                >
                    <g
                        transform="matrix(1, 0, 0, 1, -89.27, -31)"
                        filter="url(#Path_3231)"
                    >
                        <path
                            id="Path_3231-2"
                            data-name="Path 3231"
                            d="M324.043,263.886V475.864a8.181,8.181,0,0,1-8.188,8.171H266.881a8.18,8.18,0,0,1-8.189-8.171V263.826a8.141,8.141,0,0,1,.21-1.836c1.817-7.887,15.258-63.677,31.882-63.8,16.726-.121,31.046,55.7,33.022,63.756A8.141,8.141,0,0,1,324.043,263.886Z"
                            transform="translate(-169.42 -167.19)"
                            fill="url(#linear-gradient-10)"
                        />
                    </g>
                </g>
                <path
                    id="Subtraction_1"
                    data-name="Subtraction 1"
                    d="M699.145,1068.843H681.866a8.189,8.189,0,0,0,8.185-8.171V848.694a8.158,8.158,0,0,0-.233-1.937c-.787-3.207-3.69-14.616-7.968-26.845a171.99,171.99,0,0,0-7.368-18.086c-3-6.182-6.01-10.91-8.939-14.051,2.88-3.149,5.747-4.756,8.531-4.776h.054a10.121,10.121,0,0,1,6.131,2.409,29.026,29.026,0,0,1,5.789,6.417c3.33,4.789,6.72,11.527,10.087,20.029a293.637,293.637,0,0,1,10.964,34.9,8.17,8.17,0,0,1,.233,1.938v211.986A8.189,8.189,0,0,1,699.145,1068.843Z"
                    transform="translate(-552.713 -782.997)"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                    opacity="0.441"
                    fill="url(#linear-gradient-11)"
                />
                <path
                    id="Path_3236"
                    data-name="Path 3236"
                    d="M287.379,401.311"
                    transform="translate(-169.498 -206.753)"
                    fill="#d1d0cf"
                />
                <path
                    id="Path_3240"
                    data-name="Path 3240"
                    d="M291.535,212.207a48.545,48.545,0,0,1,16.78,2.969c-4.972-9.737-10.723-17.033-16.79-16.988-5.962.044-11.519,7.227-16.283,16.822a48.526,48.526,0,0,1,16.293-2.8Z"
                    transform="translate(-170.077 -198.188)"
                    fill="url(#linear-gradient-3)"
                />
                <path
                    id="Path_3232"
                    data-name="Path 3232"
                    d="M291.26,226.492c-9.324,0-17.147,14.07-19.295,22.711a3.825,3.825,0,0,0,3.69,4.78h31.2a3.825,3.825,0,0,0,3.69-4.78C308.406,240.562,300.583,226.492,291.26,226.492Z"
                    transform="translate(-168.875 -201.297)"
                    fill="url(#linear-gradient-3)"
                />
                <rect
                    id="Rectangle_3078"
                    data-name="Rectangle 3078"
                    width="169.537"
                    height="0.96"
                    transform="translate(121.908 236.711) rotate(-90)"
                    opacity="0.195"
                    fill="url(#linear-gradient-5)"
                />
                <rect
                    id="Rectangle_3079"
                    data-name="Rectangle 3079"
                    width="67.196"
                    height="0.958"
                    transform="translate(87.35 66.216)"
                    opacity="0.195"
                    fill="url(#linear-gradient-5)"
                />
                <rect
                    id="Rectangle_3081"
                    data-name="Rectangle 3081"
                    width="67.196"
                    height="0.958"
                    transform="translate(87.35 123.686)"
                    opacity="0.195"
                    fill="url(#linear-gradient-5)"
                />
                <rect
                    id="Rectangle_3082"
                    data-name="Rectangle 3082"
                    width="67.196"
                    height="0.958"
                    transform="translate(87.35 180.198)"
                    opacity="0.195"
                    fill="url(#linear-gradient-5)"
                />
                <rect
                    id="Rectangle_3085"
                    data-name="Rectangle 3085"
                    width="2.88"
                    height="2.874"
                    transform="translate(120.948 65.259)"
                    fill="#b5b5b5"
                />
                <rect
                    id="Rectangle_3086"
                    data-name="Rectangle 3086"
                    width="2.88"
                    height="2.874"
                    transform="translate(120.948 122.728)"
                    fill="#b5b5b5"
                />
                <rect
                    id="Rectangle_3087"
                    data-name="Rectangle 3087"
                    width="2.88"
                    height="2.874"
                    transform="translate(120.948 179.241)"
                    fill="#b5b5b5"
                />
                <path
                    id="Path_3238"
                    data-name="Path 3238"
                    d="M256.963,448.97c-4.767,10.492-8.19,29.773-8.535,50.478a3.861,3.861,0,0,0,3.331,3.894l13.119,1.806a3.853,3.853,0,0,0,4.383-3.811h0a3.853,3.853,0,0,1,4.2-3.831l17.159,1.515a3.83,3.83,0,0,0,.68,0l17.158-1.515a3.853,3.853,0,0,1,4.2,3.831h0a3.853,3.853,0,0,0,4.384,3.811l13.12-1.806a3.862,3.862,0,0,0,3.331-3.894c-.345-20.7-3.768-39.985-8.535-50.478a3.877,3.877,0,0,0-3.528-2.285H260.49A3.875,3.875,0,0,0,256.963,448.97Z"
                    transform="translate(-168.756 -208.666)"
                    fill="url(#linear-gradient-18)"
                />
                <rect
                    id="Rectangle_3089"
                    data-name="Rectangle 3089"
                    width="52.681"
                    height="0.96"
                    transform="translate(200.144 276.461) rotate(-90)"
                    opacity="0.195"
                    fill="url(#linear-gradient-5)"
                />
                <rect
                    id="Rectangle_3091"
                    data-name="Rectangle 3091"
                    width="54.304"
                    transform="translate(44.168 277.747) rotate(-90)"
                    opacity="0.1"
                    fill="url(#linear-gradient-20)"
                />
                <ellipse
                    id="Ellipse_92"
                    data-name="Ellipse 92"
                    cx="10.84"
                    cy="10.816"
                    rx="10.84"
                    ry="10.816"
                    transform="translate(33.803 241.393)"
                    fill="#ccc"
                />
                <ellipse
                    id="Ellipse_93"
                    data-name="Ellipse 93"
                    cx="10.84"
                    cy="10.816"
                    rx="10.84"
                    ry="10.816"
                    transform="translate(189.314 241.393)"
                    fill="#9b9b9b"
                />
                <rect
                    id="Rectangle_3092"
                    data-name="Rectangle 3092"
                    width="5.76"
                    height="73.753"
                    rx="2.88"
                    transform="translate(119.029 222.343)"
                    fill="url(#linear-gradient-18)"
                />
                <rect
                    id="Rectangle_3097"
                    data-name="Rectangle 3097"
                    width="2.88"
                    height="2.874"
                    transform="translate(175.665 222.343)"
                    fill="#b5b5b5"
                />
                <rect
                    id="Rectangle_3098"
                    data-name="Rectangle 3098"
                    width="2.88"
                    height="2.874"
                    transform="translate(67.192 222.343)"
                    fill="#d0d0d0"
                />
                <g
                    id="Group_1753"
                    data-name="Group 1753"
                    transform="translate(114.531 201.712) rotate(-90)"
                >
                    <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M0,6A6.074,6.074,0,0,1,6.136,0a6.078,6.078,0,0,1,6.136,6.024,6.074,6.074,0,0,1-6.136,6A6.078,6.078,0,0,1,0,6"
                        transform="translate(0 1.786)"
                        fill="#e74e0f"
                    />
                    <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M5.426,7.548A2.663,2.663,0,0,1,2.764,5.1V4.667A2.679,2.679,0,1,1,5.426,7.548M5.907,0A5.019,5.019,0,0,0,2.764,1.119V.286H0V12.524H2.764V8.691A5.019,5.019,0,0,0,5.907,9.81a4.9,4.9,0,1,0,0-9.81"
                        transform="translate(55.905 3.143)"
                        fill="#383838"
                    />
                    <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M6.11,2.952H4.031V0H1.268V2.952H0V5.071H1.268v7.1H4.031v-7.1H6.11Z"
                        transform="translate(102.327)"
                        fill="#383838"
                    />
                    <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M8.088,7.572a2.668,2.668,0,0,1-5.324.214V7.357A2.661,2.661,0,0,1,5.426,4.929,2.627,2.627,0,0,1,8.088,7.572m2.738,0A4.934,4.934,0,0,0,2.764,3.786V0H0V12.167H2.764v-.833a5,5,0,0,0,3.118,1.119,4.911,4.911,0,0,0,4.944-4.881"
                        transform="translate(79.534)"
                        fill="#383838"
                    />
                    <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M2.586,4.918a2.818,2.818,0,0,1,2.789-2.7A2.246,2.246,0,0,1,7.429,3.3L2.611,5.218a2.682,2.682,0,0,0-.025-.3m.811,2.3,6.972-2.8A4.958,4.958,0,0,0,5.3,0,5.21,5.21,0,0,0,0,5.143a5.28,5.28,0,0,0,5.451,5.143,6.278,6.278,0,0,0,4.564-1.7l-1.5-1.5C5.5,9.162,3.5,7.465,3.4,7.215"
                        transform="translate(67.872 2.667)"
                        fill="#383838"
                    />
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M7.15,0V5.475a2.206,2.206,0,0,1-4.412,0V0H0V5.125H0A4.9,4.9,0,0,0,4.944,10,4.9,4.9,0,0,0,9.888,5.125V0Z"
                        transform="translate(25.455 2.952)"
                        fill="#383838"
                    />
                    <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M5.451,7.914a2.771,2.771,0,0,1-2.713-2.8,2.771,2.771,0,0,1,2.713-2.8,2.771,2.771,0,0,1,2.713,2.8,2.771,2.771,0,0,1-2.713,2.8M5.451,0A5.3,5.3,0,0,0,0,5.143a5.3,5.3,0,0,0,5.451,5.143A5.3,5.3,0,0,0,10.9,5.143,5.3,5.3,0,0,0,5.451,0"
                        transform="translate(43.684 2.667)"
                        fill="#383838"
                    />
                    <path
                        id="Path_9"
                        data-name="Path 9"
                        d="M2.611,4.918A2.818,2.818,0,0,1,5.4,2.222,2.246,2.246,0,0,1,7.454,3.3L2.637,5.218c-.025.025-.025-.125-.025-.3m.786,2.3,6.972-2.8A4.958,4.958,0,0,0,5.3,0,5.21,5.21,0,0,0,0,5.143a5.28,5.28,0,0,0,5.451,5.143,6.278,6.278,0,0,0,4.564-1.7l-1.5-1.5C5.5,9.162,3.524,7.465,3.4,7.215"
                        transform="translate(13.843 2.667)"
                        fill="#383838"
                    />
                    <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M2.611,4.918A2.818,2.818,0,0,1,5.4,2.222,2.246,2.246,0,0,1,7.454,3.3L2.637,5.218c-.025.025-.025-.125-.025-.3m.786,2.3,6.972-2.8A4.958,4.958,0,0,0,5.3,0,5.21,5.21,0,0,0,0,5.143a5.28,5.28,0,0,0,5.451,5.143,6.278,6.278,0,0,0,4.564-1.7l-1.5-1.5c-2.992,2.072-4.995.374-5.121.125"
                        transform="translate(91.425 2.667)"
                        fill="#383838"
                    />
                    <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M6.136.088C3.981-.287,0,.413,0,4.563V9.989H2.738V5.264c0-3.325,3.4-2.75,3.4-2.75Z"
                        transform="translate(36.94 2.678)"
                        fill="#383838"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Rocket;
