export enum ListStatus {
    DEFAULT = 'DEFAULT',
    WON = 'WON',
    LOST = 'LOST',
}

export interface ListUser {
    id: number;
    username: string;
    avatar: string | number;
    bet: number;
    status: ListStatus;
    PanelIndex: number;
    odds?: number | string;
    profit?: number | string;
    time?: string;
}
