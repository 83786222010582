import { combineReducers } from 'redux';
import { GameState } from '../state/app.state';
import gameReducers from './app.reducers';
import messageReducer from '../../../features/errorMessages/store/reducers';
import { ErrorMessagesState } from '../../../features/errorMessages/store/state';
import audioPlayerReducer from '../../../features/audioPlayer/store/reducers';
import { AudioPlayerState } from '../../../features/audioPlayer/store/state';
import { MessengerState } from '../../../features/chat/store/state';
import messengerReducer from '../../../features/chat/store/reducers';

export interface AppState {
    game: GameState;
    messages: ErrorMessagesState[];
    audioPlayer: AudioPlayerState;
    messenger: MessengerState;
}

const rootReducer = combineReducers({
    game: gameReducers,
    messages: messageReducer,
    audioPlayer: audioPlayerReducer,
    messenger: messengerReducer,
});

export default rootReducer;
