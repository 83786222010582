import React, { FC } from 'react';
import { ListStatus, ListUser } from '../../../../constants/interfaces/List';
import { TableRow } from '../../../../components/Table/TableRow';
import {
    SuccessFirstTableCell,
    SuccessFirstVTableCell,
    SuccessFourthTableCell,
    SuccessFourthVTableCell,
    SuccessSecondTableCell,
    SuccessSecondVTableCell,
    SuccessThirdTableCell,
    SuccessThirdVTableCell,
} from '../../components/BetsTable/TableSuccessCell';
import TableAvatar from '../../components/TableAvatar';
import {
    FailureFirstTableCell,
    FailureFirstVTableCell,
    FailureFourthTableCell,
    FailureFourthVTableCell,
    FailureSecondTableCell,
    FailureSecondVTableCell,
    FailureThirdTableCell,
    FailureThirdVTableCell,
} from '../../components/BetsTable/TableFailureCell';
import {
    NeutralFirstTableCell,
    NeutralFirstVTableCell,
    NeutralFourthTableCell,
    NeutralFourthVTableCell,
    NeutralSecondTableCell,
    NeutralSecondVTableCell,
    NeutralThirdTableCell,
    NeutralThirdVTableCell,
} from '../../components/BetsTable/NeutralTableCell';
import styled from 'styled-components';
import { roundNumber } from '../../../../helpers/functions/round-number';
import { CURRENCY_SYMBOL } from '../../../../constants/constants';
import { useProfile } from '../../../profile/configs/store/state';
import { formatUsername } from '../../../../helpers/functions/format-username';

const AvatarContainer = styled.span`
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    white-space: nowrap;
`;

const TableEllipsisWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
`;

const TableUsernameContainer = styled.div`
    right: 0;
    position: absolute;
    left: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const RenderTableRows: FC<{ items: ListUser[] }> = ({ items }): any => {
    return (
        items &&
        items.map((player, index) => {
            switch (player.status) {
                case ListStatus.WON: {
                    return (
                        <TableRow key={JSON.stringify(player + `${index}`)}>
                            <SuccessFirstTableCell>
                                <TableEllipsisWrapper>
                                    <AvatarContainer>
                                        <TableAvatar avatarId={player.avatar} />
                                    </AvatarContainer>
                                    <TableUsernameContainer>
                                        {player.username}
                                    </TableUsernameContainer>
                                </TableEllipsisWrapper>
                            </SuccessFirstTableCell>
                            <SuccessSecondTableCell>
                                {roundNumber(player.bet)} {CURRENCY_SYMBOL}
                            </SuccessSecondTableCell>
                            <SuccessThirdTableCell>
                                {player.odds ? player.odds : '-'}X
                            </SuccessThirdTableCell>
                            <SuccessFourthTableCell>
                                {player.profit
                                    ? `${roundNumber(
                                          player.profit
                                      )} ${CURRENCY_SYMBOL}`
                                    : '-'}
                            </SuccessFourthTableCell>
                        </TableRow>
                    );
                }
                case ListStatus.LOST: {
                    return (
                        <TableRow key={JSON.stringify(player + `${index}`)}>
                            <FailureFirstTableCell>
                                <TableEllipsisWrapper>
                                    <AvatarContainer>
                                        <TableAvatar avatarId={player.avatar} />
                                    </AvatarContainer>
                                    <TableUsernameContainer>
                                        {player.username}
                                    </TableUsernameContainer>
                                </TableEllipsisWrapper>
                            </FailureFirstTableCell>
                            <FailureSecondTableCell>
                                {roundNumber(player.bet)} {CURRENCY_SYMBOL}
                            </FailureSecondTableCell>
                            <FailureThirdTableCell>-</FailureThirdTableCell>
                            <FailureFourthTableCell>-</FailureFourthTableCell>
                        </TableRow>
                    );
                }
                default: {
                    return (
                        <TableRow key={JSON.stringify(player + `${index}`)}>
                            <NeutralFirstTableCell>
                                <TableEllipsisWrapper>
                                    <AvatarContainer>
                                        <TableAvatar avatarId={player.avatar} />
                                    </AvatarContainer>
                                    <TableUsernameContainer>
                                        {player.username}
                                    </TableUsernameContainer>
                                </TableEllipsisWrapper>
                            </NeutralFirstTableCell>
                            <NeutralSecondTableCell>
                                {roundNumber(player.bet)} {CURRENCY_SYMBOL}
                            </NeutralSecondTableCell>
                            <NeutralThirdTableCell>-</NeutralThirdTableCell>
                            <NeutralFourthTableCell>
                                {player.profit
                                    ? `${player.profit} ${CURRENCY_SYMBOL}`
                                    : '-'}
                            </NeutralFourthTableCell>
                        </TableRow>
                    );
                }
            }
        })
    );
};

export default RenderTableRows;

export const RenderUsernameRow: FC<{ player: ListUser }> = ({ player }) => {
    const { Player: currentPlayer } = useProfile();    
    const userName =
        currentPlayer.I === player.id
            ? currentPlayer.N
            : formatUsername(player.username);
    switch (player.status) {
        case ListStatus.WON: {
            return (
                <SuccessFirstVTableCell>
                    <TableEllipsisWrapper>
                        <AvatarContainer>
                            <TableAvatar avatarId={player.avatar} />
                        </AvatarContainer>
                        <TableUsernameContainer>
                            {userName}
                        </TableUsernameContainer>
                    </TableEllipsisWrapper>
                </SuccessFirstVTableCell>
            );
        }
        case ListStatus.LOST: {
            return (
                <FailureFirstVTableCell>
                    <TableEllipsisWrapper>
                        <AvatarContainer>
                            <TableAvatar avatarId={player.avatar} />
                        </AvatarContainer>
                        <TableUsernameContainer>
                            {userName}
                        </TableUsernameContainer>
                    </TableEllipsisWrapper>
                </FailureFirstVTableCell>
            );
        }
        default: {
            return (
                <NeutralFirstVTableCell>
                    <TableEllipsisWrapper>
                        <AvatarContainer>
                            <TableAvatar avatarId={player.avatar} />
                        </AvatarContainer>
                        <TableUsernameContainer>
                            {userName}
                        </TableUsernameContainer>
                    </TableEllipsisWrapper>
                </NeutralFirstVTableCell>
            );
        }
    }
};

export const RenderBetRow: FC<{ player: ListUser }> = ({ player }) => {
    switch (player.status) {
        case ListStatus.WON: {
            return (
                <SuccessSecondVTableCell>
                    {roundNumber(player.bet)} {CURRENCY_SYMBOL}
                </SuccessSecondVTableCell>
            );
        }
        case ListStatus.LOST: {
            return (
                <FailureSecondVTableCell>
                    {roundNumber(player.bet)} {CURRENCY_SYMBOL}
                </FailureSecondVTableCell>
            );
        }
        default: {
            return (
                <NeutralSecondVTableCell>
                    {roundNumber(player.bet)} {CURRENCY_SYMBOL}
                </NeutralSecondVTableCell>
            );
        }
    }
};

export const RenderOddsRow: FC<{ player: ListUser }> = ({ player }) => {
    switch (player.status) {
        case ListStatus.WON: {
            return (
                <SuccessThirdVTableCell>
                    {player.odds ? player.odds : '-'}X
                </SuccessThirdVTableCell>
            );
        }
        case ListStatus.LOST: {
            return <FailureThirdVTableCell>-</FailureThirdVTableCell>;
        }
        default: {
            return <NeutralThirdVTableCell>-</NeutralThirdVTableCell>;
        }
    }
};

export const RenderProfitRow: FC<{ player: ListUser }> = ({ player }) => {
    switch (player.status) {
        case ListStatus.WON: {
            return (
                <SuccessFourthVTableCell>
                    {player.profit
                        ? `${roundNumber(player.profit)} ${CURRENCY_SYMBOL}`
                        : '-'}
                </SuccessFourthVTableCell>
            );
        }
        case ListStatus.LOST: {
            return <FailureFourthVTableCell>-</FailureFourthVTableCell>;
        }
        default: {
            return (
                <NeutralFourthVTableCell>
                    {player.profit
                        ? `${player.profit} ${CURRENCY_SYMBOL}`
                        : '-'}
                </NeutralFourthVTableCell>
            );
        }
    }
};
