import React, { FC, useState } from 'react';
import styled from 'styled-components';
import ToggleInput from '../../../components/ToggleInput/ToggleInput';
import Survey from '../../../components/Icons/Survey';
import Security from '../../../components/Icons/Security';
import ArrowRight from '../../../components/Icons/ArrowRight';
import { useGameConfigs } from '../../../config/store/state/app.state';
import {
    useToggleConfigAnimationService,
    useToggleConfigBackgroundMusicService,
    useToggleConfigVoiceService,
} from '../configs/store/services';
import GameRulesModal from './GameRulesModal';
import ProvablyFairModal from './ProvablyFairModal';
import ArrowLeft from '../../../components/Icons/ArrowLeft';
import useTranslate from '../../../config/language';
import { KEYWORDS } from '../../../config/language/keywords';
import FreeBetsToggler from '../../freeBets/FreeBetsToggler';
import EuropeBetCircle from '../../../components/Icons/EuropebetCircle';
import { ReactComponent as EuropeBetCircleIcon } from '../../../assets/images/general/e_circle.svg';
import { HOME_WEBSITE } from '../../../constants/constants';
import { useIsMobile } from '../../../helpers/hooks/useIsMobile';
import { useSaveSettingsService } from '../../../config/store/services';
import { useProfile } from '../configs/store/state';
import { getQuery } from '../../../helpers/hooks/useQuery';

const StyledContainer = styled.div`
    padding: 16px 0;
`;

const ConfigurationItem = styled.div<{ clickable?: boolean }>`
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding: 4px 8px;
    border-bottom: 1px solid #ffffff1a;
    box-sizing: border-box;
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

const Label = styled.div`
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    font-weight: 500;
    font-size: 13px;
    display: flex;
    align-items: center;
`;

const LabelIcon = styled.div`
    width: 15px;
    margin-right: 8px;
    display: flex;
`;

const ActionIcon = styled.div`
    height: 11px;
    display: flex;
`;

const Actions = styled.div`
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
`;

const ArrowActions = styled.div`
    width: 50px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    &:hover {
        background: ${props => props.theme.colors.transparent3};
    }
`;

const GameConfigurations: FC = () => {
    const configs = useGameConfigs();
    const translate = useTranslate();
    const handleAnimate = useToggleConfigAnimationService();
    const handleBackgroundMusic = useToggleConfigBackgroundMusicService();
    const handleVoice = useToggleConfigVoiceService();
    const [openGameRules, setOpenGameRules] = useState(false);
    const [openProvablyFair, setOpenProvablyFair] = useState(false);
    const saveGameSettings = useSaveSettingsService();
    const profile = useProfile().Player;
    const vip = getQuery(window.location.search, 'isVip');

    const handleChangeBackgroundMusic = (on: boolean) => {
        handleBackgroundMusic(on);
        saveGameSettings({
            Avatar: profile?.A || 0,
            Sound: configs.voiceEffects,
            Music: on,
            Animate: configs.animate,
        });
    };

    const handleChangeVoiceEffects = (on: boolean) => {
        handleVoice(on);
        saveGameSettings({
            Avatar: profile?.A || 0,
            Sound: on,
            Music: configs.backgroundMusic,
            Animate: configs.animate,
        });
    };

    const handleChangeAnimations = (on: boolean) => {
        handleAnimate(on);
        saveGameSettings({
            Avatar: profile?.A || 0,
            Sound: configs.voiceEffects,
            Music: configs.backgroundMusic,
            Animate: on,
        });
    };

    const handleRedirectionToMain = () => {
        window.location.href = HOME_WEBSITE;
    };

    const isMobile = useIsMobile();

    return (
        <StyledContainer>
            {openGameRules && (
                <GameRulesModal closeModal={() => setOpenGameRules(false)} />
            )}
            {openProvablyFair && (
                <ProvablyFairModal
                    closeModal={() => setOpenProvablyFair(false)}
                />
            )}
            {isMobile && (
                <ConfigurationItem>
                    <Label>{translate(KEYWORDS.FreeBet)}</Label>
                    <Actions>
                        <FreeBetsToggler />
                    </Actions>
                </ConfigurationItem>
            )}
            <ConfigurationItem>
                <Label>{translate(KEYWORDS.Sound)}</Label>
                <Actions>
                    <ToggleInput
                        active={configs.voiceEffects}
                        onChange={() =>
                            handleChangeVoiceEffects(!configs.voiceEffects)
                        }
                    />
                </Actions>
            </ConfigurationItem>
            <ConfigurationItem>
                <Label>{translate(KEYWORDS.Music)}</Label>
                <Actions>
                    <ToggleInput
                        active={configs.backgroundMusic}
                        onChange={() =>
                            handleChangeBackgroundMusic(
                                !configs.backgroundMusic
                            )
                        }
                    />
                </Actions>
            </ConfigurationItem>
            <ConfigurationItem>
                <Label>{translate(KEYWORDS.Animation)}</Label>
                <Actions>
                    <ToggleInput
                        active={configs.animate}
                        onChange={() =>
                            handleChangeAnimations(!configs.animate)
                        }
                    />
                </Actions>
            </ConfigurationItem>
            <ConfigurationItem>
                <Label>
                    <LabelIcon>
                        <Survey />
                    </LabelIcon>
                    {translate(KEYWORDS.GameRules)}
                </Label>
                <ArrowActions onClick={() => setOpenGameRules(true)}>
                    <ActionIcon>
                        {openGameRules ? <ArrowLeft /> : <ArrowRight />}
                    </ActionIcon>
                </ArrowActions>
            </ConfigurationItem>
            <ConfigurationItem>
                <Label>
                    <LabelIcon>
                        <Security />
                    </LabelIcon>
                    Provably Fair
                </Label>
                <ArrowActions onClick={() => setOpenProvablyFair(true)}>
                    <ActionIcon>
                        {openProvablyFair ? <ArrowLeft /> : <ArrowRight />}
                    </ActionIcon>
                </ArrowActions>
            </ConfigurationItem>
            {isMobile && (
                <ConfigurationItem clickable onClick={handleRedirectionToMain}>
                    <Label>
                        <LabelIcon>
                            {vip ? (
                                <EuropeBetCircle />
                            ) : (
                                <EuropeBetCircleIcon />
                            )}
                        </LabelIcon>
                        {translate(KEYWORDS.MainPage)}
                    </Label>
                </ConfigurationItem>
            )}
        </StyledContainer>
    );
};

export default GameConfigurations;
