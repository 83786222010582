import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../../components/Modal';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';
import { avatarsList } from './avatarsList';
import Button from '../../../../components/Button/Button';
import { useProfile } from '../../configs/store/state';
import { useGameConfigs } from '../../../../config/store/state/app.state';
import { useSaveSettingsService } from '../../../../config/store/services';

const Container = styled.div`
    background: ${props => props.theme.colors.charcoalGreyTwo};
    font-family: ${props => props.theme.fonts.mediatorNarrowCaps};
    border-radius: 6px;
    box-shadow: none;
    width: 805px;
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%;
`;

const ModalHeader = styled.div`
    text-align: center;
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.mediatorNarrowCaps};
    font-weight: bold;
    letter-spacing: 0;
    color: ${({ theme }) => theme.colors.turtleGreen};
`;

const ModalBody = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 32px 0;
`;

const AvatarsList = styled.div`
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 32px;
    height: 330px;
    min-width: 164px;
    flex: 6;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
`;

const AvatarsListItem = styled.div``;

const AvatarsListItemImage = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 4px;
    margin: 8px;
    cursor: pointer;
    &:active {
        opacity: 0.8;
    }
`;

const ProfileAvatar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 4;
    min-width: 150px;
`;

const ProfileAvatarImage = styled.img`
    width: 110px;
    height: 110px;
    border-radius: 110px;
    margin-bottom: 8px;
`;

const ProfileAvatarName = styled.div`
    width: 100%;
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 17px;
    font-family: ${({ theme }) => theme.fonts.mediatorNarrowCaps};
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
`;

const ProfileAvatarButton = styled.div`
    width: 160px;
    height: 50px;
`;

const AvatarsModal: FC<{
    closeModal(): void;
}> = ({ closeModal }) => {
    const translate = useTranslate();
    const profile = useProfile().Player;
    const avatarId = profile?.A || 0;

    const [selectedAvatar, setSelectedAvatar] = useState(avatarId);
    const selectedAvatarPath = avatarsList.filter(
        avatar => avatar.id === Number(selectedAvatar)
    )[0].path;

    const configs = useGameConfigs();
    const saveSettings = useSaveSettingsService();

    const handleSaveSelectedAvatar = () => {
        saveSettings({
            Avatar: selectedAvatar,
            Music: configs.backgroundMusic,
            Sound: configs.voiceEffects,
            Animate: configs.animate,
        });
        closeModal();
    };

    return (
        <Modal closeModal={closeModal}>
            <Container>
                <ModalHeader>{translate(KEYWORDS.ChangeAvatar)}</ModalHeader>
                <ModalBody>
                    <AvatarsList>
                        {avatarsList.map(avatar => {
                            return (
                                <AvatarsListItem
                                    key={avatar.id}
                                    onClick={() => setSelectedAvatar(avatar.id)}
                                >
                                    <AvatarsListItemImage src={avatar.path} />
                                </AvatarsListItem>
                            );
                        })}
                    </AvatarsList>
                    <ProfileAvatar>
                        <ProfileAvatarImage src={selectedAvatarPath} />
                        <ProfileAvatarName>{profile.N}</ProfileAvatarName>
                        <ProfileAvatarButton onClick={handleSaveSelectedAvatar}>
                            <Button text={translate(KEYWORDS.Save)} />
                        </ProfileAvatarButton>
                    </ProfileAvatar>
                </ModalBody>
            </Container>
        </Modal>
    );
};

export default AvatarsModal;
