import React, { FC } from 'react';
import styled from 'styled-components';

const StyledPlusButton = styled.div<{ height?: number; width?: number }>`
    width: 71px;
    height: 100%;
    border-radius: 15px;
    background-color: ${props => props.theme.colors.transparent};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:active {
        opacity: 0.9;
    }
`;

const PlusButton: FC<{
    height?: number;
    width?: number;
    onClick?(): void;
}> = ({ children, height, width, onClick }) => {
    return (
        <StyledPlusButton height={height} width={width} onClick={onClick}>
            {children}
        </StyledPlusButton>
    );
};

export default PlusButton;
