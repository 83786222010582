import Image_0 from '../../assets/images/explosion/0.png';
import Image_1 from '../../assets/images/explosion/1.png';
import Image_2 from '../../assets/images/explosion/2.png';
import Image_3 from '../../assets/images/explosion/3.png';
import Image_4 from '../../assets/images/explosion/4.png';
import Image_5 from '../../assets/images/explosion/5.png';
import Image_6 from '../../assets/images/explosion/6.png';
import Image_7 from '../../assets/images/explosion/7.png';
import Image_8 from '../../assets/images/explosion/8.png';
import Image_9 from '../../assets/images/explosion/9.png';
import Image_10 from '../../assets/images/explosion/10.png';
import Image_11 from '../../assets/images/explosion/11.png';
import Image_12 from '../../assets/images/explosion/12.png';
import Image_13 from '../../assets/images/explosion/13.png';
import Image_14 from '../../assets/images/explosion/14.png';
import Image_15 from '../../assets/images/explosion/15.png';
import Image_16 from '../../assets/images/explosion/16.png';
import Image_17 from '../../assets/images/explosion/17.png';
import Image_18 from '../../assets/images/explosion/18.png';
import Image_19 from '../../assets/images/explosion/19.png';
import Image_20 from '../../assets/images/explosion/20.png';
import Image_21 from '../../assets/images/explosion/21.png';
import Image_22 from '../../assets/images/explosion/22.png';
import Image_23 from '../../assets/images/explosion/23.png';
import Image_24 from '../../assets/images/explosion/24.png';
import Image_25 from '../../assets/images/explosion/25.png';
import Image_26 from '../../assets/images/explosion/26.png';

export const EXPLOSION = [
    Image_0,
    Image_1,
    Image_2,
    Image_3,
    Image_4,
    Image_5,
    Image_6,
    Image_7,
    Image_8,
    Image_9,
    Image_10,
    Image_11,
    Image_12,
    Image_13,
    Image_14,
    Image_15,
    Image_16,
    Image_17,
    Image_18,
    Image_19,
    Image_20,
    Image_21,
    Image_22,
    Image_23,
    Image_24,
    Image_25,
    Image_26,
];
