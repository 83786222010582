import React, { memo } from 'react';
import styled from 'styled-components';
import { useBalance } from '../profile/configs/store/state';
import FreeBetsHandler from './FreeBetsHandler';
import FreeBetsToggler from './FreeBetsToggler';

const Container = styled.div`
    width: 80px;
    margin: 0 16px;
`;

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 3;
    margin: 0 4px;
`;

const FreeBetsContainer = () => {
    const freeBetsBalance = useBalance()?.FreeSpinBalance || [];
    const freeSpinTotalCount = freeBetsBalance
        .map(freeSpin => freeSpin.Count)
        .reduce((a, b) => a + b, 0);
    const isFreeBetsBalanceEmpty =
        freeBetsBalance.length < 1 || freeSpinTotalCount < 1;

    if (isFreeBetsBalanceEmpty) {
        return <Container />;
    }

    return (
        <Container>
            <ToggleContainer>
                <FreeBetsHandler />
                <FreeBetsToggler />
            </ToggleContainer>
        </Container>
    );
};

export default memo(FreeBetsContainer);
