import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getMultiplierRGBColor } from '../../features/betColors';

const StyledRulerContainer = styled.div`
    width: 50px;
    position: absolute;
    right: 32px;
    height: 100%;
    bottom: 10px;
    top: 10px;

    @media (max-width: ${750}px) {
        right: 0;
    }
`;

const StyledRuler = styled.div`
    top: 0;
    position: absolute;
    height: 100%;
    bottom: 10px;
    right: 10px;
    text-align: right;
    border-right: 1.5px solid #ffffff80;
    font-weight: 600;
    & > div {
        line-height: 0;
        position: relative;
    }

    & > .hidden {
        position: absolute !important;
        right: -1.3px;
        bottom: 0;
        color: blue;
        border: none;
        width: 1.6px;
    }
`;

const rulerUnits = [
    1,
    2,
    5,
    10,
    20,
    50,
    100,
    200,
    500,
    1e3,
    2e3,
    5e3,
    1e4,
    2e4,
    5e4,
];

const Ruler: FC<{ multiplier?: number | null }> = ({ multiplier }) => {
    const rulerRef = useRef<any>(null);

    useEffect(() => {
        let n = multiplier;
        if (rulerRef.current && n) {
            let result, t, u, h;
            let i = Math.max(n * 2, 10),
                f = rulerUnits.filter(n => i / n > 1 && i / n < 10)[0],
                rulerItem = rulerRef.current,
                rulerItemHeight =
                    rulerRef.current &&
                    rulerRef.current.getBoundingClientRect().height - 2,
                s = Math.floor((5 * i) / f);

            for (n < 2 && (n = (n - 1) * 2), result = '', t = 0; t <= s; t++) {
                u = (t * f) / 5;
                h = u <= n ? getMultiplierRGBColor(n) : '#ffffff80';
                result +=
                    '<div style=" color:' +
                    h +
                    '; top: ' +
                    (rulerItemHeight - (t * rulerItemHeight) / s) +
                    'px;">' +
                    (u % f === 0 ? (t === 0 ? 1 : u) + 'x ' : '') +
                    '-</div>';
            }
            let l = (rulerItemHeight * n) / i;
            result +=
                ' <div class="hidden" style="background-color:' +
                getMultiplierRGBColor(n) +
                '; height:' +
                l +
                'px;"> </div>';
            rulerItem.innerHTML = result;
        }
    }, [multiplier]);

    return (
        <StyledRulerContainer>
            <StyledRuler ref={rulerRef} />
        </StyledRulerContainer>
    );
};

export default Ruler;
