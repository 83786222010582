import React, { FC, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Preparing } from '../../spaceship/states/preparing';
import { useGameStatusState } from '../../../config/store/state/app.state';
import { calculateGameDuration } from '../tools';
import { useSetLaunchingService } from '../../../config/store/services';

const CoolDownContainer = styled.div`
    display: flex;
    align-self: center;
    margin-top: 60px;
`;

const GameCoolDown: FC = () => {
    const gameStatus = useGameStatusState();
    const setLaunching = useSetLaunchingService();
    const [countDown, setCountDown] = useState(
        calculateGameDuration(gameStatus.durationLeft || gameStatus.duration)
    );

    useEffect(() => {
        if (countDown <= 0) {
            setLaunching();
            return;
        }
        const interval = setInterval(() => {
            setCountDown(countDown - 100);
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [countDown, setCountDown, setLaunching]);

    return (
        <CoolDownContainer>
            <Preparing countDown={countDown} />
        </CoolDownContainer>
    );
};

export default memo(GameCoolDown);
