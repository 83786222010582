import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BoomImg from '../../assets/images/general/boom-app-logo.png';
import BackgroundContainer from '../Background';
import StarsContainer from '../Stars';
import { useLoadGameService } from '../../config/store/services';
import { useGameState } from '../../config/store/state/app.state';
import { GAME_STATE } from '../../constants/interfaces/Game';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: absolute;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;

const LoadingContainer = styled.div`
    z-index: 1;
`;

const AppLogoContainer = styled.div`
    width: 100%;
    max-width: 350px;
    height: 35vh;
    display: flex;
    max-height: 350px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transition: all 0.5s;
`;

const AppLogo = styled.img`
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: contain;
`;

const ProgressBarContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    width: 80%;
    max-width: 400px;
    height: 12px;
    margin: 50px auto 50px auto;
    overflow: hidden;
    transition: all 0.5s;
`;

const ProgressBar = styled.div<{ width: number }>`
    background-color: #ffffff;
    width: ${({ width }) => width}%;
    height: 100%;
    border-radius: 6px;
    transition: all 1s;
`;

const GameLoader = () => {
    const [loading, setLoading] = useState(0);
    const [loadingDuration, setLoadingDuration] = useState<number>(0);
    const handleLoadGame = useLoadGameService();
    const gameState = useGameState();

    useEffect(() => {
        const count = setInterval(() => {
            setLoadingDuration(prevState => prevState + 0.1);
        }, 100);

        return () => {
            clearInterval(count);
        };
    }, [loadingDuration, setLoadingDuration]);

    useEffect(() => {
        if (loading >= 90) {
            return;
        }

        const countdown = setInterval(() => {
            setLoading(prevState => prevState + 10);
        }, 100);

        return () => {
            clearInterval(countdown);
        };
    }, [gameState.status.state, loading, setLoading]);

    useEffect(() => {
        if (loading < 90) {
            return;
        }

        const countdown = setInterval(() => {
            if (gameState.status.state !== GAME_STATE.INITIAL) {
                setLoading(100);
            } else {
                if (loading < 99) {
                    setLoading(prevState => prevState + 0.5);
                }
            }
        }, 300);

        return () => {
            clearInterval(countdown);
        };
    }, [gameState.status.state, handleLoadGame, loading]);

    useEffect(() => {
        if (loading >= 100) {
            const timeout = setTimeout(() => {
                handleLoadGame(loadingDuration);
            }, 1000);

            return () => {
                clearTimeout(timeout);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleLoadGame, loading]);

    return (
        <Container>
            <BackgroundContainer disabled={true} />
            <StarsContainer disabled={true} />
            <LoadingContainer>
                <AppLogoContainer>
                    <AppLogo src={BoomImg} alt="app image" />
                </AppLogoContainer>
                <ProgressBarContainer>
                    <ProgressBar width={loading} />
                </ProgressBarContainer>
            </LoadingContainer>
        </Container>
    );
};

export default GameLoader;
