import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled.div``;
const Switch = styled(motion.div)<{ active: boolean; disabled?: boolean }>`
    width: 46px;
    box-sizing: border-box;
    padding: 0px 3px;
    position: relative;
    display: flex;
    justify-content: ${props => (props.active ? 'flex-end' : 'flex-start')};
    &:disabled {
        opacity: 0.45;
        pointer-events: none;
    }
`;

const Stick = styled(motion.div)<{ active: boolean }>`
    width: 100%;
    height: 16px;
    border-radius: 100px;
    display: flex;
    cursor: pointer;
    background-color: ${props =>
        props.active
            ? `${props.theme.colors.vermillionTwo}3d`
            : props.theme.colors.transparent};
`;

const Bullet = styled(motion.div)<{ active: boolean }>`
    width: 20px;
    height: 20px;
    margin-top: -2px;
    position: absolute;
    border-radius: 200px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
    cursor: pointer;
    background-color: ${props =>
        props.active
            ? `${props.theme.colors.vermillionTwo}`
            : props.theme.colors.charcoalGrey};
`;

const ToggleInput: FC<{
    active: boolean;
    onChange?(): void;
    disabled?: boolean;
    className?: string;
}> = ({ active, onChange, disabled, className }) => {
    return (
        <Container className={className}>
            <Switch
                active={active}
                onClick={() => {
                    if (disabled) return;
                    onChange && onChange();
                }}
                disabled={disabled}
            >
                <Stick
                    animate
                    active={active}
                    className={
                        active
                            ? 'switch-stick switch-stick-active'
                            : 'switch-stick'
                    }
                />
                <Bullet
                    animate
                    active={active}
                    className={
                        active
                            ? 'switch-bullet switch-bullet-active'
                            : 'switch-bullet'
                    }
                />
            </Switch>
        </Container>
    );
};

export default ToggleInput;
