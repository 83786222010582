import { georgian as ka } from './dictionary/georgian';
import { english as en } from './dictionary/english';
import { turkish as tr } from './dictionary/turkish';
import { russian as ru } from './dictionary/russian';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';

export enum LANGUAGES {
    ka = 'ka',
    en = 'en',
    tr = 'tr',
    ru = 'ru',
}

export const dictionaryList: any = { ka, en, tr, ru };

export const useGetLanguage = () => {
    const pathname = useLocation().pathname;

    return useCallback((): LANGUAGES => {
        const splitUrl = pathname.split('/');
        return splitUrl[1] as LANGUAGES;
    }, [pathname]);
};
