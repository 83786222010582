import { ETenantType, ITenant } from "../constants/interfaces/Tenant";

export const getIsTestTenant = (tenant: ITenant): boolean => {
    const testTenants = [ETenantType.Demo, ETenantType.Local];
    return testTenants.includes(tenant.Type);
};

export const isLocal = (): boolean => {
    const hostname: string = window.location.hostname;
    return hostname === '127.0.0.1' || hostname === 'localhost';
};