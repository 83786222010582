import React, { FC } from 'react';
import { IconProps } from './types';

export const Error: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 16.21 16.21"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
            >
                <g transform="translate(3.749 3.749)">
                    <path
                        id="Line_101"
                        d="M8.712 0L0 8.712"
                        className="cls-2"
                    />
                    <path
                        id="Line_102"
                        d="M0 0L8.712 8.712"
                        className="cls-2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Error;
