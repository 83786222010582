import { useEffect, useState } from 'react';
import { variables } from '../../constants/variables';

export const useIsMobile = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return width <= variables.breaks.mobile;
};
