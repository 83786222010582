import React, { FC, memo } from 'react';
import styled from 'styled-components';
import useTranslate from '../../../../../../config/language';
import { KEYWORDS } from '../../../../../../config/language/keywords';

const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -135px;
    @media (max-height: ${700}px) {
        top: -100px;
    }
    @media (max-width: ${1000}px) {
        top: -80px;
    }
`;

const Text = styled.div`
    letter-spacing: 0;
    color: ${props => props.theme.colors.white};
    text-align: center;
    font-family: ${props => props.theme.fonts.mediatorNarrowCaps};
    font-size: 19px;
    width: fit-content;
    width: -moz-fit-content;
    white-space: nowrap;
`;

const Value = styled.span`
    display: inline-flex;
    width: 50px;
    align-items: center;
    justify-content: center;
    font-size: 27px;
    font-family: 'Roboto', monospace;
    font-weight: 500;
`;

const Countdown: FC<{ value: string }> = ({ value }) => {
    const translate = useTranslate();
    return (
        <Container>
            <Text>
                {translate(KEYWORDS.PlaceYourBet)} <Value>{value}</Value>
            </Text>
        </Container>
    );
};

export default memo(Countdown);
