export enum GAME_STATE {
    INITIAL = 'INITIAL',
    RUNNING = 'RUNNING',
    COUNTDOWN = 'COUNTDOWN',
    CRUSHED = 'CRUSHED',
    LAUNCHING = 'LAUNCHING',
}

export const GAME_STATE_BY_INDEX = {
    1: GAME_STATE.COUNTDOWN,
    2: GAME_STATE.CRUSHED,
    3: GAME_STATE.RUNNING,
    4: GAME_STATE.LAUNCHING,
};

export interface GameStatus {
    id?: number;
    state?: GAME_STATE;
    multiplier?: number | null;
    hashKey?: string;
    duration: number;
    durationLeft?: number;
}
