import React, { FC } from 'react';
import { IconProps } from './types';

export const Success: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 14.183 12.472"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g transform="translate(0.49)">
                <rect
                    width="12.472"
                    height="12.472"
                    transform="translate(0)"
                    fill="currentColor"
                    opacity="0"
                />
                <path
                    d="M12.526,6,6.664,11.862,4,9.2"
                    transform="translate(-1.661 -2.897)"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                />
            </g>
        </svg>
    );
};

export default Success;
