import React, { memo } from 'react';
import styled from 'styled-components';
import useTranslate from '../../config/language';
import { KEYWORDS } from '../../config/language/keywords';

const Title = styled.div`
    color: ${props => props.theme.colors.whiteGrayish};
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
`;

const FreeBetsHandler = () => {
    const translate = useTranslate();
    return (
        <>
            <Title>{translate(KEYWORDS.FreeBet)}</Title>
        </>
    );
};

export default memo(FreeBetsHandler);
