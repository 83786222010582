import React, { FC, useState } from 'react';
import styled from 'styled-components';
import BetsModal from '../BetsModal';
import { GameHistory } from '../../constants/interfaces/GameHistory';
import { getMultiplierRGBColor } from '../../features/betColors';
import { roundNumber } from '../../helpers/functions/round-number';

const StyledResultBox = styled.div<{ color?: string }>`
    margin: 4px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    line-height: 20px;
    padding: 5px 8px;
    display: inline-block;
    background-color: ${(props) =>
        props.color ? props.color : props.theme.colors.leafyGreen};
    font-weight: bold;
    user-select: none;
`;

const CustomResultBox: FC<{ game: GameHistory }> = ({ game }) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            {open && (
                <BetsModal
                    closeModal={() => setOpen(false)}
                    bustedAt={game.BustedAt}
                    gameId={game.ID}
                    hashCode={game.CrashHash}
                    hashKey={game.CrashSource}
                />
            )}
            <StyledResultBox
                color={getMultiplierRGBColor(game.BustedAt)}
                onClick={() => setOpen(true)}
            >
                {roundNumber(game.BustedAt)}x
            </StyledResultBox>
        </div>
    );
};

export default CustomResultBox;
