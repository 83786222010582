import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ diameter: number }>`
    position: absolute;
    background: ${props => props.theme.colors.grassGreen};
    color: ${props => props.theme.colors.white};
    top: ${({ diameter }) => -diameter / 2 + 4}px;
    right: ${({ diameter }) => -diameter / 2 + 4}px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 40px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Counter: FC<{ count: number; diameter?: number }> = ({
    count,
    diameter,
}) => {
    return <Container diameter={diameter || 26}>{count}</Container>;
};

export default Counter;
