import { KEYWORDS } from '../../config/language/keywords';

export enum LIST_INDEXES {
    BETS = 0,
    MY_BETS = 1,
    TOP = 2,
}

export const SidebarNavigationItems = [
    { text: KEYWORDS.Bets, index: LIST_INDEXES.BETS },
    { text: KEYWORDS.My, index: LIST_INDEXES.MY_BETS },
    { text: KEYWORDS.Rating, index: LIST_INDEXES.TOP },
];
