import React, { createContext, FC, useEffect, useState } from 'react';
import { useGameStatusState } from '../../config/store/state/app.state';
import { GAME_STATE, GameStatus } from '../../constants/interfaces/Game';
import styled from 'styled-components';
import GameMultiplier from './components/GameMultiplier';
import useWindowDimensions from '../../helpers/hooks/windowDimentions';
import { calculateGameBoardDimensions } from './tools';
import Ruler from '../../components/Ruller/Ruler';
import GameRunning from './state/Running';
import GameCoolDown from './state/Cooldown';
import GameExploding from './state/Exploding';
import { EXPLOSION } from '../spaceship/tools';

const StyledGameBoardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
`;

const GameBorder = styled.div<{ width: number; height: number }>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding-top: 6px;
`;

const PreRenderedExplosion = styled.div`
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    & img {
        position: absolute;
    }
`;

export const GameContext = createContext({ gameWidth: 300, gameHeight: 400 });

const GameBoard: FC<{ game: GameStatus }> = ({ game }) => {
    const { width, height } = useWindowDimensions();
    const gameDimensions = calculateGameBoardDimensions(width, height);
    const [images, setImages] = useState<any>([]);
    useEffect(() => {
        setImages(
            EXPLOSION.map(src => {
                const img = new Image();
                img.src = src;
                return <img src={src} key={src} alt="explosion" />;
            })
        );
    }, []);

    const renderGame = () => {
        switch (game.state) {
            case GAME_STATE.LAUNCHING:
            case GAME_STATE.RUNNING: {
                return <GameRunning />;
            }
            case GAME_STATE.COUNTDOWN: {
                return <GameCoolDown />;
            }
            case GAME_STATE.CRUSHED: {
                return <GameExploding explosion={images} />;
            }
            default:
                return <div />;
        }
    };

    return (
        <StyledGameBoardContainer>
            <GameBorder
                width={gameDimensions.width}
                height={gameDimensions.height}
            >
                <GameContext.Provider
                    value={{
                        gameWidth: gameDimensions.width,
                        gameHeight: gameDimensions.height,
                    }}
                >
                    {renderGame()}
                </GameContext.Provider>
                {!!game.multiplier && (
                    <GameMultiplier
                        multiplier={game.multiplier}
                        gameState={game.state}
                    />
                )}
            </GameBorder>
            {game.state === GAME_STATE.RUNNING && (
                <Ruler multiplier={game.multiplier} />
            )}
            <PreRenderedExplosion>
                {images.map((img: any) => {
                    return img;
                })}
            </PreRenderedExplosion>
        </StyledGameBoardContainer>
    );
};

const GameBoardContainer = () => {
    const gameState = useGameStatusState();
    if (!gameState) return <div />;
    return <GameBoard game={gameState} />;
};
export default GameBoardContainer;
