import NavigationItem from '../../../components/Navigation/NavigationItem';
import Navigation from '../../../components/Navigation/Navigation';
import React, { FC } from 'react';
import styled from 'styled-components';

const StyledNavigation = styled(Navigation)`
    background: #ffffff14 0% 0% no-repeat padding-box;
    margin-bottom: 8px;
`;

const StyledNavigationItem = styled(NavigationItem)<{ active: boolean }>`
    ${props =>
        props.active &&
        `
        &::before {
        display: block;
        position: absolute;
        content: '';
        background: ${props.theme.colors.vermillion};
        height: 2px;
        bottom: 0px;
        width: 100%;
        left: 0px;
        right: 0px;
    }
    `};
    font-size: 15px;
`;

const TableNavigation: FC<{
    items: { text: string; index: number }[];
    activeIndex: number;
    setActive(index: number): void;
}> = ({ items, activeIndex, setActive }) => {
    return (
        <StyledNavigation>
            {items.map(item => {
                return (
                    <StyledNavigationItem
                        key={item.index}
                        text={item.text}
                        index={item.index}
                        active={item.index === activeIndex}
                        onClick={setActive}
                    />
                );
            })}
        </StyledNavigation>
    );
};

export default TableNavigation;
