import React, { FC } from 'react';
import { ListUser } from '../../../constants/interfaces/List';
import VirtualizedTable from '../../../components/Table/VirtualizedTable';
import { Column } from 'react-virtualized';
import {
    RenderBetRow,
    RenderOddsRow,
    RenderProfitRow,
    RenderUsernameRow,
} from './components/RenderTableRows';

const CurrentList: FC<{ items: ListUser[] }> = ({ items }) => {
    return (
        <VirtualizedTable
            rowHeight={50}
            headerHeight={0}
            rowCount={items.length}
            rowGetter={({ index }) => {
                return items[index];
            }}
        >
            <Column
                dataKey="username"
                width={30}
                flexGrow={1}
                cellRenderer={(data) => {
                    return <RenderUsernameRow player={data.rowData} />;
                }}
            />
            <Column
                dataKey="bet"
                width={20}
                flexGrow={1}
                cellRenderer={(data) => {
                    return <RenderBetRow player={data.rowData} />;
                }}
            />
            <Column
                dataKey="odds"
                width={20}
                flexGrow={1}
                cellRenderer={(data) => {
                    return <RenderOddsRow player={data.rowData} />;
                }}
            />
            <Column
                dataKey="profit"
                width={30}
                flexGrow={1}
                cellRenderer={(data) => {
                    return <RenderProfitRow player={data.rowData} />;
                }}
            />
        </VirtualizedTable>
    );
};

export default CurrentList;
