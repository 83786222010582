import React, { FC } from 'react';
import { IconProps } from './types';

export const Fire: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 52.797 193.482"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <linearGradient
                    id="linear-gradient-fire"
                    x1="0.5"
                    y1="0.127"
                    x2="0.5"
                    y2="0.819"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#ffc400" />
                    <stop
                        offset="0.524"
                        stopColor="#ff3100"
                        stopOpacity="0.475"
                    />
                    <stop offset="1" stopColor="red" stopOpacity="0" />
                </linearGradient>
            </defs>
            <g
                id="Group_4597"
                data-name="Group 4597"
                transform="translate(-89.801 -260.642)"
            >
                <rect
                    id="Rectangle_3099"
                    data-name="Rectangle 3099"
                    width="16.005"
                    height="143.156"
                    rx="8.002"
                    transform="translate(89.801 260.642)"
                    fill="url(#linear-gradient-fire)"
                />
                <rect
                    id="Rectangle_3100"
                    data-name="Rectangle 3100"
                    width="16.005"
                    height="143.156"
                    rx="8.002"
                    transform="translate(122.7 260.642)"
                    fill="url(#linear-gradient-fire)"
                />
                <rect
                    id="Rectangle_3101"
                    data-name="Rectangle 3101"
                    width="16.894"
                    height="174.276"
                    rx="8.447"
                    transform="translate(105.806 265.977)"
                    fill="url(#linear-gradient-fire)"
                />
            </g>
        </svg>
    );
};

export default Fire;
