import React, { FC } from 'react';
import { IconProps } from './types';

export const Menu: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 22 14"
            className={className}
            width="100%"
            height="100%"
        >
            <g id="menu" transform="translate(-1.509 -5.339)" opacity="0.8">
                <line
                    id="Line_104"
                    data-name="Line 104"
                    x2="12"
                    transform="translate(2.509 12.339)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_105"
                    data-name="Line 105"
                    x2="20"
                    transform="translate(2.509 6.339)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_106"
                    data-name="Line 106"
                    x2="16"
                    transform="translate(2.509 18.339)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default Menu;
