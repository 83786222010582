import React, { FC, memo, useContext, useEffect } from 'react';
import { Flying } from '../../spaceship/spaceship';
import styled from 'styled-components';
import { GameContext } from '../index';
import { useGameConfigs } from '../../../config/store/state/app.state';
import { SoundPlayer } from '../../soundPlayer/soundPlayer';

const RunningContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: ${450}px) {
        margin-right: 100px;
    }
`;

const GameRunning: FC = () => {
    const { gameWidth } = useContext(GameContext);
    const voiceEffects = useGameConfigs().voiceEffects;

    useEffect(() => {
        if (!voiceEffects) {
            return;
        }
        const audio = new SoundPlayer();
        audio.playFlyingSound();
        return () => {
            audio.stopFlyingSound();
        };
    }, [voiceEffects]);

    return (
        <RunningContainer>
            <Flying shipWidth={gameWidth / 1.62} />
        </RunningContainer>
    );
};

export default memo(GameRunning);
