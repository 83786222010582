import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Explosion } from '../../spaceship/states/exploding';
import { SoundPlayer } from '../../soundPlayer/soundPlayer';
import { useGameConfigs } from '../../../config/store/state/app.state';

const CoolDownContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;

    @media (max-width: ${450}px) {
        margin-right: 100px;
    }
`;

const GameExploding: FC<{ explosion: string[] }> = ({ explosion }) => {
    const voiceEffects = useGameConfigs().voiceEffects;

    useEffect(() => {
        const sound = new SoundPlayer();
        if (!voiceEffects) {
            return;
        }
        sound.playExplosionSound();
        return () => {
            sound.stopExplosionSound();
        };
    }, [voiceEffects]);

    return (
        <CoolDownContainer>
            <Explosion explosion={explosion} />
        </CoolDownContainer>
    );
};

export default GameExploding;
