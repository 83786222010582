import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import NumberInput from '../../../../components/NumberInput/NumberInput';
import {
    RegisterState,
    useErrorsState,
    useGameStatusState,
    usePanelState,
} from '../../../../config/store/state/app.state';
import {
    useCashOutService,
    usePendingService,
    useRegisterService,
    useUnregisterLocalService,
    useUnregisterService,
} from '../../../../config/store/services';
import { GAME_STATE } from '../../../../constants/interfaces/Game';
import RegisterPanel from './panels/RegisterPanel';
import CashOutPanel from './panels/CashOutPanel';
import UnregisterPanel from './panels/UnregisterPanel';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';
import ConditionDropdown from './ConditionDropdown';
import { useShowMessage } from '../../../errorMessages/store/services';
import { useBalance } from '../../../profile/configs/store/state';
import {
    calculateMultiplierGrow,
    getFinalMultiplier,
    MULTIPLIER_DELAY,
} from '../../../game/components/GameMultiplier';
import { useMixpanelServices } from '../../../../services/mixpanel/MixpanelServices';
import { EMixpanelEvent } from '../../../../services/mixpanel/types';

const LIST_ITEMS = [
    { text: KEYWORDS.IncreaseBy, index: 1 },
    { text: KEYWORDS.ReturnToBaseBet, index: 0 },
];

const StyledAutoBoard = styled.div`
    min-height: 150px;
    padding: 0 32px;
    display: flex;
    max-width: 90%;
    flex: 1;
    box-sizing: border-box;
    margin-bottom: 8px;
    &:nth-child(2) {
        border-left: 1px solid #ffffff30;
    }
    @media (max-width: ${1500}px) {
        max-width: 95%;
        padding: 0 16px;
    }
    @media (max-width: ${700}px) {
        max-width: 100%;
        padding: 0 4px;
    }
    @media (max-width: ${500}px) {
        flex-direction: column;
    }
`;

const FirstRow = styled.div`
    display: flex;
    margin-bottom: 12px;
    flex: 1;
    @media (max-height: ${550}px), (max-width: ${600}px) {
        margin-bottom: 6px;
    }
`;

const SecondRow = styled.div`
    display: flex;
    flex: 1;
`;

const InputContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 16px;
`;

const InputWrapper = styled.div<{ disabled?: boolean }>`
    position: relative;
    height: 25px;
    ${props => props.disabled && 'opacity: 0.5'};
    @media (max-height: ${550}px), (max-width: ${600}px) {
        height: 20px;
    }
`;

const InputLabel = styled.div`
    font-size: 16px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-family: ${props => props.theme.fonts.mediatorNarrowCaps};
    font-weight: bold;
    letter-spacing: 0;
    color: ${props => props.theme.colors.whiteGrayish};
    text-align: left;
    margin-bottom: 7px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-height: ${550}px) {
        font-size: 11px;
        margin-bottom: 4px;
    }
    @media (max-width: ${600}px) {
        font-size: 11px;
        margin-bottom: 4px;
    }
`;

const NumberInputWrapper = styled.div`
    flex: 1;
`;

const ActionsContainer = styled.div`
    flex: 4;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    flex: 1;
    display: flex;
    @media (max-width: ${500}px) {
        margin-top: 8px;
        min-height: 70px;
    }
`;

// Auto Board Configuration
const BOARD_INDEX = 2;

const BASE_AMOUNT_MIN_VALUE = 0.1;
const BASE_AMOUNT_MAX_VALUE = 200;
const AUTO_CASHOUT_MIN_VALUE = 1.1;
const STOP_BET_MIN_VALUE = 0.1;
const STOP_BET_MAX_VALUE = 200;
const ON_WIN_LOSS_MIN_VALUE = 0.1;
const ON_WIN_LOSS_MAX_VALUE = 200;

const AutoBoardContainer = () => {
    const translate = useTranslate();
    const state = usePanelState().filter(
        board => board.PanelIndex === BOARD_INDEX
    )[0];
    const gameStatus = useGameStatusState();
    const multiplier = gameStatus ? gameStatus.multiplier || 1 : 1;
    const registerService = useRegisterService();
    const pendingService = usePendingService();
    const unregisterService = useUnregisterService();
    const unregisterLocalService = useUnregisterLocalService();
    const cashOutService = useCashOutService();
    const registerState = state && state.registerState;

    const showMessage = useShowMessage();
    const balance = useBalance();

    const disableActions = registerState !== RegisterState.UNREGISTERED;
    const [autoBetEnabled, setAutoBetEnabled] = useState(false);

    const [amount, setAmount] = useState(1);

    const [baseAmount, setBaseAmount] = useState<number>(1);

    const handleCheckBalance = useCallback(
        (value: number) => {
            if (value > balance.WalletBalance) {
                return balance.WalletBalance;
            } else {
                return value;
            }
        },
        [balance.WalletBalance]
    );

    const handleBaseAmountValueChange = useCallback(
        (value: number | null) => {
            if (!value || disableActions) return;
            value = Math.round(value * 100) / 100;
            value = handleCheckBalance(value);

            if (value < BASE_AMOUNT_MIN_VALUE) {
                setBaseAmount(BASE_AMOUNT_MIN_VALUE);
                setAmount(BASE_AMOUNT_MIN_VALUE);
                return;
            }
            if (value > BASE_AMOUNT_MAX_VALUE) {
                setBaseAmount(BASE_AMOUNT_MAX_VALUE);
                setAmount(BASE_AMOUNT_MAX_VALUE);
                return;
            }
            setBaseAmount(value);
            setAmount(value);
        },
        [handleCheckBalance, setAmount, setBaseAmount, disableActions]
    );

    const [autoCashOutValue, setAutoCashOutValue] = useState<number>(2);
    const handleAutoCashOutValueChange = useCallback(
        (value: number | null) => {
            if (!value || disableActions) return;
            if (value < AUTO_CASHOUT_MIN_VALUE) {
                setAutoCashOutValue(AUTO_CASHOUT_MIN_VALUE);
                return;
            }
            if (value > Number.MAX_SAFE_INTEGER) {
                return;
            }
            setAutoCashOutValue(value);
        },
        [setAutoCashOutValue, disableActions]
    );

    const [stopBetValue, setStopBetValue] = useState<number>(200);

    const handleStopBetValueChange = useCallback(
        (value: number | null) => {
            if (!value || disableActions) return;
            if (value < STOP_BET_MIN_VALUE) {
                setStopBetValue(STOP_BET_MIN_VALUE);
                return;
            }
            if (value > STOP_BET_MAX_VALUE) {
                setStopBetValue(STOP_BET_MAX_VALUE);
                return;
            }
            setStopBetValue(value);
        },
        [setStopBetValue, disableActions]
    );

    const [onWinCase, setOnWinCase] = useState<0 | 1>(0);
    const [onWinValue, setOnWinValue] = useState<number>(2);
    const handleOnWinValueChange = useCallback(
        (value: number | null) => {
            if (!value || disableActions) return;
            if (value < ON_WIN_LOSS_MIN_VALUE) {
                setOnWinValue(ON_WIN_LOSS_MIN_VALUE);
                return;
            }
            if (value > ON_WIN_LOSS_MAX_VALUE) {
                setOnWinValue(ON_WIN_LOSS_MAX_VALUE);
                return;
            }
            setOnWinValue(value);
        },
        [disableActions, setOnWinValue]
    );

    const [onLossCase, setOnLossCase] = useState<0 | 1>(0);
    const [onLossValue, setOnLossValue] = useState<number>(2);
    const handleOnLossValueChange = useCallback(
        (value: number | null) => {
            if (!value || disableActions) return;
            if (value < ON_WIN_LOSS_MIN_VALUE) {
                setOnLossValue(ON_WIN_LOSS_MIN_VALUE);
                return;
            }
            if (value > ON_WIN_LOSS_MAX_VALUE) {
                setOnLossValue(ON_WIN_LOSS_MAX_VALUE);
                return;
            }
            setOnLossValue(value);
        },
        [disableActions, setOnLossValue]
    );

    const handleOnWinCase = useCallback(() => {
        if (onWinCase === 0) {
            setAmount(baseAmount);
        } else {
            setAmount(amount * onWinValue);
        }
    }, [onWinCase, setAmount, baseAmount, amount, onWinValue]);

    const handleOnLossCase = useCallback(() => {
        if (onLossCase === 0) {
            setAmount(baseAmount);
        } else {
            setAmount(amount * onLossValue);
        }
    }, [onLossCase, setAmount, baseAmount, amount, onLossValue]);

    const handleRegister = useCallback(() => {
        registerService({
            BuyIn: amount,
            AutoCashoutOn: autoCashOutValue,
            IsAutoBet: true,
            PanelIndex: BOARD_INDEX,
            BalanceType: 1,
        });
    }, [autoCashOutValue, amount, registerService]);

    const handlePending = useCallback(() => {
        pendingService({
            BuyIn: amount,
            AutoCashoutOn: autoCashOutValue,
            IsAutoBet: true,
            PanelIndex: BOARD_INDEX,
            BalanceType: 1,
        });
    }, [amount, autoCashOutValue, pendingService]);

    const handleInitRegister = useCallback(() => {
        if (amount > balance.WalletBalance) {
            showMessage(KEYWORDS.NotEnoughMoney, 'error');
            setAutoBetEnabled(false);
            return;
        }
        if (gameStatus && gameStatus.state !== GAME_STATE.COUNTDOWN) {
            handlePending();
        } else {
            handleRegister();
        }
    }, [
        gameStatus,
        handlePending,
        handleRegister,
        showMessage,
        amount,
        balance.WalletBalance,
    ]);

    const handleClickRegister = () => {
        setAutoBetEnabled(true);
        handleInitRegister();
    };

    const handleClickUnregister = useCallback(() => {
        setAutoBetEnabled(false);
        setAmount(baseAmount);
        if (registerState === RegisterState.PENDING) {
            unregisterLocalService({
                PanelIndex: BOARD_INDEX,
            });
        } else {
            unregisterService({
                PanelIndex: BOARD_INDEX,
            });
        }
    }, [baseAmount, registerState, unregisterLocalService, unregisterService]);

    const { track } = useMixpanelServices();

    const handleClickCashOut = () => {
        track(EMixpanelEvent.cashout, {
            Board: 'AutoBetting',
            Multiplier: multiplier,
            BetAmount: amount,
            Won: amount * multiplier,
        });
        cashOutService({
            PanelIndex: BOARD_INDEX,
            Won: amount * multiplier,
            Multiplier: multiplier,
        });
    };

    useEffect(() => {
        if (state.registerState && gameStatus.state) {
            if (
                state.registerState === RegisterState.PENDING &&
                gameStatus.state === GAME_STATE.COUNTDOWN
            ) {
                if (amount > stopBetValue) {
                    setAutoBetEnabled(false);
                    handleClickUnregister();
                } else {
                    handleRegister();
                }
            }
            if (
                state.registerState === RegisterState.UNREGISTERED &&
                autoBetEnabled
            ) {
                if (amount > stopBetValue) {
                    setAutoBetEnabled(false);
                } else {
                    if (state.wonLastGame) {
                        handleOnWinCase();
                    } else {
                        handleOnLossCase();
                    }
                    handleInitRegister();
                }
            }
        }
    }, [
        state.registerState,
        state.wonLastGame,
        gameStatus.state,
        handleRegister,
        autoBetEnabled,
        setAutoBetEnabled,
        handleInitRegister,
        handleOnWinCase,
        handleOnLossCase,
        handleClickUnregister,
        amount,
        stopBetValue,
    ]);

    // BEGIN TEMP LOCAL CALCULATIONS
    const gameError = useErrorsState().isError;
    const gameState = useGameStatusState().state;
    const gameCrushed = gameState === GAME_STATE.CRUSHED;

    const [localMultiplier, setLocalMultiplier] = useState(1);

    useEffect(() => {
        setLocalMultiplier(multiplier || 1);
    }, [multiplier]);

    useEffect(() => {
        if (gameState === GAME_STATE.RUNNING) {
            const interval = setInterval(() => {
                setLocalMultiplier(prevValue =>
                    calculateMultiplierGrow(prevValue)
                );
            }, MULTIPLIER_DELAY);

            return () => {
                clearInterval(interval);
            };
        }
    }, [multiplier, gameState, setLocalMultiplier]);

    let finalMultiplayer = getFinalMultiplier(
        gameCrushed,
        gameError,
        localMultiplier,
        multiplier
    );
    // END TEMP LOCAL CALCULATIONS

    const handleRegisterStateView = () => {
        switch (registerState) {
            case RegisterState.UNREGISTERED: {
                return <RegisterPanel handleRegister={handleClickRegister} />;
            }
            case RegisterState.REGISTER_INITIATED:
            case RegisterState.CASH_OUT_INITIATED:
            case RegisterState.REGISTERED: {
                if (gameStatus.state === GAME_STATE.COUNTDOWN) {
                    return (
                        <UnregisterPanel
                            amount={amount}
                            disabled={
                                registerState ===
                                RegisterState.REGISTER_INITIATED
                            }
                            handleUnregister={handleClickUnregister}
                        />
                    );
                } else {
                    return (
                        <CashOutPanel
                            amount={amount * finalMultiplayer}
                            disabled={
                                registerState ===
                                RegisterState.CASH_OUT_INITIATED
                            }
                            handleCashOut={handleClickCashOut}
                            handleUnregister={handleClickUnregister}
                        />
                    );
                }
            }
            case RegisterState.UNREGISTER_INITIATED:
            case RegisterState.PENDING: {
                return (
                    <UnregisterPanel
                        amount={amount}
                        disabled={
                            registerState === RegisterState.UNREGISTER_INITIATED
                        }
                        handleUnregister={handleClickUnregister}
                    />
                );
            }
            default: {
                return <RegisterPanel handleRegister={handleClickRegister} />;
            }
        }
    };

    return (
        <StyledAutoBoard>
            <ActionsContainer>
                <FirstRow>
                    <InputContainer>
                        <InputWrapper disabled={disableActions}>
                            <InputLabel>
                                {translate(KEYWORDS.BaseBet)}
                            </InputLabel>
                        </InputWrapper>
                        <NumberInputWrapper>
                            <NumberInput
                                value={baseAmount}
                                onChange={handleBaseAmountValueChange}
                                minValue={BASE_AMOUNT_MAX_VALUE}
                                maxValue={BASE_AMOUNT_MAX_VALUE}
                                maxLength={
                                    baseAmount < BASE_AMOUNT_MAX_VALUE
                                        ? BASE_AMOUNT_MAX_VALUE.toString()
                                              .length + 3
                                        : BASE_AMOUNT_MAX_VALUE.toString()
                                              .length
                                }
                                disabled={disableActions}
                            />
                        </NumberInputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputWrapper disabled={disableActions}>
                            <InputLabel>
                                {translate(KEYWORDS.AutoCashout)}
                            </InputLabel>
                        </InputWrapper>
                        <NumberInputWrapper>
                            <NumberInput
                                suffix={' x'}
                                value={autoCashOutValue}
                                onChange={handleAutoCashOutValueChange}
                                minValue={AUTO_CASHOUT_MIN_VALUE}
                                disabled={disableActions}
                            />
                        </NumberInputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputWrapper disabled={disableActions}>
                            <InputLabel>
                                {translate(KEYWORDS.StopIf)}
                            </InputLabel>
                        </InputWrapper>
                        <NumberInputWrapper>
                            <NumberInput
                                value={stopBetValue}
                                onChange={handleStopBetValueChange}
                                minValue={STOP_BET_MIN_VALUE}
                                maxValue={STOP_BET_MAX_VALUE}
                                maxLength={
                                    stopBetValue < STOP_BET_MAX_VALUE
                                        ? STOP_BET_MAX_VALUE.toString().length +
                                          3
                                        : STOP_BET_MAX_VALUE.toString().length
                                }
                                disabled={disableActions}
                            />
                        </NumberInputWrapper>
                    </InputContainer>
                </FirstRow>
                <SecondRow>
                    <InputContainer>
                        <InputWrapper disabled={disableActions}>
                            <InputLabel>{translate(KEYWORDS.OnWin)}</InputLabel>
                        </InputWrapper>
                        <ConditionDropdown
                            value={onWinValue}
                            onValueChange={handleOnWinValueChange}
                            selected={onWinCase}
                            selectAction={setOnWinCase}
                            items={LIST_ITEMS}
                            disabled={disableActions}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputWrapper disabled={disableActions}>
                            <InputLabel>
                                {translate(KEYWORDS.OnLoss)}
                            </InputLabel>
                        </InputWrapper>
                        <ConditionDropdown
                            value={onLossValue}
                            onValueChange={handleOnLossValueChange}
                            selected={onLossCase}
                            selectAction={setOnLossCase}
                            items={LIST_ITEMS}
                            disabled={disableActions}
                        />
                    </InputContainer>
                </SecondRow>
            </ActionsContainer>
            <ButtonContainer>{handleRegisterStateView()}</ButtonContainer>
        </StyledAutoBoard>
    );
};

export default memo(AutoBoardContainer);
