import React, { FC } from 'react';
import { IconProps } from './types';

export const Info: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 16 16"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g transform="translate(-0.22 -0.22)">
                <circle
                    cx="7"
                    cy="7"
                    r="7"
                    transform="translate(1.22 1.22)"
                    fill="none"
                    stroke="currentColor"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M12,6V9.77l2.513,1.257"
                    transform="translate(-3.78 -1.549)"
                    fill="none"
                    fillRule="evenodd"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default Info;
