import React, { createContext, FC, useEffect, useState } from 'react';
import { dictionaryList, LANGUAGES } from './tools';
import { useQuery } from '../../helpers/hooks/useQuery';
import { LANGUAGE_KEY } from '../../constants/constants';

export const LanguageContext = createContext({
    lang: LANGUAGES.ka,
    dictionary: dictionaryList.ka,
});

const LanguageProvider: FC = ({ children }) => {
    const [userLanguage, setUserLanguage] = useState<LANGUAGES>(LANGUAGES.ka);
    const currentLanguage: LANGUAGES = useQuery(LANGUAGE_KEY) as LANGUAGES;

    useEffect(() => {
        if (
            currentLanguage &&
            Object.values(LANGUAGES).includes(currentLanguage)
        ) {
            setUserLanguage(currentLanguage);
        }
    }, [currentLanguage, setUserLanguage]);

    const provider = {
        lang: userLanguage,
        dictionary: dictionaryList[userLanguage],
    };

    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;
