import styled from 'styled-components';
import React, { FC, useContext, useState } from 'react';
import { GameContext } from '../../../game';

const ExplosionContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ExplosionImageWrapper = styled.div<{ width: number }>`
    width: ${(props) => props.width}px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledExplosionImage = styled.div<{ width: number }>`
    width: ${(props) => props.width}px;
    position: absolute;
    top: -11%;
    > img {
        top: 0;
        width: 100%;
    }
`;

export const Explosion: FC<{ explosion: string[] }> = ({ explosion }) => {
    const [imageCount, setImageCount] = useState(0);
    const { gameWidth } = useContext(GameContext);

    React.useEffect(() => {
        const interval = 1800 / explosion.length;

        const timeout = setInterval(() => {
            setImageCount((prevCount) => prevCount + 1);
        }, interval);

        if (imageCount === explosion.length) {
            clearInterval(timeout);
        }

        return () => {
            clearInterval(timeout);
        };
    }, [explosion.length, imageCount]);

    return (
        <ExplosionContainer>
            <ExplosionImageWrapper width={gameWidth / 1.62}>
                <StyledExplosionImage
                    key={imageCount}
                    width={gameWidth / 1.62 / 0.2983}
                >
                    {explosion[imageCount]}
                </StyledExplosionImage>
            </ExplosionImageWrapper>
        </ExplosionContainer>
    );
};
