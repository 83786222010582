import { useSelector } from 'react-redux';
import { ChatMessage } from '../../../constants/interfaces/ChatMessage';
import { SelectMessengerState } from '../../../config/store/selectors';

export interface MessengerState {
    open: boolean;
    messages: ChatMessage[];
}

export function useMessengerState(): MessengerState {
    return useSelector(SelectMessengerState);
}
