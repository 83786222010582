import React, { FC } from 'react';
import { IconProps } from './types';

export const Unlock: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 20 22.008"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <g
                    fill="none"
                    stroke="#75b75d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2px"
                    transform="translate(-2 -.992)"
                >
                    <rect
                        id="Rectangle_3027"
                        width="18"
                        height="11"
                        className="cls-1"
                        rx="2"
                        transform="translate(3 11)"
                    />
                    <path
                        id="Path_3446"
                        d="M7 11V7a5 5 0 0 1 9.9-1"
                        className="cls-1"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Unlock;
