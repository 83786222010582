import React, { FC } from 'react';
import { MyList } from '../../../../constants/interfaces/MyList';
import styled from 'styled-components';
import RenderMyBetsTableRows from './RenderMyBetsTableRows';
import Table from '../../../../components/Table/Table';
import { TableHead } from '../../../../components/Table/TableHead';
import { TableHeaderRow } from '../../../../components/Table/TableHeaderRow';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell';
import { TableBody } from '../../../../components/Table/TableBody';
import { useFormatDate } from '../../../../helpers/functions/format-date';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';
import { mapMyBetsValues } from '../configs/helpers';

const Container = styled.div``;
const StyledDate = styled.div`
    color: ${props => props.theme.colors.whiteGrayish};
    font-size: 15px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    padding: 11px;
`;

const RenderMyBetsTable: FC<{ items: MyList[] }> = ({ items }): any => {
    const translate = useTranslate();
    const formatDate = useFormatDate();
    const myBets = mapMyBetsValues(items);

    return (
        myBets &&
        myBets.map((item, index) => {
            return (
                <Container key={index}>
                    <StyledDate>{formatDate(item.date)}</StyledDate>
                    <div>
                        <Table>
                            <TableHead>
                                <TableHeaderRow>
                                    <TableHeaderCell>
                                        {translate(KEYWORDS.Time)}
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        {translate(KEYWORDS.BuyIn)}
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        {translate(KEYWORDS.Crashed)}
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        {translate(KEYWORDS.WinAmount)}
                                    </TableHeaderCell>
                                </TableHeaderRow>
                            </TableHead>
                        </Table>
                        <Table>
                            <TableBody>
                                <RenderMyBetsTableRows items={item.games} />
                            </TableBody>
                        </Table>
                    </div>
                </Container>
            );
        })
    );
};

export default RenderMyBetsTable;
