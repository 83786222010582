import React, { FC } from 'react';
import styled from 'styled-components';
import { useGameStatusState } from '../../config/store/state/app.state';
import { GAME_STATE } from '../../constants/interfaces/Game';

const StyledBackground = styled.div<{ flying?: boolean; down?: boolean }>`
    background: transparent
        linear-gradient(
            180deg,
            #241e2b 0%,
            #2d0a56 18%,
            #360b5f 44%,
            #2a285f 71%,
            #1e2326 100%
        )
        0 0 no-repeat padding-box;
    position: absolute;
    width: 100%;
    height: 4800px;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: ${({ down }) => (down ? 'none' : 'all 50s')};
    transform: ${({ down, flying }) => {
        if (!down && !flying) {
            return 'none';
        } else if (down) {
            return 'translate(0, 0)';
        } else if (flying) {
            return 'translate(0, 3400px)';
        } else {
            return 'none';
        }
    }};
`;

const BackgroundContainer: FC<{ disabled?: boolean }> = ({ disabled }) => {
    const gameState = useGameStatusState().state;
    const running =
        gameState === GAME_STATE.RUNNING || gameState === GAME_STATE.LAUNCHING;
    const down = gameState === GAME_STATE.COUNTDOWN;
    if (disabled) {
        return <StyledBackground />;
    }
    return <StyledBackground flying={running} down={down} />;
};

export default BackgroundContainer;
