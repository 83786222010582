export const MULTIPLIER_COLORS = [
    [88, 188, 52],
    [149, 203, 2],
    [195, 203, 2],
    [217, 195, 25],
    [248, 125, 45],
    [203, 56, 2],
];
export const MULTIPLIER_RANGE = [
    [1, 1.24],
    [1.25, 1.49],
    [1.5, 1.75],
    [1.76, 1.99],
    [2, 9.99],
    [10, 1e6],
];
