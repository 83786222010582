import React, { FC } from 'react';
import styled from 'styled-components';
import './styles.css';

import {
    useGameConfigs,
    useGameStatusState,
} from '../../config/store/state/app.state';
import { GAME_STATE } from '../../constants/interfaces/Game';

const StyledStarsContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    bottom: 0;
    right: 15px;
`;

const StarsContainer: FC<{ disabled?: boolean }> = ({ disabled }) => {
    const gameState = useGameStatusState().state;
    const animate = useGameConfigs().animate;

    return (
        <StyledStarsContainer>
            {!disabled && animate && gameState === GAME_STATE.RUNNING ? (
                <>
                    <div className="stars-one animate" />
                    <div className="stars-two animate" />
                </>
            ) : (
                <>
                    <div className="stars-one" />
                    <div className="stars-two" />
                </>
            )}
        </StyledStarsContainer>
    );
};

export default StarsContainer;
