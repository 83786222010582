import React, { memo, useEffect } from 'react';
import Title from './features/title';
import BackgroundContainer from './components/Background';
import StarsContainer from './components/Stars';
import { AudioPlayer } from './features/audioPlayer';
import Sidebar from './layout/Sidebar';
import SidebarList from './features/topBoards';
import Content from './layout/Content';
import OddsList from './features/oddsList';
import GameContainer from './features/game';
import ActionPanel from './features/actionPanel';
// import Chat from './features/chat';
import styled from 'styled-components';
import useBackgroundsMusic from './components/BackgroundMusic';
import ErrorModal from './components/Modals';
import { useErrorsState } from './config/store/state/app.state';
import HeaderSection from './layout/Header';
import { usePingService } from './config/store/services';
import { useMixpanelServices } from './services/mixpanel/MixpanelServices';
import { useProfile } from './features/profile/configs/store/state';
import { ITenant } from './constants/interfaces/Tenant';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SelectTenantState } from './config/store/selectors';
import { useSentryServices } from './services/sentry/SentryServices';
import { gameErrorAction } from './config/store/actions/app.actions';
import { ErrorListNames } from './components/Modals/ErrorList';

const AppContainer = styled.div`
    height: 100%;
    display: flex;
    color: white;
    overflow: auto;
`;

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
`;

const StyledBody = styled.div`
    display: flex;
    flex: 1;
    @media (max-width: ${1000}px) {
        flex-direction: column;
    }
`;

const Body = styled.div`
    display: flex;
    flex: 1;
`;

function useTenant(): ITenant {
    return useSelector(SelectTenantState, shallowEqual);
}

const Game = () => {
    const errors = useErrorsState();
    const { Player } = useProfile();
    const { identify } = useMixpanelServices();
    const { init } = useSentryServices();
    const tenant = useTenant();
    useBackgroundsMusic();
    const pingService = usePingService();
    const dispatch = useDispatch();

    useEffect(() => {
        const ping = setInterval(() => {
            pingService();
        }, 10000);

        return () => {
            clearInterval(ping);
        };
    }, [pingService]);

    useEffect(() => {
        identify(
            {
                name: Player.N,
                id: `${Player.I}`,
            },
            tenant
        );
    }, [Player.I, Player.N, identify, tenant]);

    useEffect(() => {
        init(tenant);
    }, [init, tenant]);

    useEffect(() => {
        if (Player.B) {
            dispatch(gameErrorAction(ErrorListNames.BlockedUser));
        }
    }, [Player.B, dispatch]);

    return (
        <AppContainer>
            {errors.isError && <ErrorModal type={errors.type} />}
            <Title />
            <BackgroundContainer />
            <StarsContainer />
            <AudioPlayer />
            <StyledContainer>
                <HeaderSection />
                <Body>
                    <StyledBody>
                        <Sidebar>
                            <SidebarList />
                        </Sidebar>
                        <Content>
                            <OddsList />
                            <GameContainer />
                            <ActionPanel />
                        </Content>
                    </StyledBody>
                    {/* <Chat /> */}
                </Body>
            </StyledContainer>
        </AppContainer>
    );
};

export default memo(Game);
