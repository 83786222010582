import { FormattedMyBetsParams, InitializeMyBetsParams } from './store/actions';
import { ListStatus } from '../../../../constants/interfaces/List';
import { formatTime } from '../../../../helpers/functions/format-date';
import { roundNumber } from '../../../../helpers/functions/round-number';

export const groupArray = (data: InitializeMyBetsParams[], field: string) => {
    const groups = data.reduce((groups: any, game: any) => {
        const date = game[field].split('T')[0];
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(game);
        return groups;
    }, {});

    return Object.keys(groups).map((date) => {
        return {
            date,
            games: groups[date],
        };
    });
};

export const mapMyBetsValues = (data: InitializeMyBetsParams[]) => {
    return groupArray(data, 'Timestamp').map((bet: FormattedMyBetsParams) => {
        return {
            ...bet,
            games: bet.games.map((game: InitializeMyBetsParams) => {
                return {
                    time: formatTime(game.Timestamp),
                    id: game.ID,
                    bet: game.BuyIn,
                    status: game.CashOut > 0 ? ListStatus.WON : ListStatus.LOST,
                    odds:
                        game.CashOut > 0
                            ? roundNumber(Number(game.CashOut) / game.BuyIn)
                            : undefined,
                    profit:
                        game.CashOut > 0
                            ? roundNumber(game.CashOut)
                            : undefined,
                };
            }),
        };
    });
};
