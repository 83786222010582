import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import useTranslate from '../../../config/language';
import { KEYWORDS } from '../../../config/language/keywords';
import { LanguageContext } from '../../../config/language/provider';
import { LANGUAGES } from '../../../config/language/tools';

const Container = styled.div`
    background: ${props => props.theme.colors.charcoalGreyTwo};
    font-family: ${props => props.theme.fonts.mediatorNarrowCaps};
    border-radius: 6px;
    box-shadow: none;
    width: 600px;
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%;
`;

const Title = styled.div`
    color: ${props => props.theme.colors.white};
    opacity: 0.8;
    margin: 0 0 24px 0;
    font-size: 24px;
    font-family: ${props => props.theme.fonts.mediatorNarrowCaps};
    font-weight: bold;
    text-align: center;
`;

const Paragraph = styled.p`
    line-height: 18px;
    color: rgb(192, 192, 192);
    font-size: 14px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    font-weight: bold;
    text-align: left;
    margin-bottom: 16px;
    padding-left: 24px;
    position: relative;
    &::before {
        content: '';
        display: block;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #e74e0f;
        position: absolute;
        left: 0;
        top: 4px;
    }
`;

const SubParagraph = styled.p`
    line-height: 14px;
    color: rgb(192, 192, 192);
    font-size: 14px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    font-weight: bold;
    text-align: left;
    margin-bottom: 16px;
    padding-left: 48px;
    position: relative;
    &::before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background-color: #e74e0f;
        position: absolute;
        left: 24px;
        top: 4px;
    }
`;

const GameRulesModal: FC<{
    gameId?: number;
    hashCode?: string;
    hashKey?: string;
    bustedAt?: number;
    closeModal(): void;
}> = ({ closeModal }) => {
    const translate = useTranslate();
    const languageContext = useContext(LanguageContext);

    return (
        <Modal closeModal={closeModal}>
            <Container>
                <Title>{translate(KEYWORDS.GameRules)}</Title>
                <Paragraph>{translate(KEYWORDS.Rules1)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules2)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules3)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules4)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules5)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules6)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules7)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules8)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules9)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules10)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules11)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules12)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules13)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules14)}</Paragraph>
                <SubParagraph>{translate(KEYWORDS.Rules14_1)}</SubParagraph>
                <SubParagraph>{translate(KEYWORDS.Rules14_2)}</SubParagraph>
                <Paragraph>{translate(KEYWORDS.Rules15)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules16)}</Paragraph>
                {languageContext.lang !== LANGUAGES.ka ? (
                    <Paragraph>{translate(KEYWORDS.Rules17)}</Paragraph>
                ) : (
                    <></>
                )}
                <Paragraph>{translate(KEYWORDS.Rules18)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules19)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules20)}</Paragraph>
            </Container>
        </Modal>
    );
};

export default GameRulesModal;
