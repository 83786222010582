import React, { memo } from 'react';
import styled from 'styled-components';
import List from './List';
import { useMyBets } from './configs/store/state';

const StyledCurrentList = styled.div``;

const StyledScrollableTable = styled.div`
    height: calc(100vh - 120px);
    overflow: auto;
    scrollbar-color: #ffffff21 #373754;
    scrollbar-width: thin;
`;

const MyBetsList = () => {
    const myBets = useMyBets();
    return (
        <StyledCurrentList>
            <StyledScrollableTable>
                {myBets && <List items={myBets} />}
            </StyledScrollableTable>
        </StyledCurrentList>
    );
};

export default memo(MyBetsList);
