import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Ship } from '../../components/Ship';
import Flame from './components/Flame';
import Countdown from './components/Countdown';
import ProgressCircle from './components/ProgressCircle';

const LoadingContainer = styled(motion.div)`
    position: relative;
    @media (max-height: ${800}px) {
        transform: scale(0.7);
    }
    @media (max-height: ${680}px) {
        transform: scale(0.6);
    }
    @media (max-width: ${1000}px) {
        transform: scale(0.8);
    }
`;

const LoadingShipContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    top: -50px;
`;

export const Preparing: FC<{ countDown: number }> = ({ countDown }) => {
    return (
        <LoadingContainer>
            <Countdown value={(countDown / 1000).toFixed(0)} />
            <div>
                <div className="progress-container">
                    <ProgressCircle value={countDown} />
                    <Flame />
                </div>
            </div>
            <LoadingShipContainer>
                <Ship width={100} animateShip={true} />
            </LoadingShipContainer>
        </LoadingContainer>
    );
};
