import {
    boardAddedAction,
    boardRemovedAction,
    cashOutAction,
    loadGameAction,
    pendingAction,
    pingAction,
    registerAction,
    registerFailAction,
    registerSuccessAction,
    saveSettingsAction,
    setLaunchingAction,
    toggleEnableFreeBetsAction,
    unregisterAction,
    unregisterActionFail,
    unregisterActionSuccess,
    unregisterLocalAction,
} from '../actions/app.actions';
import { Dispatch, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GameConfigs, RegisterState, useGameConfigs } from '../state/app.state';
import { useShowMessage } from '../../../features/errorMessages/store/services';
import { Message } from '../../../constants/interfaces/Message';
import { Player } from '../../../constants/interfaces/Player';
import { KEYWORDS } from '../../language/keywords';
import { useMixpanelServices } from '../../../services/mixpanel/MixpanelServices';
import { EMixpanelEvent } from '../../../services/mixpanel/types';
import { useMessengerState } from '../../../features/chat/store/state';

const getBettingDataForAnalytics = (
    payload: PlayerRegisterParams,
    chatIsOpen: boolean,
    configs: GameConfigs
) => {
    const BalanceTypeCashIndex = 1;
    const AutoBettingBoardIndex = 2;
    return {
        Chat: chatIsOpen ? 'Open' : 'Closed',
        BalanceType:
            payload.BalanceType === BalanceTypeCashIndex ? 'Cash' : 'Freebet',
        AutoCashOut: payload.AutoCashoutOn ? 'On' : 'Off',
        BuyIn: payload.BuyIn,
        AutoBet: payload.IsAutoBet ? 'On' : 'Off',
        BettingBoard:
        payload.PanelIndex === AutoBettingBoardIndex ? 'Auto' : 'Manual',
        Animate: configs.animate ? 'On' : 'Off',
        BackgroundMusic: configs.backgroundMusic ? 'On' : 'Off',
        VoiceEffects: configs.voiceEffects ? 'On' : 'Off',
    };
};

export const useSetLaunchingService = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(setLaunchingAction());
    }, [dispatch]);
};

export type PlayerRegisterParams = {
    Message: 'Register';
    BuyIn: number;
    AutoCashoutOn: null | number;
    IsAutoBet: boolean;
    PanelIndex: number;
    BalanceType: number;
    registerState: RegisterState;
};

export const usePendingService = (): any => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    return useCallback(
        (data: PlayerRegisterParams) => {
            dispatch(pendingAction(data));
            showMessage(KEYWORDS.WaitingForNextRound, 'success');
        },
        [dispatch, showMessage]
    );
};

export const useRegisterService = (): any => {
    const dispatch = useDispatch();
    const configs = useGameConfigs();
    const { track } = useMixpanelServices();
    const { open } = useMessengerState();
    return useCallback(
        (data: PlayerRegisterParams) => {
            dispatch(registerAction(data));
            track(EMixpanelEvent.bet, getBettingDataForAnalytics(data, open, configs));
        },
        [dispatch, track, open, configs]
    );
};

export const usePingService = (): any => {
    const dispatch = useDispatch();
    return useCallback(() => dispatch(pingAction()), [dispatch]);
};

export interface RegisterSuccessActionParams extends Message {
    PanelIndex: number;
    GameId: number;
    Player: Player;
}

export const registerSuccessService = (
    dispatch: Dispatch<any>,
    data: RegisterSuccessActionParams
): any => {
    dispatch(registerSuccessAction(data));
};

export const registerFailService = (
    dispatch: Dispatch<any>,
    data: RegisterSuccessActionParams
): any => {
    dispatch(registerFailAction(data));
};

export type PlayerUnregisterParams = {
    Message?: 'UnRegister';
    PanelIndex: number;
};

export const useUnregisterService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (data: PlayerUnregisterParams) => {
            dispatch(
                unregisterAction({
                    Message: 'UnRegister',
                    PanelIndex: data.PanelIndex,
                })
            );
        },
        [dispatch]
    );
};

export const useUnregisterLocalService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (data: PlayerUnregisterParams) => {
            dispatch(
                unregisterLocalAction({
                    Message: 'UnRegister',
                    PanelIndex: data.PanelIndex,
                })
            );
        },
        [dispatch]
    );
};

export const unregisterServiceSuccess = (
    dispatch: Dispatch<any>,
    data: RegisterSuccessActionParams
): any => {
    dispatch(unregisterActionSuccess(data));
};

export const unregisterServiceFail = (
    dispatch: Dispatch<any>,
    data: RegisterSuccessActionParams
): any => {
    dispatch(unregisterActionFail(data));
};

export type PlayerCashOutParams = {
    Message: 'CashOut';
    PanelIndex?: number;
    Multiplier?: number;
    Won?: number;
};

export const useCashOutService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (data: Partial<PlayerCashOutParams>) => {
            dispatch(
                cashOutAction({
                    Message: 'CashOut',
                    PanelIndex: data.PanelIndex,
                    Multiplier: data.Multiplier,
                })
            );
        },
        [dispatch]
    );
};

export type BoardActionParams = {
    PanelIndex: number;
    registerState: RegisterState;
};

export const useBoardAddedService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (PanelIndex: number) => {
            dispatch(
                boardAddedAction({
                    PanelIndex: PanelIndex,
                    registerState: RegisterState.UNREGISTERED,
                })
            );
        },
        [dispatch]
    );
};

export const useBoardRemovedService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (PanelIndex: number) => {
            dispatch(
                boardRemovedAction({
                    PanelIndex: PanelIndex,
                    registerState: RegisterState.UNREGISTERED,
                })
            );
        },
        [dispatch]
    );
};

export const useToggleEnableFreeBetsService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (enabled: boolean) => {
            dispatch(toggleEnableFreeBetsAction(enabled));
        },
        [dispatch]
    );
};

export type SaveSettingsParams = {
    Message: 'Setting';
    Avatar: string | number;
    Sound: boolean;
    Music: boolean;
    Animate: boolean;
};

export const useSaveSettingsService = () => {
    const dispatch = useDispatch();

    return useCallback(
        (
            data: Pick<
                SaveSettingsParams,
                'Avatar' | 'Sound' | 'Music' | 'Animate'
            >
        ) => {
            dispatch(
                saveSettingsAction({
                    Message: 'Setting',
                    Avatar: data.Avatar,
                    Sound: data.Sound,
                    Music: data.Music,
                    Animate: data.Animate,
                })
            );
        },
        [dispatch]
    );
};

export const useLoadGameService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (loadingDuration: number) => {
            dispatch(loadGameAction(loadingDuration));
        },
        [dispatch]
    );
};
