import React, { FC } from 'react';
import { IconProps } from './types';

export const EuropeBetCircle: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 14.562 14.238"
            className={className}
            width="100%"
            height="100%"
        >
            <path
                data-name="Path 2"
                d="M0,14.6A7.2,7.2,0,0,1,7.281,7.5a7.206,7.206,0,0,1,7.281,7.133,7.2,7.2,0,0,1-7.281,7.1A7.206,7.206,0,0,1,0,14.6"
                transform="translate(0 -7.5)"
                fill="#e74e0f"
            />
        </svg>
    );
};

export default EuropeBetCircle;
