import React, { FC, useState } from 'react';
import styled from 'styled-components';
import UserAvatar from '../../../components/UserAvatar';
import { Profile } from '../../../constants/interfaces/Profile';
import GameConfigurations from './GameConfigurations';
import useTranslate from '../../../config/language';
import { KEYWORDS } from '../../../config/language/keywords';
import AvatarsModal from './AvatarsModal/AvatarsModal';

const StyledContainer = styled.div`
    position: absolute;
    min-height: 150px;
    width: 345px;
    background: ${(props) => props.theme.colors.charcoalGreyTwo};
    border-radius: 2px;
    top: 50px;
    right: 0;
    z-index: 100;
    padding: 16px;
    @media (max-width: ${700}px) {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 60%;
    }
    @media (max-width: ${501}px) {
        width: 80%;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
`;

const AvatarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
`;

const Username = styled.div`
    letter-spacing: 0;
    color: ${(props) => props.theme.colors.whiteGrayish};
    margin: auto;
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.mediatorNarrowBook};
    text-align: center;
    margin-top: 8px;
`;

const ChangeAvatarButton = styled.div`
    letter-spacing: 0;
    color: ${(props) => props.theme.colors.white};
    background: #ffffff1a;
    border-radius: 4px;
    width: 150px;
    height: 32px;
    outline: none;
    border: none;
    font-family: ${(props) => props.theme.fonts.mediatorNarrowCaps};
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    &:active {
        opacity: 0.7;
    }
`;

const Body = styled.div``;

const ProfileDropdown: FC<{ profile: Profile; handleClose: () => void }> = ({
    profile,
}) => {
    const translate = useTranslate();
    const [changeAvatarOpen, setChangeAvatarOpen] = useState(false);
    const avatarId = profile?.Player?.A || 0;

    const handleOpenChangeAvatarModal = () => {
        setChangeAvatarOpen(true);
        // handleClose();
    };

    return (
        <StyledContainer>
            <Header>
                <AvatarContainer>
                    <UserAvatar size={49} avatarId={avatarId} />
                    <Username>{profile.Player && profile.Player.N}</Username>
                </AvatarContainer>
                <ChangeAvatarButton onClick={handleOpenChangeAvatarModal}>
                    {translate(KEYWORDS.ChangeAvatar)}
                </ChangeAvatarButton>
            </Header>
            <Body>
                <GameConfigurations />
            </Body>
            {changeAvatarOpen && (
                <AvatarsModal closeModal={() => setChangeAvatarOpen(false)} />
            )}
        </StyledContainer>
    );
};

export default ProfileDropdown;
