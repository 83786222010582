import { useSelector, shallowEqual } from 'react-redux';
import { Profile } from '../../../../constants/interfaces/Profile';
import { Balance } from '../../../../constants/interfaces/Balance';
import {
    SelectBalanceState,
    SelectProfileState,
} from '../../../../config/store/selectors';

export function useProfile(): Profile {
    return useSelector(SelectProfileState, shallowEqual);
}

export function useBalance(): Balance {
    return useSelector(SelectBalanceState, shallowEqual);
}
