import { KEYWORDS } from '../../../../config/language/keywords';

export enum TOP_LIST_INDEXES {
    TOP_ODDS = 0,
    TOP_WINNINGS = 1,
    TOP_CASH_OUT = 2,
}

export enum TOP_LIST_FILTER_INDEXES {
    DAY = 'Day',
    MONTH = 'Month',
    YEAR = 'Year',
}

export const TopListItems = [
    { text: KEYWORDS.TopSpins, index: TOP_LIST_INDEXES.TOP_ODDS },
    { text: KEYWORDS.TopWins, index: TOP_LIST_INDEXES.TOP_WINNINGS },
    { text: KEYWORDS.MultiplierCashout, index: TOP_LIST_INDEXES.TOP_CASH_OUT },
];

export const TopListFilters = [
    { text: KEYWORDS.Day, index: TOP_LIST_FILTER_INDEXES.DAY },
    { text: KEYWORDS.Month, index: TOP_LIST_FILTER_INDEXES.MONTH },
    { text: KEYWORDS.Year, index: TOP_LIST_FILTER_INDEXES.YEAR },
];
