import React from 'react';
import styled from 'styled-components';
import {
    BUBBLE_ANIMATION_0,
    BUBBLE_ANIMATION_1,
    BUBBLE_ANIMATION_10,
    BUBBLE_ANIMATION_11,
    BUBBLE_ANIMATION_12,
    BUBBLE_ANIMATION_2,
    BUBBLE_ANIMATION_3,
    BUBBLE_ANIMATION_4,
    BUBBLE_ANIMATION_5,
    BUBBLE_ANIMATION_6,
    BUBBLE_ANIMATION_7,
    BUBBLE_ANIMATION_8,
    BUBBLE_ANIMATION_9,
} from './animations';

const StyledBubble = styled.div`
    position: absolute;
    background: white;
    border-radius: 50%;
`;

const StyledBubble0 = styled(StyledBubble)`
    width: 30px;
    height: 30px;
    top: 116px;
    left: -1px;
    animation: 1.75541s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_0};
`;

const StyledBubble1 = styled(StyledBubble)`
    width: 50px;
    height: 50px;
    top: 129px;
    left: 43px;
    animation: 1.95524s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_1};
`;

const StyledBubble2 = styled(StyledBubble)`
    width: 30px;
    height: 30px;
    top: 128px;
    left: 24px;
    animation: 2.98443s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_2};
`;

const StyledBubble3 = styled(StyledBubble)`
    width: 30px;
    height: 30px;
    top: 132px;
    left: 102px;
    animation: 2.35075s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_3};
`;

const StyledBubble4 = styled(StyledBubble)`
    width: 20px;
    height: 20px;
    top: 105px;
    left: -4px;
    animation: 1.79912s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_4};
`;

const StyledBubble5 = styled(StyledBubble)`
    left: 45px;
    top: 130px;
    width: 30px;
    height: 30px;
    animation: 2.82559s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_5};
`;

const StyledBubble6 = styled(StyledBubble)`
    width: 20px;
    height: 20px;
    top: 120px;
    left: 20px;
    animation: 1.95524s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_6};
`;

const StyledBubble7 = styled(StyledBubble)`
    width: 32px;
    height: 32px;
    top: 132px;
    left: 77px;
    animation: 1.65524s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_7};
`;

const StyledBubble8 = styled(StyledBubble)`
    width: 40px;
    height: 40px;
    top: 132px;
    left: 77px;
    animation: 2.98605s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_8};
`;

const StyledBubble9 = styled(StyledBubble)`
    width: 30px;
    height: 30px;
    top: 121px;
    left: 120px;
    animation: 2.21174s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_9};
`;

const StyledBubble10 = styled(StyledBubble)`
    width: 20px;
    height: 20px;
    top: 115px;
    left: 134px;
    animation: 1.95524s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_10};
`;

const StyledBubble11 = styled(StyledBubble)`
    width: 15px;
    height: 15px;
    top: 108px;
    left: 147px;
    animation: 2.32524s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_11};
`;

const StyledBubble12 = styled(StyledBubble)`
    width: 15px;
    height: 15px;
    top: 108px;
    left: 147px;
    animation: 1.73796s ease 0s infinite normal none running
        ${BUBBLE_ANIMATION_12};
`;

export const Bubble0 = () => {
    return <StyledBubble0 />;
};

export const Bubble1 = () => {
    return <StyledBubble1 />;
};

export const Bubble2 = () => {
    return <StyledBubble2 />;
};

export const Bubble3 = () => {
    return <StyledBubble3 />;
};

export const Bubble4 = () => {
    return <StyledBubble4 />;
};

export const Bubble5 = () => {
    return <StyledBubble5 />;
};

export const Bubble6 = () => {
    return <StyledBubble6 />;
};

export const Bubble7 = () => {
    return <StyledBubble7 />;
};

export const Bubble8 = () => {
    return <StyledBubble8 />;
};

export const Bubble9 = () => {
    return <StyledBubble9 />;
};

export const Bubble10 = () => {
    return <StyledBubble10 />;
};

export const Bubble11 = () => {
    return <StyledBubble11 />;
};

export const Bubble12 = () => {
    return <StyledBubble12 />;
};
