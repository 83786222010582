import React, { FC } from 'react';
import styled from 'styled-components';

const Th = styled.th<{ width?: number }>`
    color: ${(props) => props.theme.colors.whiteGrayish};
    opacity: 0.5;
    border-bottom: 2px solid ${(props) => props.theme.colors.gray};
    font-size: 13px;
    font-family: ${(props) => props.theme.fonts.mediatorNarrow};
    font-weight: bold;
    text-align: right;
    ${(props) => (props.width ? `width: ${props.width}%` : '')};
    height: 28px;
    &:nth-child(1) {
        padding-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        letter-spacing: 0;
        text-align: left;
    }
    &:nth-child(4) {
        padding-right: 10px;
    }
`;

export const TableHeaderCell: FC<{
    width?: number;
    className?: string;
}> = ({ children, className, width }) => {
    return (
        <Th className={className} width={width}>
            {children}
        </Th>
    );
};
