import React, { FC } from 'react';
import { IconProps } from './types';

export const VipLogo: FC<IconProps> = ({ className }) => {
    return (
        <svg
            id="Layer_2"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            viewBox="0 0 231.86 24.11"
            className={className}
        >
            <defs>
                <linearGradient
                    id="linear-gradient-vip"
                    x1="6.84"
                    y1="10.35"
                    x2="29.2"
                    y2="28.33"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#6a5942" />
                    <stop offset=".18" stopColor="#7f6c3f" />
                    <stop offset=".27" stopColor="#806d3d" />
                    <stop offset=".3" stopColor="#856f36" />
                    <stop offset=".32" stopColor="#8c742b" />
                    <stop offset=".32" stopColor="#8f7626" />
                    <stop offset=".55" stopColor="#f3ea9f" />
                    <stop offset=".71" stopColor="#b7a63f" />
                    <stop offset=".87" stopColor="#e2ca68" />
                    <stop offset="1" stopColor="#a08329" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-vip-2"
                    x1="160.19"
                    y1="9.68"
                    x2="168.89"
                    y2="9.68"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#6a5942" stopOpacity="0" />
                    <stop offset="1" stopColor="#6a5942" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-vip-3"
                    x1="198"
                    y1="5.21"
                    x2="232.03"
                    y2="22.71"
                    xlinkHref="#linear-gradient-vip"
                />
                <linearGradient
                    id="linear-gradient-vip-4"
                    x1="200.94"
                    y1="-.51"
                    x2="234.97"
                    y2="16.99"
                    xlinkHref="#linear-gradient-vip"
                />
                <linearGradient
                    id="linear-gradient-vip-5"
                    x1="194.91"
                    y1="11.2"
                    x2="228.95"
                    y2="28.71"
                    xlinkHref="#linear-gradient-vip"
                />
            </defs>
            <g id="Layer_1-2">
                <g>
                    <g id="Group_5603">
                        <g id="logo_slogan">
                            <path
                                id="Path_25"
                                d="M0,12.02C.06,7.03,4.15,3.03,9.14,3.09c4.99,.06,8.98,4.15,8.93,9.14-.06,4.95-4.08,8.93-9.03,8.93C4.02,21.13-.03,17.04,0,12.03c0,0,0,0,0-.01"
                                fill="url(#linear-gradient-vip)"
                            />
                            <path
                                id="Path_26"
                                d="M89.93,16.26c-2.11-.06-3.81-1.75-3.88-3.86v-.71c.04-2.16,1.82-3.89,3.98-3.85,2.16,.04,3.89,1.82,3.85,3.98,0,.06,0,.13,0,.19,.07,2.25-1.69,4.14-3.94,4.25m.7-11.97c-1.69,0-3.32,.62-4.58,1.74v-1.29h-4.07V24.11h4.07v-6.04c1.27,1.11,2.89,1.73,4.58,1.74,4.13-.14,7.37-3.59,7.25-7.72,0-4.25-3.24-7.79-7.25-7.79"
                                fill="#fff"
                            />
                            <path
                                id="Path_27"
                                d="M159,4.74h-3.05V.11h-4.07V4.74h-1.84v3.35h1.84v11.2h4.07V8.09h3.05v-3.34Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_28"
                                d="M128.47,12.02c.06,2.24-1.7,4.11-3.94,4.18-2.11-.06-3.81-1.75-3.88-3.86v-.71c.09-2.1,1.78-3.78,3.88-3.86,2.25,.1,4.01,1.99,3.94,4.25m4.01,0c.12-4.13-3.12-7.58-7.25-7.72-1.69,0-3.32,.62-4.58,1.74V.11h-4.07V19.35h4.07v-1.29c1.27,1.11,2.89,1.73,4.58,1.74,4.14-.17,7.38-3.64,7.25-7.79"
                                fill="#fff"
                            />
                            <path
                                id="Path_29"
                                d="M103.29,11.69c.03-2.23,1.84-4.03,4.07-4.05,1.22-.07,2.38,.55,2.99,1.61l-7.06,2.9c.06,0,.06-.19,0-.45m1.21,3.47l10.24-4.18c-.34-3.84-3.59-6.76-7.44-6.69-4.27-.02-7.74,3.43-7.76,7.69,0,0,0,.02,0,.03,.07,4.32,3.63,7.78,7.95,7.72,2.93,0,5.53-1.16,6.68-2.57l-2.23-2.25c-4.39,3.15-7.31,.64-7.44,.26"
                                fill="#fff"
                            />
                            <path
                                id="Path_30"
                                d="M47.83,4.74V12.98c.03,1.79-1.4,3.27-3.19,3.3-1.79,.03-3.27-1.4-3.3-3.19,0-.04,0-.08,0-.11V4.74h-4.01v7.72h0c-.07,4,3.13,7.3,7.13,7.37s7.3-3.13,7.37-7.13c0-.08,0-.16,0-.24V4.74h-4.01Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_31"
                                d="M72.52,16.26c-2.31,.1-4.26-1.69-4.36-4-.1-2.31,1.69-4.26,4-4.36,2.31-.1,4.26,1.69,4.36,4,0,.06,0,.12,0,.18-.01,2.24-1.77,4.07-4.01,4.18m-.46-11.97c-4.26,.16-7.59,3.74-7.44,8.01s3.74,7.59,8.01,7.44c4.15-.15,7.44-3.57,7.44-7.72-.1-4.34-3.68-7.78-8.01-7.72"
                                fill="#fff"
                            />
                            <path
                                id="Path_32"
                                d="M24.1,11.69c.03-2.23,1.84-4.03,4.07-4.05,1.22-.07,2.38,.55,2.99,1.61l-7.06,2.9v-.45m1.15,3.47l10.24-4.18c-.34-3.84-3.59-6.76-7.44-6.69-4.27-.02-7.74,3.43-7.76,7.69,0,0,0,.02,0,.03,.07,4.32,3.63,7.78,7.95,7.72,2.93,0,5.53-1.16,6.68-2.57l-2.23-2.26c-4.32,3.15-7.25,.64-7.44,.26"
                                fill="#fff"
                            />
                            <path
                                id="Path_33"
                                d="M137.89,11.69c.03-2.23,1.84-4.03,4.07-4.05,1.22-.07,2.38,.55,2.99,1.61l-7.06,2.9v-.45m1.15,3.47l10.24-4.18c-.36-3.83-3.6-6.74-7.44-6.69-4.27-.02-7.74,3.43-7.76,7.69,0,0,0,.02,0,.03,.07,4.32,3.63,7.78,7.95,7.72,2.93,0,5.53-1.16,6.68-2.57l-2.23-2.25c-4.32,3.15-7.25,.64-7.44,.26"
                                fill="#fff"
                            />
                            <path
                                id="Path_34"
                                d="M63.16,4.42c-3.18-.58-9.03,.45-9.03,6.76v8.17h4.01v-7.08c0-5.02,4.96-4.18,4.96-4.18v-3.67h.06Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            d="M177.05,9.34c1.79-.8,2.51-2.24,2.51-3.73,0-3.9-2.85-5.61-6.66-5.61h-16.95V3.31h16.95c2.32,0,3.04,1.5,3.04,2.38,0,1.11-.75,2.08-3.04,2.08h-16.95v3.2h16.95c1.49,0,3.62,.92,3.62,2.66s-2.13,2.32-3.62,2.32h-16.95v3.4h16.95c4.01,0,7.24-1.38,7.24-5.69,0-1.22-.83-3.63-3.09-4.32Z"
                            fill="url(#linear-gradient-vip-2)"
                        />
                        <path
                            d="M185.81,.02V19.35h-3.34V.02h3.34Z"
                            fill="#6a5942"
                        />
                        <path
                            d="M217.67,17.61c-1.58,1.55-3.24,2.16-5.25,2.16-3.95,0-7.24-2.38-7.24-7.22s3.29-7.22,7.24-7.22c1.94,0,3.43,.55,4.92,2.02l-2.13,2.24c-.8-.72-1.8-1.08-2.74-1.08-2.27,0-3.93,1.66-3.93,4.04,0,2.6,1.77,3.98,3.87,3.98,1.08,0,2.16-.3,2.99-1.13l2.27,2.21Z"
                            fill="url(#linear-gradient-vip-3)"
                        />
                        <path
                            d="M222.46,.02V11.19l4.59-5.47h4.04v.19l-5.56,6.22,6.33,6.97v.25h-4.06l-5.34-6.17v6.17h-3.37V.02h3.37Z"
                            fill=" url(#linear-gradient-vip-4)"
                        />
                    </g>
                    <path
                        d="M199.58,5.72h3.23v13.63h-3.18l-.17-1.99c-.77,1.6-2.9,2.38-4.42,2.41-4.04,.03-7.02-2.46-7.02-7.24s3.12-7.16,7.11-7.13c1.83,0,3.57,.86,4.34,2.21l.11-1.88Zm-8.18,6.8c0,2.6,1.8,4.15,4.04,4.15,5.31,0,5.31-8.27,0-8.27-2.24,0-4.04,1.52-4.04,4.12Z"
                        fill="url(#linear-gradient-vip-5)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default VipLogo;
