import React from 'react';
import { Table, AutoSizer, Index } from 'react-virtualized';
import styled from 'styled-components';
import 'react-virtualized/styles.css';

interface VirtualizedTableProps {
    rowGetter?: ((info: Index) => any) | undefined;
    rowRenderer?: any;
    rowCount: number;
    headerHeight: number;
    rowHeight: number;
    children?: React.ReactNode | undefined;
}

const TableWrapper = styled.div`
    position: relative;
    font-size: 14px;
    font-weight: bold;
    height: 100%;
    color: #ffffffd6;
    cursor: pointer;
    font-family: ${(props) => props.theme.fonts.mediatorNarrow};
    padding: 9px 2px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .ReactVirtualized__Table__row {
        width: 100% !important;
        cursor: pointer;
        &:hover {
            background-color: #ffffff08;
        }
    }

    .ReactVirtualized__Table__rowColumn {
        height: 100%;
        margin: 0;
    }
    .ReactVirtualized__Table__rowColumn:first-of-type {
        margin: 0;
    }
`;

export const VirtualizedTable = (props: VirtualizedTableProps) => (
    <TableWrapper>
        <AutoSizer>
            {({ width, height }) => {
                return (
                    <Table
                        headerHeight={props.headerHeight}
                        width={width}
                        height={height}
                        rowCount={props.rowCount}
                        rowHeight={props.rowHeight}
                        rowGetter={props.rowGetter}
                    >
                        {props.children}
                    </Table>
                );
            }}
        </AutoSizer>
    </TableWrapper>
);

export default VirtualizedTable;
