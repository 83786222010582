import React, { FC } from 'react';
import styled from 'styled-components';
import useTranslate from '../../config/language';

const StyledContainer = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    top: 50px;
    background-color: ${props => props.theme.colors.transparent3};
    backdrop-filter: blur(30px);
    border-radius: 4px;
    overflow: hidden;
`;

const DropDownItem = styled.div`
    cursor: pointer;
    padding: 10px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    text-align: center;
    border-top: 1px solid #ffffff29;
    text-align: center;
    font-size: 14px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};

    &:active {
        opacity: 0.7;
    }
`;

const DropDownItems: FC<{
    items: { text: string; index: any }[];
    selectItem(selected: any): void;
    handleClose(): void;
}> = ({ items, selectItem, handleClose }) => {
    const translate = useTranslate();

    return (
        <StyledContainer
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {items.map(item => {
                return (
                    <DropDownItem
                        key={item.index}
                        onClick={() => {
                            selectItem(item.index);
                            handleClose();
                        }}
                    >
                        {translate(item.text)}
                    </DropDownItem>
                );
            })}
        </StyledContainer>
    );
};

export default DropDownItems;
