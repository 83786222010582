import { format } from 'date-fns';
import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo } from 'react';
import { MIXPANEL_TOKEN } from '../../constants/constants';
import { ITenant } from '../../constants/interfaces/Tenant';
import { getIsTestTenant, isLocal } from '../../utils/check-env';
import { EMixpanelEvent, IMixpanelUser } from './types';

export const useMixpanelServices = () => {
    const identify = useCallback((user: IMixpanelUser, tenant: ITenant) => {
        if (disableTracking()) return;

        const isForTesting = getIsTestTenant(tenant);

        if (isForTesting) {
            user = {
                name: '**************',
                id: '**************',
            };
        }

        mixpanel.init(MIXPANEL_TOKEN, { debug: isForTesting });
        mixpanel.identify(user.id);
        mixpanel.people.set({
            $name: user.name,
        });
    }, []);

    const track = useCallback(
        (eventName: EMixpanelEvent, payload: object = {}) => {
            if (disableTracking()) return;
            mixpanel.track(eventName, {
                ...payload,
                UI_Time: format(
                    new Date(),
                    'h:MM:SS.SSS aaa, EEE, LLL, d, yyyy'
                ),
            });
        },
        []
    );

    return useMemo(
        () => ({
            identify,
            track,
        }),
        [identify, track]
    );
};

const disableTracking = (): boolean => {
    return isLocal() || !MIXPANEL_TOKEN;
};
