import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { variables } from './constants/variables';
import rootReducer from './config/store/reducers';
import createAppStore from './config/store/store';
import LanguageProvider from './config/language/provider';
import { BrowserRouter } from 'react-router-dom';

const store = createAppStore(rootReducer);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <LanguageProvider>
                    <ThemeProvider theme={variables}>
                        <App />
                    </ThemeProvider>
                </LanguageProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
