import React, { memo } from 'react';
import BoxContainer from '../../components/BoxContainer/BoxContainer';
import { useGameHistory } from '../../config/store/state/app.state';
import ResultBox from '../../components/ResultBox/ResultBox';
import styled from 'styled-components';
import { useMessengerState } from '../chat/store/state';
import { useIsMobile } from '../../helpers/hooks/useIsMobile';

const StyledOddsList = styled.div<{ showMargin: boolean }>`
    height: 45px;
    position: relative;
    margin-right: ${({ showMargin }) => (showMargin ? '120px' : 0)};
`;

const StyledBoxContainer = styled(BoxContainer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const OddsListContent = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 5px;
`;

const OddsList = () => {
    const gameHistory = useGameHistory();
    const chatOpen = useMessengerState().open;
    const isMobile = useIsMobile();

    const historyItemsToShow = gameHistory.slice(0, isMobile ? 10 : 50);

    return (
        <StyledOddsList showMargin={!chatOpen && !isMobile}>
            <StyledBoxContainer>
                <OddsListContent>
                    {historyItemsToShow.map(game => {
                        return <ResultBox key={game.ID} game={game} />;
                    })}
                </OddsListContent>
            </StyledBoxContainer>
        </StyledOddsList>
    );
};

export default memo(OddsList);
